import * as React from 'react';
import {
  BlogItem as TBlogItem,
  AppLocalizationKey,
  CarouselBreakpoints,
} from '~/common/types/types';
import { Carousel } from '~/components/common/common';
import { BlogItem } from '../components';

const BREAKPOINTS: CarouselBreakpoints = {
  0: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
};

type Props = {
  items: TBlogItem[];
  readMoreText: AppLocalizationKey;
};

const BlogsList: React.FC<Props> = ({ items, readMoreText }) => (
  <Carousel breakpoints={BREAKPOINTS} hasNavigation>
    {items.map(
      (item): JSX.Element => (
        <BlogItem item={item} readMoreText={readMoreText} key={item.title} />
      ),
    )}
  </Carousel>
);

export { BlogsList };
