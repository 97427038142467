import { AppEnvironment, ENV } from '~/common/enums/enums';
import { HelmetScript } from '~/common/types/types';

const getScripts = (): HelmetScript[] => {
  const scripts: HelmetScript[] = [];
  const isProductionEnvironment =
    ENV.APP.ENVIRONMENT === AppEnvironment.PRODUCTION;

  if (isProductionEnvironment) {
    scripts.push({
      innerHTML: `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${ENV.GOOGLE.TAG_MANAGER_ID}')
      `,
    });
  }

  return scripts;
};

export { getScripts };
