import * as React from 'react';
import {
  CarouselBreakpoints,
  BenefitsItem as TBenefitsItem,
} from '~/common/types/types';
import { Carousel } from '~/components/common/common';
import { BenefitsItem } from '../components';
import * as styles from './benefits-list.module.scss';

const BREAKPOINTS: CarouselBreakpoints = {
  0: {
    slidesPerView: 1,
  },
  464: {
    slidesPerView: 2,
  },
  1024: {
    slidesPerView: 3,
  },
};

type Props = {
  items: TBenefitsItem[];
};

const BenefitsList: React.FC<Props> = ({ items }) => (
  <Carousel breakpoints={BREAKPOINTS} className={styles.carousel} hasNavigation>
    {items.map((item) => (
      <BenefitsItem key={item.title} item={item} />
    ))}
  </Carousel>
);

export { BenefitsList };
