import * as React from 'react';
import {
  HistoryItem as THistoryItem,
  CarouselBreakpoints,
} from '~/common/types/types';
import { Carousel } from '~/components/common/common';
import { useSliderNavigation } from '~/hooks/hooks';
import { HistoryItem, HistorySelectList } from '../components';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './history-list.module.scss';

const BREAKPOINTS: CarouselBreakpoints = {
  0: {
    slidesPerView: 1,
    wrapperClassName: styles.wrapper,
  },
};

type Props = {
  items: THistoryItem[];
};

const HistoryList: React.FC<Props> = ({ items }) => {
  const {
    ref,
    handleSlideSelect: handleYearSelect,
    currentIdx,
  } = useSliderNavigation();

  return (
    <>
      <Carousel
        ref={ref}
        breakpoints={BREAKPOINTS}
        className={getValidClasses(styles.history, styles.carousel)}
      >
        {items.map((it, idx) => {
          const isActive = idx === currentIdx;

          return <HistoryItem item={it} isActive={isActive} key={it.year} />;
        })}
      </Carousel>
      <HistorySelectList
        activeIndex={currentIdx}
        items={items}
        onGoToSlide={handleYearSelect}
      />
    </>
  );
};

export { HistoryList };
