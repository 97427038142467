import * as React from 'react';
import {
  FormattedMessage,
  Link,
  SectionLayout,
} from '~/components/common/common';
import { DirectionsPayload, SectionUIProps } from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import { AppRoute } from '~/common/enums/enums';
import * as styles from './directions.module.scss';

const DIRECTION_CLASSES = {
  wrapper: styles.sectionWrapper,
};

type Props = SectionUIProps & {
  payload: DirectionsPayload;
  style: 'primary' | 'secondary';
};

const Directions: React.FC<Props> = ({ payload, style, sectionHighlight }) => {
  const { title, items } = payload;

  return (
    <SectionLayout
      classes={DIRECTION_CLASSES}
      sectionHighlight={sectionHighlight}
    >
      <h2 className="visually-hidden">Tracks</h2>
      <div className={getValidClasses(styles.directions, styles[style])}>
        <FormattedMessage className={styles.title} message={title} />
        <div className={styles.buttonsWrapper}>
          {items.map((it) => (
            <Link
              className={styles.link}
              localLink={it.link as AppRoute}
              key={it.title}
            >
              {it.title}
            </Link>
          ))}
        </div>
      </div>
    </SectionLayout>
  );
};

export { Directions };
