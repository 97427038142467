import alina from '~/assets/img/blogs/alina-kupchyk.png';
import farid from '~/assets/img/blogs/farid-shabanov.png';
import dmytro from '~/assets/img/blogs/dmytro-kulakov.png';
import { AppLocalizationKey, BlogsPayload } from '~/common/types/types';

const blogDetails = [
  {
    image: alina,
    decorationKey: 1,
    externalLink:
      'https://academy.binary-studio.com/blog/road-to-being-a-binarian',
  },
  {
    image: farid,
    decorationKey: 2,
    externalLink:
      'https://academy.binary-studio.com/blog/mastering-clean-code-unlocking-programming-principles-power',
  },
  {
    image: dmytro,
    decorationKey: 3,
    externalLink:
      'https://academy.binary-studio.com/blog/from-the-circus-to-it',
  },
];

const blogsPayload: BlogsPayload = {
  titlePart1: 'blogs.titlePart1',
  titlePart2: 'blogs.titlePart2',
  readMoreText: 'blogs.readMoreText',
  items: blogDetails.map((it, idx) => ({
    title: `blogs.blog${idx}.title` as AppLocalizationKey,
    author: `blogs.blog${idx}.author` as AppLocalizationKey,
    image: it.image,
    content: `blogs.blog${idx}.content` as AppLocalizationKey,
    decorationKey: it.decorationKey,
    externalLink: it.externalLink,
  })),
};

export { blogsPayload };
