import * as React from 'react';
import { BSAEvent, AppLocalizationKey } from '~/common/types/types';
import { Link, FormattedMessage, Image } from '~/components/common/common';
import * as styles from './events-item.module.scss';

type Props = {
  item: BSAEvent;
  moreLinkText: AppLocalizationKey;
};

const EventsItem: React.FC<Props> = ({ item, moreLinkText }) => {
  const hasSpeaker = Boolean(item.speaker);

  return (
    <li>
      <article className={styles.item}>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>{item.title}</h3>
          {hasSpeaker && <p className={styles.speaker}>{item.speaker}</p>}
          <Link className={styles.link} externalLink={item.link}>
            <FormattedMessage message={moreLinkText} />
          </Link>
        </div>
        <div className={styles.imgWrapper}>
          <Image className={styles.img} src={item.imgPath} alt="speaker" />
        </div>
      </article>
    </li>
  );
};

export { EventsItem };
