import * as React from 'react';
import { ENV } from '~/common/enums/enums';
import { footerPayload, headerPayload } from '~/common/payload/payload';
import { Layout } from '~/components/common/common';
import { FoundingTeam } from '~/components/sections/sections';
import { seoPayload, foundingTeamPayload } from './data/data';

const Page: React.FC = () => (
  <Layout
    seoPayload={seoPayload}
    headerPayload={headerPayload}
    footerPayload={footerPayload}
    registrationLink={ENV.APP.REGISTRATION_LINK}
  >
    <h1 className="visually-hidden">Founding Team</h1>
    <FoundingTeam payload={foundingTeamPayload} />
  </Layout>
);

export { Page };
