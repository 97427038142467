"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.languageToISO = void 0;
const enums_1 = require("../../../common/enums/enums");
const languageToISO = {
    [enums_1.AppLanguage.EN]: 'en',
    [enums_1.AppLanguage.RU]: 'ru',
    [enums_1.AppLanguage.UA]: 'uk',
};
exports.languageToISO = languageToISO;
