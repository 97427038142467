import * as React from 'react';
import { BenefitsItem as TBenefitsItem } from '~/common/types/types';
import { FormattedMessage, Image } from '~/components/common/common';
import * as styles from './benefits-item.module.scss';

type Props = {
  item: TBenefitsItem;
};

const BenefitsItem: React.FC<Props> = ({ item }) => (
  <article className={styles.item}>
    <Image
      alt="Binary studio workers"
      className={styles.img}
      src={item.imgPath}
    />
    <h3 className={styles.title}>
      <FormattedMessage message={item.title} />
    </h3>
    <p className={styles.description}>
      <FormattedMessage message={item.description} />
    </p>
  </article>
);

export { BenefitsItem };
