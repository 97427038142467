import * as React from 'react';
import { BSDescriptionPayload } from '~/common/types/types';
import { BSDescriptionSection } from './components/components';
import * as styles from './bs-description.module.scss';

type Props = {
  payload: BSDescriptionPayload;
};

const BSDescription: React.FC<Props> = ({ payload }) => {
  const { sections } = payload;

  return (
    <div className={styles.contentWrapper}>
      {sections.map((section) => (
        <BSDescriptionSection section={section} key={section.title} />
      ))}
    </div>
  );
};

export { BSDescription };
