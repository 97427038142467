import * as React from 'react';
import { SectionLayout } from '~/components/common/common';
import { ReviewPayload, SectionUIProps } from '~/common/types/types';
import { ReviewList } from './components/components';

type Props = SectionUIProps & {
  payload: ReviewPayload;
};

const Reviews: React.FC<Props> = ({ payload, sectionHighlight }) => {
  const { title, items } = payload;

  return (
    <SectionLayout headingMessage={title} sectionHighlight={sectionHighlight}>
      <ReviewList items={items} />
    </SectionLayout>
  );
};

export { Reviews };
