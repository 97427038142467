import * as React from 'react';
import { SwiperSlide, SwiperSlideProps } from 'swiper/react';

const CarouselSlide: React.FC<SwiperSlideProps> = ({ children, ...props }) => {
  return (
    <SwiperSlide tag="li" {...props}>
      {children}
    </SwiperSlide>
  );
};

CarouselSlide.displayName = 'SwiperSlide';

export { CarouselSlide };
