import * as React from 'react';
import { getValidClasses } from '~/helpers/helpers';
import { Link, Props as LinkProps } from '~/components/common/link/link';
import * as styles from './button.module.scss';

type Props = LinkProps & {
  btnColor?: 'blue' | 'orange' | 'pink';
  hasShadow?: boolean;
};

const Button: React.FC<Props> = ({
  id,
  btnColor = 'blue',
  hasShadow = false,
  localLink,
  externalLink,
  hasLanguagePrefix,
  withLanguageQuery,
  withQueries,
  className,
  children,
  gtm,
}) => {
  const fullClassName = getValidClasses(
    styles.btn,
    styles[btnColor],
    hasShadow && styles.shadow,
    className,
  );
  const hasLocalLink = Boolean(localLink);
  const hasExternalLink = Boolean(externalLink);
  const isLink = hasLocalLink || hasExternalLink;

  if (isLink) {
    return (
      <Link
        id={id}
        className={fullClassName}
        externalLink={externalLink}
        localLink={localLink}
        hasLanguagePrefix={hasLanguagePrefix}
        withLanguageQuery={withLanguageQuery}
        withQueries={withQueries}
        gtm={gtm}
      >
        {children}
      </Link>
    );
  }

  return <button className={fullClassName}>{children}</button>;
};

export { Button };
