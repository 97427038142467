import React from 'react';
import { SectionUIProps, TeamCompositionPayload } from '~/common/types/types';
import { SectionLayout } from '~/components/common/common';
import { TeamCompositionList } from './components/components';
import * as styles from './team-composition.module.scss';

type Props = SectionUIProps & {
  payload: TeamCompositionPayload;
};

const TEAM_COMPOSITION_CLASSES = {
  title: styles.title,
  wrapper: styles.wrapper,
  section: styles.section,
};

const TeamComposition: React.FC<Props> = ({ payload, sectionHighlight }) => {
  const { title, items } = payload;

  return (
    <SectionLayout
      classes={TEAM_COMPOSITION_CLASSES}
      sectionHighlight={sectionHighlight}
      headingMessage={title}
    >
      <TeamCompositionList items={items} />
    </SectionLayout>
  );
};

export { TeamComposition };
