// extracted by mini-css-extract-plugin
export var item = "info-item-module--item--tOSQj";
export var hands = "info-item-module--hands--wwL8U";
export var gear = "info-item-module--gear--TvPFq";
export var people = "info-item-module--people--7EqpD";
export var laptop = "info-item-module--laptop--Sv6iV";
export var head = "info-item-module--head--k+sc6";
export var magister = "info-item-module--magister--1GOtX";
export var star = "info-item-module--star--CpQID";
export var cluster = "info-item-module--cluster--9R6AD";
export var diplomatic = "info-item-module--diplomatic--rJm5v";
export var award = "info-item-module--award--iPAlL";
export var title = "info-item-module--title--bMjqZ";
export var description = "info-item-module--description--1imx7";