import { AppLocalizationKey, ReviewPayload } from '~/common/types/types';
import chichik from '~/assets/img/reviews/authors/chichik.png';
import shabanov from '~/assets/img/reviews/authors/shabanov.png';
import palchyn from '~/assets/img/reviews/authors/palchyn.png';
import sirak from '~/assets/img/reviews/authors/sirak.png';
import dobychin from '~/assets/img/reviews/authors/dobychin.png';

const AUTHORS = [
  {
    img: chichik,
    decorationKey: 1,
    linkedIn: 'https://www.linkedin.com/in/anastasiia-chichik/',
  },

  {
    img: shabanov,
    decorationKey: 1,
    linkedIn: 'https://www.linkedin.com/in/fsh02/',
  },
  {
    img: palchyn,
    decorationKey: 2,
    linkedIn: 'https://www.linkedin.com/in/dramen/',
  },
  {
    img: sirak,
    decorationKey: 3,
    linkedIn: 'https://www.linkedin.com/in/veronika-sirak/',
  },
  {
    img: dobychin,
    decorationKey: 4,
    linkedIn: 'https://www.linkedin.com/in/vladyslav-dobychin-674574197/',
  },
];

const reviewPayload: ReviewPayload = {
  title: 'review.title',
  items: AUTHORS.map((it, idx) => ({
    commentary: `review.group${idx}.commentary` as AppLocalizationKey,
    authorImage: it.img,
    authorName: `review.group${idx}.authorName` as AppLocalizationKey,
    authorStatus: `review.group${idx}.authorStatus` as AppLocalizationKey,
    authorExJob: `review.group${idx}.authorExJob` as AppLocalizationKey,
    authorLink: it.linkedIn,
    authorLinkTitle: 'Linkedin',
    decorationKey: it.decorationKey,
  })),
};

export { reviewPayload };
