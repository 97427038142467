import * as React from 'react';
import { Link as AppLink } from 'gatsby';
import { AppRoute } from '~/common/enums/enums';
import { LinkTargetType, LinkRelType } from '~/common/types/types';
import { useAppTranslation, useRouter } from '~/hooks/hooks';
import {
  getLocalLinkWithLanguage,
  getFullLinkQuery,
  joinLinkQuery,
} from './helpers/helpers';

type Props = {
  id?: string;
  localLink?: AppRoute | null;
  externalLink?: string;
  className?: string;
  hasLanguagePrefix?: boolean;
  withQueries?: boolean;
  withLanguageQuery?: boolean;
  target?: LinkTargetType;
  rels?: LinkRelType[];
  gtm?: string;
};

const Link: React.FC<Props> = ({
  id,
  target = 'blank',
  rels = ['norefferer'],
  hasLanguagePrefix = true,
  withQueries = true,
  withLanguageQuery = false,
  children,
  localLink,
  externalLink,
  className,
  gtm,
}) => {
  const { queries } = useRouter();
  const { currentLanguage } = useAppTranslation();

  const hasLocalLink = Boolean(localLink);
  const hasExternalLink = Boolean(externalLink);
  const hasLink = hasLocalLink || hasExternalLink;

  if (!hasLink) {
    return <span className={className}>{children}</span>;
  }

  const fullQuery = withQueries
    ? getFullLinkQuery({
        initialQueries: queries,
        language: currentLanguage,
        withLanguageQuery,
      })
    : '';

  if (hasLocalLink) {
    const locatedLocalLink = hasLanguagePrefix
      ? getLocalLinkWithLanguage(localLink as AppRoute, currentLanguage)
      : localLink;
    const fullLocalLink = joinLinkQuery(locatedLocalLink as string, fullQuery);

    return (
      <AppLink className={className} to={fullLocalLink as AppRoute}>
        {children}
      </AppLink>
    );
  }

  const externalLinkTarget = `_${target}`;
  const fullExternalLink = joinLinkQuery(externalLink as string, fullQuery);

  const handleExternalLinkClick = (
    evt: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    evt.preventDefault();

    window.open(fullExternalLink, externalLinkTarget);
  };

  return (
    <a
      id={id}
      onClick={handleExternalLinkClick}
      className={className}
      href={fullExternalLink}
      target={externalLinkTarget}
      rel={rels.join(' ')}
      data-gtmtrigger={gtm}
    >
      {children}
    </a>
  );
};

export { Link, Props };
