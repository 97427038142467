import * as React from 'react';
import { SectionLayout } from '~/components/common/common';
import { StatisticsList } from './components/components';
import { StatisticsPayload } from '~/common/types/types';
import * as styles from './statistics.module.scss';

const STATISTICS_CLASSES = {
  wrapper: styles.sectionWrapper,
};

type Props = {
  payload: StatisticsPayload;
};

const Statistics: React.FC<Props> = ({ payload }) => {
  const { items } = payload;

  return (
    <SectionLayout classes={STATISTICS_CLASSES}>
      <h2 className="visually-hidden">Academy statistics</h2>
      <StatisticsList items={items} />
    </SectionLayout>
  );
};

export { Statistics };
