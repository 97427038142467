exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-binary-studio-tsx": () => import("./../../../src/pages/about/binary-studio.tsx" /* webpackChunkName: "component---src-pages-about-binary-studio-tsx" */),
  "component---src-pages-about-code-of-behavior-tsx": () => import("./../../../src/pages/about/code-of-behavior.tsx" /* webpackChunkName: "component---src-pages-about-code-of-behavior-tsx" */),
  "component---src-pages-about-faq-tsx": () => import("./../../../src/pages/about/faq.tsx" /* webpackChunkName: "component---src-pages-about-faq-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-founding-team-tsx": () => import("./../../../src/pages/founding-team.tsx" /* webpackChunkName: "component---src-pages-founding-team-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-online-events-tsx": () => import("./../../../src/pages/online-events.tsx" /* webpackChunkName: "component---src-pages-online-events-tsx" */),
  "component---src-pages-technology-js-tsx": () => import("./../../../src/pages/technology/js.tsx" /* webpackChunkName: "component---src-pages-technology-js-tsx" */),
  "component---src-pages-technology-qa-tsx": () => import("./../../../src/pages/technology/qa.tsx" /* webpackChunkName: "component---src-pages-technology-qa-tsx" */)
}

