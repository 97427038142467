import imgSrc from '~/assets/img/direction-icons/js.svg';
import { LandingImagePayload } from '~/common/types/types';

const landingPayload: LandingImagePayload = {
  title: 'technology.js.landing.title',
  subtitleTop: 'technology.js.landing.subtitleTop',
  subtitleBottom: null,
  description: 'technology.js.landing.description',
  items: [
    {
      icon: 'time',
      title: 'technology.js.landing.item0',
    },
    {
      icon: 'code',
      title: 'technology.js.landing.item1',
    },
    {
      icon: 'coin',
      title: 'technology.js.landing.item2',
    },
    {
      icon: 'web',
      title: 'technology.js.landing.item3',
    },
  ],
  imgSrc,
  registrationBtn: 'registration.button',
  registrationEnd: 'registration.end',
};

export { landingPayload };
