import * as React from 'react';
import {
  CarouselBreakpoints,
  ExamplesItem as TExamplesItem,
} from '~/common/types/types';
import { Carousel } from '~/components/common/common';
import { ExamplesItem } from '../components';
import * as styles from './examples-list.module.scss';

const BREAKPOINTS: CarouselBreakpoints = {
  0: {
    slidesPerView: 1,
    wrapperClassName: styles.wrapper,
  },
};

type Props = {
  items: TExamplesItem[];
};

const ExamplesList: React.FC<Props> = ({ items }) => (
  <Carousel breakpoints={BREAKPOINTS} hasPagination>
    {items.map((it) => (
      <ExamplesItem item={it} key={it.title} />
    ))}
  </Carousel>
);

export { ExamplesList };
