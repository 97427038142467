import * as React from 'react';
import { getValidClasses } from '~/helpers/helpers';
import { TabLink } from '~/common/types/types';
import { Link, FormattedMessage } from '~/components/common/common';
import * as styles from './tabs.module.scss';

type Props = {
  tabLinks: TabLink[];
  activeItem: string;
};

const Tabs: React.FC<Props> = ({ tabLinks, activeItem }) => (
  <ul className={styles.tabs}>
    {tabLinks.map((link) => {
      const isActive = link.name === activeItem;

      return (
        <li key={link.localLink} className={styles.tabItem}>
          <Link
            className={getValidClasses(
              styles.tabLink,
              isActive && styles.tabLinkActive,
            )}
            localLink={link.localLink}
          >
            <FormattedMessage message={link.title} />
          </Link>
        </li>
      );
    })}
  </ul>
);

export { Tabs };
