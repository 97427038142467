import * as React from 'react';
import { JoinPayload, SectionUIProps } from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import {
  Button,
  FormattedMessage,
  SectionLayout,
} from '~/components/common/common';
import * as styles from './join.module.scss';

type Props = SectionUIProps & {
  payload: JoinPayload;
  registrationLink: string | null;
};

const Join: React.FC<Props> = ({
  payload,
  sectionHighlight,
  registrationLink,
}) => {
  const { title, description, postscript, registrationBtn } = payload;

  const hasRegistrationLink = Boolean(registrationLink);

  return (
    <SectionLayout
      classes={{
        wrapper: getValidClasses(hasRegistrationLink && styles.registerWrapper),
      }}
      headingMessage={title}
      sectionHighlight={sectionHighlight}
    >
      <p className={styles.description}>
        <FormattedMessage message={description} />
      </p>
      {hasRegistrationLink ? (
        <div className={styles.registrationBtnWrapper}>
          <Button
            gtm="sign_up"
            externalLink={registrationLink as string}
            withLanguageQuery
          >
            <FormattedMessage message={registrationBtn} />
          </Button>
        </div>
      ) : (
        <p className={styles.postscript}>
          <FormattedMessage message={postscript} />
        </p>
      )}
    </SectionLayout>
  );
};

export { Join };
