// extracted by mini-css-extract-plugin
export var wrapper = "review-item-image-module--wrapper--R7mux";
export var imgWrapper = "review-item-image-module--img-wrapper--svJMQ";
export var img = "review-item-image-module--img--+T0Xe";
export var decoration = "review-item-image-module--decoration--bTP-4";
export var decoration1 = "review-item-image-module--decoration1--Lgw9T";
export var decoration2 = "review-item-image-module--decoration2--rhSiB";
export var decoration3 = "review-item-image-module--decoration3--2GVPV";
export var decoration4 = "review-item-image-module--decoration4--MTh8o";
export var imgDecoration = "review-item-image-module--img-decoration--W35af";
export var imgDecoration1 = "review-item-image-module--img-decoration1--UJREL";
export var imgDecoration2 = "review-item-image-module--img-decoration2--SiRtp";
export var imgDecoration3 = "review-item-image-module--img-decoration3--A6yh+";
export var imgDecoration4 = "review-item-image-module--img-decoration4--Vl28Z";
export var wrapperActive = "review-item-image-module--wrapper-active--Iedjj";
export var imgActive = "review-item-image-module--img-active--WtN6N";