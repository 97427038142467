import * as React from 'react';
import { Internationalization } from '~/services/services';
import { useAppTranslation, useEffect } from '~/hooks/hooks';
import { AppLanguage } from '~/common/enums/enums';

const internationalization = new Internationalization();

const InternationalizationProvider = internationalization.provider;

type Props = {
  language: AppLanguage;
  isSSR: boolean;
};

const InternationalizationWrapper: React.FC<Props> = ({
  isSSR,
  language,
  children,
}) => {
  const { currentLanguage, handleLanguageChange } = useAppTranslation();

  const hasCurrentLanguage = currentLanguage !== language;

  useEffect(() => {
    if (isSSR) {
      return;
    }

    if (hasCurrentLanguage) {
      handleLanguageChange(language);
    }
  }, [hasCurrentLanguage, language]);

  if (hasCurrentLanguage && isSSR) {
    handleLanguageChange(language);
  }

  return (
    <InternationalizationProvider i18n={internationalization.instance}>
      {children}
    </InternationalizationProvider>
  );
};

export { InternationalizationWrapper };
