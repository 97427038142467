// extracted by mini-css-extract-plugin
export var wrapper = "review-item-module--wrapper--8mRM7";
export var item = "review-item-module--item--WQv86";
export var icon = "review-item-module--icon--IWuxs";
export var quoteWrapper = "review-item-module--quote-wrapper--Ca2Wt";
export var quote = "review-item-module--quote--NXwFo";
export var authorText = "review-item-module--author-text--stn7M";
export var authorName = "review-item-module--author-name--GyphP";
export var authorStatus = "review-item-module--author-status--fLySb";
export var authorExJob = "review-item-module--author-ex-job--w719a";
export var authorLink = "review-item-module--author-link--tcM7c";
export var itemActive = "review-item-module--item-active--+yaa9";
export var iconActive = "review-item-module--icon-active--4SLXy";
export var left = "review-item-module--left--u7reC";
export var right = "review-item-module--right--AFYy9";
export var hidden = "review-item-module--hidden--HnSH1";