import * as React from 'react';
import { QuestionItem as TQuestionItem } from '~/common/types/types';
import { QuestionItem } from '../components';
import * as styles from './question-list.module.scss';

type Props = {
  questions: TQuestionItem[];
};

const QuestionList: React.FC<Props> = ({ questions }) => (
  <ul className={styles.list}>
    {questions.map((it) => (
      <QuestionItem question={it} key={it.question} />
    ))}
  </ul>
);

export { QuestionList };
