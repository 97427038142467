import * as React from 'react';
import { BSADescriptionTimelineItem } from '~/common/types/types';
import { TimelineItem } from '../components';
import * as styles from './timeline-list.module.scss';

type Props = {
  items: BSADescriptionTimelineItem[];
};

const TimelineList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <TimelineItem item={it} key={it.title} />
    ))}
  </ul>
);

export { TimelineList };
