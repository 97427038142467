import * as React from 'react';
import { AppEnvironment, ENV } from '~/common/enums/enums';
import { useAppTranslation, useEffect } from '~/hooks/hooks';

type UseFacebookChat = () => {
  handleRender: () => JSX.Element | null;
};

const useFacebookChat: UseFacebookChat = () => {
  const { handleTranslate } = useAppTranslation();

  const isProductionEnvironment =
    ENV.APP.ENVIRONMENT === AppEnvironment.PRODUCTION;

  useEffect(() => {
    if (!isProductionEnvironment) {
      return;
    }

    window.fbAsyncInit = (): void => {
      window.FB.init({
        appId: ENV.FACEBOOK.APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v5.0',
      });
    };

    ((d, s, id): void => {
      const [fjs] = d.getElementsByTagName(s);

      if (d.getElementById(id)) {
        return;
      }

      const js = d.createElement(s) as HTMLScriptElement;

      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';

      (fjs.parentNode as HTMLScriptElement).insertBefore(js, fjs);
    })(window.document, 'script', 'facebook-jssdk');
  }, []);

  const handleRender = (): JSX.Element | null => {
    if (!isProductionEnvironment) {
      return null;
    }

    return (
      <div
        className="fb-customerchat"
        {...{
          page_id: ENV.FACEBOOK.PAGE_ID,
          logged_out_greeting: handleTranslate('facebook.chat.login'),
          logged_in_greeting: handleTranslate('facebook.chat.logout'),
          greeting_dialog_delay: 120,
          greeting_dialog_display: 'fade',
        }}
      />
    );
  };

  return {
    handleRender,
  };
};

export { useFacebookChat };
