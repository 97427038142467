import * as React from 'react';
import { AppLocalizationKey } from '~/common/types/types';
import { useAppTranslation } from '~/hooks/hooks';

type Props = {
  message: AppLocalizationKey;
  className?: string;
};

const FormattedMessage: React.FC<Props> = ({ message, className }) => {
  const { handleTranslate } = useAppTranslation();

  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: handleTranslate(message) }}
    />
  );
};

export { FormattedMessage };
