import { AppRoute, AboutTabItem } from '~/common/enums/enums';
import { NavigationPayload, TabLink } from '~/common/types/types';

const aboutTabItemToPath = {
  [AboutTabItem.INDEX]: AppRoute.ABOUT,
  [AboutTabItem.FAQ]: AppRoute.ABOUT_FAQ,
  [AboutTabItem.COB]: AppRoute.ABOUT_COB,
  [AboutTabItem.BS]: AppRoute.ABOUT_BS,
} as const;

const tabLinks: TabLink[] = Object.values(AboutTabItem).map((it) => ({
  name: it,
  title: `about.${it}.title`,
  localLink: aboutTabItemToPath[it],
}));

const navigationPayload: NavigationPayload = {
  title: 'about.title',
  description: 'about.description',
  tabLinks,
};

export { navigationPayload };
