"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppLanguage = void 0;
var AppLanguage;
(function (AppLanguage) {
    AppLanguage["UA"] = "ua";
    AppLanguage["RU"] = "ru";
    AppLanguage["EN"] = "en";
})(AppLanguage || (AppLanguage = {}));
exports.AppLanguage = AppLanguage;
