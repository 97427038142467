import { AppRoute } from '~/common/enums/enums';
import { AppLocalizationKey, QuestionsPayload } from '~/common/types/types';
import { generateItems } from '~/helpers/helpers';

const ITEMS_COUNT = 3;

const questionsPayload: QuestionsPayload = {
  title: 'questions.title',
  items: generateItems(ITEMS_COUNT, (idx) => ({
    question: `questions.group${idx}.question` as AppLocalizationKey,
    answer: `questions.group${idx}.answer` as AppLocalizationKey,
  })),
  question: 'questions.question',
  link: AppRoute.ABOUT_FAQ,
};

export { questionsPayload };
