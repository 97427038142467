import * as React from 'react';
import {
  AppLocalizationKey,
  LandingItem as TLandingItem,
} from '~/common/types/types';
import { LandingItem } from '../components';
import { getValidClasses } from '~/helpers/helpers';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './landing-list.module.scss';

type Props = {
  items: TLandingItem[];
  description: AppLocalizationKey;
};

const LandingList: React.FC<Props> = ({ description, items }) => (
  <div className={styles.wrapper}>
    <p className={styles.description}>
      <FormattedMessage message={description} />
    </p>
    <ul
      className={getValidClasses(
        styles.list,
        styles[`listLenght${items.length}`],
      )}
    >
      {items.map((item) => (
        <LandingItem item={item} key={item.title} />
      ))}
    </ul>
  </div>
);

export { LandingList };
