import {
  AppLocalizationKey,
  BSADescriptionPayload,
} from '~/common/types/types';
import { generateItems } from '~/helpers/helpers';

const TIMELINE_ITEMS_COUNT = 2;
const BENEFITS_ITEMS_COUNT = 6;

const bsaDescriptionPayload: BSADescriptionPayload = {
  title: 'bsaDescription.title',
  description: 'bsaDescription.description',
  timelineTitle: 'bsaDescription.timelineTitle',
  timelineItems: generateItems(TIMELINE_ITEMS_COUNT, (idx) => ({
    period: `bsaDescription.timelineItem${idx}.period` as AppLocalizationKey,
    title: `bsaDescription.timelineItem${idx}.title` as AppLocalizationKey,
    description:
      `bsaDescription.timelineItem${idx}.description` as AppLocalizationKey,
  })),
  benefitTitle: 'bsaDescription.benefitTitle',
  benefitItems: generateItems(BENEFITS_ITEMS_COUNT, (idx) => {
    return `bsaDescription.benefitItem${idx}` as AppLocalizationKey;
  }),
};

export { bsaDescriptionPayload };
