import * as React from 'react';
import { MentorsItem as TMentorsItem } from '~/common/types/types';
import { Image, FormattedMessage } from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './mentors-item.module.scss';

type Props = {
  item: TMentorsItem;
};

const MentorsItem: React.FC<Props> = ({ item }) => {
  const { name, imgPath, isTrackOwner } = item;

  return (
    <article className={styles.item}>
      <div
        className={getValidClasses(
          styles.imageWrapper,
          isTrackOwner && styles.imageWrapperTrackOwner,
        )}
      >
        <Image
          className={styles.image}
          height="225"
          src={imgPath}
          isDraggable={false}
          alt="mentor"
        />
        {isTrackOwner && <span className={styles.label}>Track Owner</span>}
      </div>
      <h3 className={styles.title}>
        <FormattedMessage message={name} />
      </h3>
    </article>
  );
};

export { MentorsItem };
