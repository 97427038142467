import * as React from 'react';
import { TechtimeItem as TTechtimeItem } from '~/common/types/types';
import { TechtimeItem } from '../components';
import * as styles from './techtime-list.module.scss';

type Props = {
  items: TTechtimeItem[];
};

const TechtimeList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <TechtimeItem item={it} key={it.title} />
    ))}
  </ul>
);

export { TechtimeList };
