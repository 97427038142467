import * as React from 'react';
import { ExamplesItem as TExamplesItem } from '~/common/types/types';
import { TagList, FormattedMessage, Image } from '~/components/common/common';
import * as styles from './examples-item.module.scss';

type Props = {
  item: TExamplesItem;
};

const ExamplesItem: React.FC<Props> = ({ item }) => (
  <article className={styles.item}>
    <div>
      <h3 className={styles.title}>
        <FormattedMessage message={item.title} />
      </h3>
      <p className={styles.description}>
        <FormattedMessage message={item.description} />
      </p>
      <TagList items={item.technologies} />
    </div>
    <div className={styles.imgWrapper}>
      <Image src={item.imgPath} loading="eager" alt="" isDraggable={false} />
    </div>
  </article>
);

export { ExamplesItem };
