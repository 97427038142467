import * as React from 'react';
import { BlogsPayload, SectionUIProps } from '~/common/types/types';
import { FormattedMessage, SectionLayout } from '~/components/common/common';
import { BlogsList } from './components/components';
import * as styles from './blogs.module.scss';

const BLOGS_CLASSES = {
  wrapper: styles.wrapper,
};

type Props = SectionUIProps & {
  payload: BlogsPayload;
};

const Blogs: React.FC<Props> = ({ payload, sectionHighlight }) => {
  const { titlePart1, titlePart2, items, readMoreText } = payload;

  return (
    <SectionLayout classes={BLOGS_CLASSES} sectionHighlight={sectionHighlight}>
      <h2 className={styles.title}>
        <FormattedMessage message={titlePart1} />{' '}
        <span className={styles.highlighted}>
          <FormattedMessage message={titlePart2} />
        </span>
      </h2>
      <BlogsList items={items} readMoreText={readMoreText} />
    </SectionLayout>
  );
};

export { Blogs };
