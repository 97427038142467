import { AppLocalizationMap } from '~/common/types/types';

const ENMessagesMap: AppLocalizationMap = {
  seo: {
    main: {
      title: 'Free online programming courses | Binary Studio Academy',
      description:
        'Become a skilled full-stack developer within 2,5 months - learn how to engineer complex web applications with JS or QA!',
    },
    about: {
      title: 'About Us | Binary Studio Academy',
      description:
        'At Binary Studio Academy`s free web development courses, we transform talented students into top-notch engineers able to create world-class software',
    },
    faq: {
      title: 'FAQ | Binary Studio Academy',
      description:
        'Find the answers to frequently asked questions about our free online programming course - Binary Studio Academy.',
    },
    bs: {
      title: 'Binary Studio | Binary Studio Academy',
      description:
        'Founded in 2005, Binary Studio focuses on web app development. Annually, we host Binary Studio Academy, a free online course for top industry talents.',
    },
    codex: {
      title: 'Learning Process | Binary Studio Academy',
      description:
        'Terms of studying at Binary Studio Academy - an annual intensive course covering JS and QA technologies.',
    },
    onlineEvent: {
      title: 'Homeference | Binary Studio Academy',
      description:
        'Homeference is a series of online web development broadcasts from Binary Studio Academy, check our series on this page.',
    },
    js: {
      title: 'Free online JavaScript course | Binary Studio Academy',
      description:
        'Learn how to engineer state-of-art web applications, master JS, React, Node.js and become a full-stack developer within 2,5 months.',
    },
    qa: {
      title: 'Free online QA course | Binary Studio Academy',
      description:
        'Master manual and automated testing instruments, get teamwork and real project development experience.',
    },
    foundingTeam: {
      title: 'Founding Team | Binary Studio Academy',
      description:
        'Meet the Binary Studio Academy founding team - the people behind the free online programming course.',
    },
  },
  registration: {
    end: 'Registration opening soon!',
    button: 'Sign up',
  },
  header: {
    about: 'About us',
    online: 'Online events',
    blog: 'Blog',
    login: 'Log in',
  },
  info: {
    title: 'How Academy works',
    group0: {
      title: 'An intense pace - full support',
      description:
        '2.5 months of full-time work on a real project + feedback from coaches throughout the Academy',
    },
    group1: {
      title: 'Practice-Theory-Practice',
      description:
        'Learning on real projects - roadmaps, daily meetings, code reviews, and SCRUM methodology are integral',
    },
    group2: {
      title: 'Binary Studio Engagement',
      description: '65% of the Binary Studio team is involved in the Academy',
    },
  },
  statistics: {
    item0: 'years',
    item1: 'applicants',
    item2: 'students',
    item3: 'people joined Binary Studio',
    item4: 'countries',
    item5: 'landing a job within first 2 month',
  },
  benefits: {
    title: 'What will you get?',
    group0: {
      title: 'Practical Application over Theory',
      description:
        'We guide you in building real-world applications, and top performers may secure job offers at Binary Studio with hands-on experience in commercial projects.',
    },
    group1: {
      title: 'Portfolio Development',
      description:
        'Gain your first project for your portfolio, showcasing your skills with pride. Collaborate with your peers in a real commercial development environment.',
    },
    group2: {
      title: 'Community-Centric Learning',
      description:
        'Engage in knowledge-sharing with students and coaches, and become part of a vibrant community of over 600 specialists, including Academy graduates from across the world.',
    },
  },
  timeline: {
    badge: 'Ongoing',
    section0: {
      title: 'How do I get into the Academy',
      subtitle: '',
      descriptor: 'Selective Rounds',
      group0: {
        title: 'Registration',
        description: 'Register for Binary Studio Academy 2024.',
      },
      group1: {
        title: '1st Qualification Step',
        description:
          'Pass an online test on the basics of programming and English.',
        dateSeparator: 'and',
      },
      group2: {
        title: '2nd Qualification Step',
        description:
          'Complete homework assignments following three video lectures.',
      },
    },
    section1: {
      title: 'Binary Studio Academy schedule',
      subtitle: 'At this point, we will be happy to see you as a BSA student!',
      descriptor: 'Studies Rounds',
      group0: {
        title: 'Lectures',
        description:
          'Master new technologies with the help of 10+ online lectures and homework assignments.',
        dateSeparator: 'and',
      },
      group1: {
        title: 'Interview',
        description:
          'Meet the Binary Studio Academy team during a 15-minute zoom call.',
      },
      group2: {
        title: 'Project',
        description:
          'Collaborate with fellow students to construct an MVP-level web application from the ground up.',
      },
      group3: {
        title: 'Demo',
        description:
          'Showcase your project on online demo, receive your certificate, and get the opportunity to join the Binary Studio team.',
      },
    },
  },
  review: {
    title: 'What our graduates appreciate',
    group0: {
      commentary:
        'Binary Studio Academy is a great place to develop and improve your skills. With the help of a powerful lecture base and practical tasks, which are almost identical to real challenges in the field of work, I received the necessary knowledge for a successful start of my career. The project stage gave me the opportunity to identify both my weak and strong points in cooperation with the team, as well as to familiarize myself with the full cycle of developing a full-fledged project.<br/><br/>Looking back, I can safely say that studying at the Binary Studio Academy was not only a challenging and exciting period, but also played a decisive role in shaping my professional path.',
      authorName: 'Anastasiia Chichik',
      authorStatus: 'graduate 2023',
      authorExJob: 'former student',
    },
    group1: {
      commentary:
        'Binary Studio Academy was a game-changer in my journey to becoming a qualified developer. Unlike other courses, where only technical knowledge is provided, Binary Studio Academy also offers hands-on experience on a real project with a real team. Working on a real project with daily team calls and discussions made it feel like we were in a real job environment.<br/><br/>Working on challenging tasks involving diverse tasks such as 3rd party integrations and the development of an MVP in just 6 weeks was one of the key points that taught us about the challenges in a real-world project. Despite having no prior job experience, the collaborative code reviews and supportive environment greatly accelerated my technical growth.',
      authorName: 'Farid Shabanov',
      authorStatus: 'graduate 2022',
      authorExJob: 'former student',
    },
    group2: {
      commentary:
        'The Academy is a magical combination of development, people, relationships, knowledge and achievements.<br/>Each new challenge provides an opportunity to learn new things, communicate with like-minded people.<br/>For me, the Academy opened the door to aspects of the development lifecycle that I didn’t previously understand or even realize existed. This experience is important. It is useful, inspiring, and gives strength to move forward and conquer new goals.<br/><br/>I want to share a few recommendations that will make the journey more exciting for the next students: be curious, thirst for knowledge, do everything to the maximum for yourself, help others and communicate.',
      authorName: 'Volodymyr Palchyn',
      authorStatus: 'graduate 2023',
      authorExJob: 'former category manager',
    },
    group3: {
      commentary:
        'Attending Binary Studio Academy was an amazing experience that really challenged and motivated me. The lectures gave me both theory and practical skills so I could learn about different parts of software development. What was unique about the Academy was that we got to work on real projects with a team. This helped me not only improve my technical skills but also understand the importance of working together, being responsible, and aiming for the same goal. Looking back, the Academy played a significant role in starting my career in the tech industry. It gave me the skills and confidence to succeed in this fast-changing field.',
      authorName: 'Veronika Sirak',
      authorStatus: 'graduate 2023',
      authorExJob: 'former Statistics Tutor',
    },
    group4: {
      commentary:
        'Academy was an experience of its own.</br> Everyone was very outgoing and willing to help to overcome any obstacle. Having people of different levels within the same team also allowed me to look up at someone more experienced that increased my growth.</br>The Academy also provided a valuable understanding of how development pipeline works in big companies and how communication within development teams looks.',
      authorName: 'Vladyslav Dobychin',
      authorStatus: 'graduate 2023',
      authorExJob: 'former English Teacher',
    },
  },
  history: {
    title: 'A history of Binary Studio Academy',
    group0: {
      year: 2011,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Graduates',
      title: 'First Academy',
      historyText:
        'Binary Studio Academy was founded. The company was located in Donetsk and conducted the first online programming courses in the city`s history, focusing on both .NET and PHP technologies. Theoretical lectures were held onsite at the Binary Studio`s office.',
    },
    group1: {
      year: 2012,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Graduates',
      title: 'New curriculum',
      historyText:
        'Binary Studio Academy gains momentum and sharpens its focus. Starting from this year, students are tasked to work on actual projects as well as attend lectures and complete homework assignments.',
    },
    group2: {
      year: 2013,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Graduates',
      title: 'New tracks',
      historyText:
        'Binary Studio Academy grows in popularity. We expand the course cirriculum, including a C++ path and graduating nearly 30 IT specialists.',
    },
    group3: {
      year: 2014,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Graduates',
      title: 'Going online',
      historyText:
        'A turning point in Academy and Binary Studio history. At the height of the Academy screening process, the company relocates from Donetsk to Western Ukraine due to the political situation in the East. This year is the first where the course is conducted online.',
    },
    group4: {
      year: 2015,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Graduates',
      title: 'Recognition and growth',
      historyText:
        'Academy receives recognition from IT Education Awards. We introduce a new curriculum - Quality Assurance. We also increase the entrance requirements, and introduce offline meetups in addition to our online course, inviting students and graduates to demo their projects at our new Lviv office.',
    },
    group5: {
      year: 2016,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Graduates',
      title: 'Gaining momentum',
      historyText:
        'The percentage of Academy graduates in Binary Studio`s own staff reaches 40%. We continue to raise the bar of quality for our education program, and start bringing applicants to our office for both the first lecture and graduation.',
    },
    group6: {
      year: 2017,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Graduates',
      title: 'Partnering with top universities',
      historyText:
        'Binary Studio Academy partners with 5 leading universities across Ukraine to attract more than 3000 applicants from 20 different cities. We update our curriculum for JS, JAVA, .NET and QA tracks, and instruct 6 separate teams of students simultaneously.',
    },
    group7: {
      year: 2018,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Students',
      title: 'Improving learning experience',
      historyText:
        'To improve learning experience of our students we update learning management system (LMS) and release new version of our mobile application.',
    },
    group8: {
      year: 2019,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Students',
      title: 'Adding a new preparation stage',
      historyText:
        'We will add one more stage - a mini-project - in order to help students better prepare for upcoming lectures and group project development. Students learn about project architecture while waiting for enrollment results.',
    },
    group9: {
      year: 2020,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Students',
      title: 'Adding a new study track',
      historyText:
        'We are introducing the Java track, which immediately became the second most popular among applicants. This year we prove that there are no age restrictions for studying at the Academy: the oldest (50 years) student is studying along with everyone else, and the youngest (11 years old!) presents his team`s project at the open-air Demo day. The percentage of Academy graduates in the Binary Studio team reaches 82%.',
    },
    group10: {
      year: 2021,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Students',
      title: 'Launching the first Winter Academy',
      historyText:
        'This year we are launching the first Winter Academy in the JS intensive format. More than 600 students from previous Summer Academies decided to try their luck once again! We introduce quality criteria aimed to assess the code of all students and celebrate 10 years of the Academy with a new record for the number of applicants!',
    },
    group11: {
      year: 2022,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Students',
      title:
        'We’ve added 14 New Countries and a record number of international students!',
      historyText:
        'In 2022, Binary Studio Academy can be called an international academy. This summer, we attracted students from 16 countries. From now on, the Academy offers 3 tracks in English to ensure that every student can benefit from the best learning opportunities at BSA. Lectures, tests, homework, product documentation, and weekly demos are now also in English so that students can adapt to the real conditions of commercial development while studying. We have also started providing even more self-study materials at the registration stage so that everyone can prepare for the entrance exams.',
    },
    group12: {
      year: 2023,
      applicantsTitle: 'Applicants',
      studentsTitle: 'Students',
      title: 'Expanded our presence to Latin America and Asia',
      historyText:
        '2023 was amazing year of growth and connection! We’ve been thrilled to receive over 15,000 applications from aspiring tech enthusiasts spanning 30+ countries. It’s been heartwarming to see our international team expand, and we’re proud to have welcomed top talents from Latin America and Asia after successfully graduating from the Academy into our midst.',
    },
  },
  past: {
    title: 'See how students present their demo projects',
  },
  questions: {
    title: 'Questions and Answers',
    group0: {
      question: 'How to get into Academy?',
      answer:
        'The Academy takes place once a year. Register for the Academy. In order to enter the Academy after registration, you will need to go through a selection process: pass an online test, complete several home assignments and pass an interview with the Academy team representative.',
    },
    group1: {
      question: 'Will I receive an offer of employment after I finish Academy?',
      answer:
        'We offer the best students an opportunity to join the Binary Studio team remotely. Beyond this, each of our Academy graduates will have enough knowledge and skills to pass a professional interview at any IT company.',
    },
    group2: {
      question: 'How much does Academy cost?',
      answer: 'Enrollment and participation in Academy is absolutely free.',
    },
    question: 'Got questions?',
  },
  landing: {
    title: 'Binary Studio Academy',
    subtitleBottom: 'An online intensive course on building web applications',
    description:
      'A two and a half month-long course to transform novice developers into true engineers who are capable of building world-class software.',
    item0: 'Totally free',
    item1: 'Practical',
    item2: 'Online',
  },
  directions: {
    title: 'Choose your track',
  },
  join: {
    title: 'Ready to join?',
    description: 'We’ll be waiting for you in Binary Studio Academy 2024.',
    postscript:
      'In the meantime subscribe to our <a href="https://t.me/bsacademy">Telegram channel</a> not to miss our event announcements, helpful materials and insights and interesting articles.',
  },
  facebook: {
    chat: {
      login: 'Hi! Got questions? Ask BSA team here!',
      logout: 'Hi! Got questions? Ask BSA team here!',
    },
  },
  events: {
    past: 'Past events',
    watch: 'Watch record',
  },
  bsaDescription: {
    title: 'Binary Studio Academy',
    description:
      'A free online intensive course on building web applications with JS and QA.',
    timelineTitle: 'What the course consists of',
    timelineItem0: {
      period: 'July – August',
      title: 'Lectures',
      description:
        'Master new technologies with the help of 15 online lectures and several homework assignments.',
    },
    timelineItem1: {
      period: 'August – September',
      title: 'Project',
      description:
        'With your fellow students, build an MVP-level web application from scratch using SCRUM methodologies.',
    },
    benefitTitle: 'What you’ll get',
    benefitItem0: 'Sure grip of modern frameworks and tools',
    benefitItem1:
      'Ability to write clean code, using industry’s best practices',
    benefitItem2:
      'Experiense of front-end and back-end development (for all programming tracks)',
    benefitItem3: 'Skills of working on a project in a team',
    benefitItem4: 'Full-stack project in portfolio',
    benefitItem5: 'An opportunity to join Binary Studio team',
  },
  technology: {
    js: {
      landing: {
        title:
          'An online intensive course on building web applications with <strong>JavaScript</strong>',
        subtitleTop: 'Binary Studio Academy',
        description:
          'Binary Studio’s top developers will introduce you to modern JavaScript technologies and teach you to create state-of-the-art applications',
        item0: '2.5 month',
        item1: 'Full-stack experience',
        item2: 'Totally free',
        item3: 'Online',
      },
      info: {
        title: 'What you gain',
        group0: {
          title: 'Accelerated Learning:',
          description:
            '2.5-month intensive program led by experienced mentors and top-tier junior developers.',
        },
        group1: {
          title: 'Full-Stack Experience:',
          description:
            'Hands-on experience in full-stack development with the latest JS, React, and Node.js technologies.',
        },
        group2: {
          title: 'Mentor Guidance:',
          description:
            'Regular code reviews and feedback from seasoned JS developers.',
        },
        group3: {
          title: 'Diverse Tech Tasks:',
          description:
            'Work on tasks ranging from Docker and AWS to React Native for mobile app development.',
        },
        group4: {
          title: 'Collaborative Environment:',
          description:
            'Team projects with the best students, using SCRUM and Agile methodologies.',
        },
        group5: {
          title: 'Job Opportunity:',
          description:
            'Top graduates may secure a remote job offer with Binary Studio.',
        },
        group6: {
          title: 'Certification:',
          description:
            'Receive Binary Studio Academy’s Certificate of Completion.',
        },
      },
      techtime: {
        title: 'What the JavaScript course consists of',
        group0: {
          title: 'Lectures',
          period: 'June – August',
          description:
            'We’ll fill you in on the details about your upcoming team project and prepare you with fundamental knowledge in JS frameworks.',
          activity:
            'On your personal account page, there will be 10-15 video lectures with homework assignments and correspondending deadlines.  Seasoned Binary Studio team members will check your assignments, and provide feedback/recommendations.',
        },
        group1: {
          title: 'Project',
          period: 'August – September',
          description: 'We’ll teach you how to build a world-class application',
          activity:
            'Students will be divided into teams, and with the mentorship of their coaches will create an MVP-level project. Coaches will assist on all stages of development, conduct daily calls with the team, and perform retrospectives and reviews.',
        },
      },
      examples: {
        title: 'Examples of projects',
        group0: {
          title: 'DEV SKILLS TRAINER',
          description:
            'CodeTrainer is an online educational platform for software developers where users can exchange their knowledge by solving programming puzzles. Users can add their own challenges and create collections by adding different challenges. The solution also includes such features like feedback gathering, clan creation, fighting for honorable places in the community, etc.',
        },
        group1: {
          title: 'PODCAST PLATFORM',
          description:
            'A platform for recording, storing, and supporting podcasts. The solution allows you to record a podcast in real time (people can go in and comment on the podcast while recording). Users can create favorite podcast lists, leave comments and likes. A private podcast option is also available.',
        },
      },
      mentors: {
        title: 'Mentors',
        mentor0: 'Vladyslav Zubko',
        mentor1: 'Mykyta Remeslov',
        mentor2: 'Anton Komisarov',
        mentor3: 'Vitaliy Kalenichenko',
        mentor4: 'Farid Shabanov',
        mentor5: 'Dmytro Popovych',
      },
      join: {
        title: 'Want to master JavaScript?',
      },
    },
    qa: {
      landing: {
        title: 'An online intensive course on <strong>QA</strong>',
        subtitleTop: 'Binary Studio Academy',
        description:
          'Experienced QA engineers at Binary Studio will help you to master key testing techniques, understand the role of testing in the development process, and introduce you to current trends',
        item0: '2.5 month',
        item1: 'Totally free',
        item2: 'Online',
      },
      info: {
        title: 'Start off your career in QA the right way',
        group0: {
          title: 'Practical Challenges:',
          description:
            'Refine test design skills with effective QA methodologies on a live project.',
        },
        group1: {
          title: 'Automated Testing:',
          description:
            'Master Postman for API testing, practicing automated test creation.',
        },
        group2: {
          title: 'Professional support:',
          description:
            'Guidance from experienced QA engineers deepens your understanding of the QA role.',
        },
        group3: {
          title: 'Realistic conditions:',
          description:
            'Operate in a SCRUM team for hands-on experience in complex application development.',
        },
        group4: {
          title: 'Certificate of completion:',
          description:
            'Earn Binary Studio Academy’s Certificate, a mark of success in a competitive software development school.',
        },
        group5: {
          title: 'Job Offer from Binary Studio:',
          description:
            'Top graduates may secure a remote role with Binary Studio.',
        },
      },
      techtime: {
        title: 'What the QA course consists of',
        group0: {
          title: 'Lectures',
          period: 'June – August',
          description:
            'You will fill in the gaps and get all the necessary basis for further work on the project.',
          activity:
            'On your personal account page, there will be 10-15 video lectures with homework assignments and correspondending deadlines.  Seasoned Binary Studio team members will check your assignments, and provide feedback/recommendations.',
        },
        group1: {
          title: 'Project',
          period: 'August – September',
          description:
            'We’ll teach you how to build a world-class application.',
          activity:
            'Students will be divided into teams, and with the mentorship of their coaches will create an MVP-level project. Coaches will assist on all stages of development, conduct daily calls with the team, and perform retrospectives and reviews.',
        },
      },
      examples: {
        title: 'Examples of projects',
        group0: {
          title: 'FUNDRAISING PLATFORM',
          description:
            'This platform helps people create their own fundraising projects and attract sponsors. The user can support somebody’s idea and donate towards its realization. There are several features such as recommendations for project authors and sponsors, statistics on top themes, diagrams of hype dynamic on certain themes, information about related projects and their current state, and many more.',
        },
        group1: {
          title: 'INTERACTIVE PRESENTATIONS APPLICATION',
          description:
            'A service for interactive presentations that allows speakers to share access and grant viewing rights to others. This solution allows users real-time communication with your audience, by providing an editor plugin for Google Slides, so users can integrate interactions into existing presentations. Users will have to install Google App Script addon and Google Chrome extension to access full functionality. They will detect interactive slides and replace them with real-time updating elements, such as Q&A screens and polls.',
        },
      },
      mentors: {
        title: 'Mentors',
        mentor0: 'Marharyta Rovna',
        mentor1: 'Volodymyr Yarosh',
        mentor2: 'Stanislav Horkusha',
      },
      join: {
        title: 'Want to master QA?',
      },
    },
  },
  about: {
    title: 'Get acquainted?',
    description:
      'Here we tell who we are, why we do it and for the sake of what. If you have any questions and you want to know more, this block is for you.',
    index: {
      title: 'What do we do',
      group0: {
        title: 'Who we are',
        paragraph0:
          'Binary Studio Academy is a free, intensive online course held each summer which covers JS and QA technologies for software engineers.',
        paragraph1:
          'Each spring, we open registration for the following academic season, where we select the strongest applicants and turn them into qualified Junior developers and QA specialists.',
      },
      group1: {
        title: 'What do we do',
        paragraph0:
          'We help novice developers to deepen and sharpen their existing programming knowledge, driving breakthroughs in their understanding of development theory while guiding them to mastery of modern frameworks and technologies.',
        paragraph1:
          'Academy students receive experience in working within a team under the same conditions they’ll face out in the real world of commercial software development, which culminates in obtaining a full-stack project for their own professional portfolio.',
      },
      group2: {
        title: 'Our mission',
        paragraph0:
          'Binary Studio Academy’s mission is to transform talanted and motivated students into qualified engineers capable of building world-class software.',
      },
      group3: {
        title: 'Vision',
        paragraph0:
          'The Academy program came into being thanks to the aspirations of Binary Studio’s own devleopment team and their desire to work with only the best engineers in the country. This is why, despite the program’s constant evolution, the fundamental principles of Academy remain the same.',
        paragraph1:
          'We strive to create a strong and meaningful community within the IT sphere. Binary Studio Academy exists in order to provide talanted and motivated junior developers practical knowledge and experience.',
        paragraph2:
          'Binary Studio Academy has been, is, and always will free of charge - it is not a commercial product, but a source of internal innovation for our company.',
        paragraph3:
          'The mentors, coaches, and organizers all consist of Binary Studio’s own existing staff. They develop the program during their own free time, fueld by their own enthusiasm and volunteer efforts. This means that the Binary Studio Academy team doesn’t receive any sort of financial reward for their participation. Instead, they get something considerably more valuable - an opportunity to develop their leadership skills as well as learn (and use) new technologies. They also get the chance to share their knowledge, and give students practical education, and as a result, engaging work. They’re also motivated to take part in large, common cause, and are inspired by the progress of every student. In this way, Binary Studio Academy is the anchor of our community - come and join us, and you’ll see for yourself!',
        paragraph4:
          'One interesting fact - 82% of the Binary Studio team are graduates from previous Acadamies over the last 10 years. This means that your teachers, mentors and coaches are all well versed in the Academy program and have a clear understanding of how to make your training as effective as possible.',
      },
    },
    faq: {
      title: 'FAQ',
      group0: {
        question: 'How can I get into Academy?',
        answer:
          'Register on the Academy site.\nPrepare for the entrance exam. There are links ot useful resources and various information, which will help you get ready for the exam. You can find them in on your personal account page, the Academy site, or in our Telegram channel.\nPass the selection stage: pass the online test, complete some homework assignments, and speak with the Binary Studio Academy admissions team.',
      },
      group1: {
        question: 'What are the requirements to enter Academy?',
        answer:
          'Have some base knowledge of JS.  Know how to work with databases (for example, with SQL), and understand the principles of OOP and/or FP. Have at least a pre-intermediate level of English.  Be able to commit 8+ hours each day to your students from the 6st of July to the 12th of September. Don’t worry - after registration, you’ll receive access to preperation materials on your personal account page.',
      },
      group2: {
        question:
          'If I registered for Academy last year, do I need to register again?',
        answer:
          'If you already created a profile on the Binary Studio Academy site, your information is saved in our system. You don’t need to register again - simply declare your education track you’re interested in this year on your personal account page.',
      },
      group3: {
        question: 'How much does Academy cost?',
        answer: 'Enrollment and participation in Academy is absolutely free.',
      },
      group4: {
        question: 'What is the language of study?',
        answer:
          'During account setup, you can choose which language you’d prefer to operate in:  Ukrainian or Russian.  Homework, stage descriptions, and all information in your personal profile and entrance test will be displayed accordingly.  However, lectures are not translated:  some of them are recorded in Ukrainian, while others are in Russian.',
      },
      group5: {
        question: 'Is there any age limitations on applying to Academy?',
        answer:
          'For enrollment into Academy, there are no age limitations, residency requirements, or educational qualifications which need to be met. The only hard requirement is the candidate’s technical competency level.',
      },
      group6: {
        question:
          'How important is my English level when considering my enrollment?',
        answer:
          'In 2019, we simplified the requirements of enrollment, removing the English test screening altogether.  For participation in Academy, it is enough to a level of English enabling you to read documentation.  However, for actual work with English-speaking clients in the IT sphere - a high level of English is a must-have.',
      },
      group7: {
        question:
          'How is the entrance exam structured regarding the different technology tracks?',
        answer:
          'The test consists of thirty multiple choice questions based on the following topics:  OOP, databases, layouts, and JS.',
      },
      group8: {
        question: 'What about the QA track - how is that test structured?',
        answer:
          'The test consists of thirty multiple choice questions, focusing on your understanding of SDLC, layouts, and a basic understanding of JS.',
      },
      group9: {
        question: 'Why is the test divided into two days?',
        answer:
          'We conduct two sessions of testing, so that each candidate may choose a convenient time.  You can only take the test once, on either the 25th or 29th of April, from 10:00 to 00:00.',
      },
      group10: {
        question: 'Can I take the test twice?',
        answer:
          'Over the course of a single Academy admissions session, you may take the test only once and for only one technology track. Duplicate profiles will be deleted from our system. If you do not receive enough points to pass the test, you’ll be able to retake the exam the following year.',
      },
      group11: {
        question:
          'Can I participate in more than one technology track at the same time?',
        answer:
          'For enrollment and participation in Academy, it is necessary to commit to a single technology track during the registration phase.  The Academy program is extremely intensive, and to participate in more than one track is physically impossible - there are not enough hours in the day. :)',
      },
      group12: {
        question: 'Can I change my technology track after Academy? How?',
        answer:
          'Yes, after registration but before you take the enrollment test, you may change your technology track.  To do this, write a request to academy@binary-studio.com and the organizers will help you out.',
      },
      group13: {
        question:
          'Can I simultaneously go through Academy while working a full-time job?',
        answer:
          'To successfully pass the course, you will be required to do an awful lot of work and commit a great deal of time to your studies.  Mixing your Academy time with work will be difficult, but it is possible - under the condition that your work will not hinder your commitments to working on your Academy project and completing assignments.',
      },
      group14: {
        question: 'How many students will be admitted?',
        answer:
          'We accept the top 5% of applicants as a result of our screening process, which includes the entrance exam, three homework assignments, and a Zoom interview with representatives of our Academy program.',
      },
      group15: {
        question: 'What size will the Academy groups be?',
        answer:
          'We divide the groups who pass the screening phase based on their chosen technology tracks, and depending on the numbers, you can expect to be in a group with no more than 10 students total.',
      },
      group16: {
        question: 'What sort of project will I work on in Academy?',
        answer:
          'Students will develop state-of-the-art (not an exaggeration!) web platforms with a complex frontend, structured backend, and the use of several cloud services. Although the project will not actually be sold to a client, the development conditions are as close to the real deal as possible. Each project’s team will consist of developers and QA engineers, who will conduct online daily standup meetings. In addition to this, each project will have a Product Owner, who will participate in meetings from time to time and propose new requirements and offer feedback on the platform’s development.',
      },
      group17: {
        question:
          'How much time should I be expected to commit to my Academy studies?',
        answer:
          'Throughout July, you will be participating in the theoretical part of your studies. You will participate in 15 lectures, after each of which you’ll be expected to complete a homework assignment. The average homework assignment requires X amount of hours to complete. Over the course of six weeks from August-September, you will work in a team to produce an MVP-level web application. Teams and mentors will convene at a mutually agreed upon time for daily meetings over Zoom/Hangouts/Zoom, while you will work on your tasks at your own pace and schedule. In our experience, you can expect to spend no less than eight hours per day working on the project during this phase of the program.',
      },
      group18: {
        question:
          'Will I receive an offer of employment after I finish Academy?',
        answer:
          'We offer the best students an opportunity to join the Binary Studio team remotely. Beyond this, each of our Academy graduates will have enough knowledge and skills to pass a professional interview at any IT company. Although we don’t directly assist in job placement at the conclusion of Academy, we are willing to offer recommendations from our mentors and management team, who will vouch for your technical and professional ability. Plus, your team project will look marvelous in your portfolio.  :)',
      },
    },
    cob: {
      title: 'Code of conduct',
      group0: {
        title: 'Code of conduct',
        item0:
          'I will use only one profile to register for the test and participate in the Academy.',
        item1:
          'Tests and homeworks will be done on my own (except for team tasks).',
        item2:
          'I will not distribute the completed solutions and answers to other current or future students.',
        item3:
          'I will not use artificial means to improve my results or damage the results of other students.',
      },
      group1: {
        title: 'Terms of studying',
        item0: 'You can pass an Academy course only once.',
        item1:
          'Enrollment is only possible if the entrance test results are above a passing level, the three homework assignments are delivered on time, and the final interview is passed. Each student receives a letter with notification about his/her result after each selection stage.',
        item2:
          'All lecture materials, tests and tasks are intellectual property of the Binary Studio Academy, and their distribution is prohibited.',
        item3:
          'The studying is conducted online, so you can choose your own time for watching the lectures. However, we objectively assess the practical tasks by timeliness, quality and independent problem solving parameters. Also, the Academy pays extra attention to the organization of teamwork, so its deliberate abuse or Code of honour neglect will lead to the exclusion from the Academy.',
        item4:
          'By decision of the Academy, a student can be excluded from the learning process at any time without explanation.',
        item5:
          'A student who fulfills the requirements of the project will receive a certificate of acquired skills.',
      },
    },
    bs: {
      title: 'Binary Studio',
      group0: {
        title: 'Who we are?',
        paragraph0:
          'Binary Studio is a Ukrainian company focusing on complex application development that has existed since 2005. The company is included in the TOP five IT companies in Lviv and actively supports the local community of programmers by conducting technical meetings such as Submit, as well as holding Academy for students around the world.',
      },
      group1: {
        title: 'What Makes Us Different',
        paragraph0:
          'There is a very high threshold of entrance to the company and an advanced technical culture. One of the most important company values ​​is the continuous training and development of our community. For this purpose we organize opportunities for professional development.',
      },
    },
  },
  teamComposition: {
    title: 'Real project environment',
    group0: {
      title: 'Supercoach',
      description: 'Assures project delivery, works on architecture',
    },
    group1: {
      title: 'Coach',
      description: 'Organizes team, assigns tasks and holds daily meetings',
    },
    group2: {
      title: 'Developer',
      description:
        'Takes/receives tasks, writes code, cooperates with team mates, gets awesome experience',
    },
    group3: {
      title: '',
      description: '',
    },
    group4: {
      title: 'QA',
      description:
        'Analyzes requirements, tests, cooperates with developers, gets awesome experience',
    },
    group5: {
      title: 'Product Owner',
      description:
        'Reviews product during weekly demo meetings, comes up with additional features to work on',
    },
    group6: {
      title: 'Designer',
      description: 'Makes project look professional & polished',
    },
  },
  blogs: {
    titlePart1: 'Binary Studio',
    titlePart2: 'Academy Blog',
    readMoreText: 'Read More',
    blog0: {
      title: 'From BSA to Binary Studio: Alumni’s path',
      author: 'Alina Kupchyk',
      content:
        'Hello to all students, Binarians, and anyone who has stumbled upon this narrative by chance. My name is Alina, and I am a graduate of the 2022 Summer Academy on the JS track. Today, I’ll share my story with you.',
    },
    blog1: {
      title: 'Mastering Clean Code: Unlocking Programming Principles’ Power',
      author: 'Farid Shabanov',
      content:
        'Each programming language has its own set of best practices. These practices help developers write scalable code, readable, and organized code.',
    },
    blog2: {
      title: 'From the Circus to IT',
      author: 'Dmytro Kulakov',
      content:
        'Hello! My name is Dmytro, I work as a full-stack developer at Binary Studio and I want to share the story of how at the age of 31 I switched from acrobatics to programming.',
    },
  },
  foundingTeam: {
    ceoName: 'Artem Goncharov',
    ceoPosition: 'Founder of Binary Studio Academy',
    paragraph0:
      'Artem Goncharov, the visionary founder of Binary Studio Academy, is transforming the tech education landscape in Eastern Europe. With a strong belief in practical learning and a commitment to fostering a new generation of engineering talent, Artem has created an intensive program that bridges the gap between theoretical knowledge and real-world application.',
    paragraph1:
      'Artem’s journey in the tech industry began with a Master’s degree in Computer Science from Donetsk National University. He later expanded his expertise with an MBA from the prestigious Haas School of Business at the University of California, Berkeley, where he honed his entrepreneurial skills and strategic thinking.',
    paragraph2:
      'Recognizing the gaps in traditional tech hiring practices, Artem founded Binary Studio Academy to cultivate talented students into skilled software engineers and tech leaders. The Academy’s intensive 3-month program transforms top-tier Computer Science and Engineering university students into job-ready software developers by simulating a real-world development environment and focusing on hands-on experience. It takes place twice a year and trains engineers and QA specialists.',
    paragraph3:
      'The Academy’s curriculum emphasizes both hard skills and soft skills. Students are expected to write high-quality code, demonstrate a solid understanding of their chosen technology stack, and showcase their ability to support and extend existing applications. The program also places a strong emphasis on communication skills, proactivity, ownership, and an entrepreneurial spirit, encouraging students to actively participate in stand-ups, present their work results logically and concisely, and take full responsibility for their tasks from start to finish.',
    paragraph4:
      'Under Artem’s leadership, Binary Studio Academy has become increasingly popular, attracting thousands of applicants each year. In 2023, the Academy reached an impressive milestone of 15,000 applications, with only the most talented and dedicated students making it through the rigorous selection process.',
    paragraph5:
      'Artem Goncharov’s entrepreneurial spirit, technical expertise, and commitment to empowering the next generation of tech leaders have positioned him at the forefront of transforming tech education. Through Binary Studio Academy, he continues to inspire, nurture, and develop talented individuals, equipping them with the skills and mindset needed to thrive in the ever-evolving world of technology.',
  },
};

export { ENMessagesMap };
