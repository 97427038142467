import * as React from 'react';
import { DateFormat, IconName } from '~/common/enums/enums';
import {
  AppLocalizationKey,
  TimelineItem as TTimelineItem,
} from '~/common/types/types';
import {
  getFormattedDate,
  getValidClasses,
  checkIsDatesInRangeByNow,
  checkIsDateToday,
  checkIsDateExpired,
} from '~/helpers/helpers';
import { FormattedMessage, Icon } from '~/components/common/common';
import * as styles from './timeline-item.module.scss';

type Props = {
  item: TTimelineItem;
  badgeText: AppLocalizationKey;
};

const TimelineItem: React.FC<Props> = ({ item, badgeText }) => {
  const { title, description, dateStart, dateEnd, dateSeparator } = item;

  const hasDateEnd = Boolean(dateEnd);
  const hasDateSeparator = Boolean(dateSeparator);

  const isExpired = checkIsDateExpired(dateEnd ?? dateStart);
  const isDateInRange = hasDateEnd
    ? checkIsDatesInRangeByNow(dateStart, dateEnd as string)
    : checkIsDateToday(dateStart);

  return (
    <li
      className={getValidClasses(
        styles.item,
        styles.timelineBar,
        isDateInRange && styles.itemInRange,
        isExpired && styles.itemExpired,
      )}
    >
      {isDateInRange && (
        <FormattedMessage className={styles.badge} message={badgeText} />
      )}
      <div className={getValidClasses(styles.details)}>
        <h4 className={styles.title}>
          <FormattedMessage message={title} />
        </h4>
        <div className={styles.datesWrapper}>
          <Icon iconName={IconName.CALENDAR} className={styles.icon} />
          <time dateTime={dateStart}>
            {getFormattedDate(dateStart, DateFormat.D_MM)}
          </time>
          {hasDateEnd && (
            <>
              {hasDateSeparator ? (
                <FormattedMessage
                  message={dateSeparator as AppLocalizationKey}
                />
              ) : (
                '–'
              )}
              <time dateTime={dateEnd as string}>
                {getFormattedDate(dateEnd as string, DateFormat.D_MM)}
              </time>
            </>
          )}
        </div>
        <p className={styles.description}>
          <FormattedMessage message={description} />
        </p>
      </div>
    </li>
  );
};

export { TimelineItem };
