import { useMemo } from '~/hooks/hooks';
import { useLocation } from '@reach/router';

type UseRouter = () => {
  search: string;
  pathname: string;
  queries: Record<string, string>;
};

const useRouter: UseRouter = () => {
  const { pathname, search } = useLocation();

  return useMemo(
    () => ({
      pathname,
      search,
      queries: Object.fromEntries(new URLSearchParams(search).entries()),
    }),
    [pathname, search],
  );
};

export { useRouter };
