import { BSDescriptionPayload } from '~/common/types/types';

const bsDescriptionPayload: BSDescriptionPayload = {
  sections: [
    {
      title: 'about.bs.group0.title',
      items: ['about.bs.group0.paragraph0'],
    },
    {
      title: 'about.bs.group1.title',
      items: ['about.bs.group1.paragraph0'],
    },
  ],
};

export { bsDescriptionPayload };
