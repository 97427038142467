import * as React from 'react';
import { QuestionItem as TQuestionItem } from '~/common/types/types';
import { FormattedMessage, Icon } from '~/components/common/common';
import { IconName } from '~/common/enums/enums';
import * as styles from './question-item.module.scss';

type Props = {
  question: TQuestionItem;
};

const QuestionItem: React.FC<Props> = ({ question }) => (
  <li className={styles.item}>
    <Icon className={styles.icon} iconName={IconName.ARROW_RIGHT} />
    <div>
      <h3 className={styles.title}>
        <FormattedMessage message={question.question} />
      </h3>
      <p className={styles.description}>
        <FormattedMessage message={question.answer} />
      </p>
    </div>
  </li>
);

export { QuestionItem };
