import { StatisticsPayload } from '~/common/types/types';

const statisticsPayload: StatisticsPayload = {
  items: [
    {
      amount: '13',
      description: 'statistics.item0',
    },
    {
      amount: '40K+',
      description: 'statistics.item1',
    },
    {
      amount: '5K+',
      description: 'statistics.item2',
    },
    {
      amount: '400+',
      description: 'statistics.item3',
    },
    {
      amount: '20+',
      description: 'statistics.item4',
    },
    {
      amount: '80%',
      description: 'statistics.item5',
    },
  ],
};

export { statisticsPayload };
