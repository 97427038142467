enum QuerySymbol {
  START = '?',
  AND = '&',
}

const joinLinkQuery = (link: string, query: string): string => {
  const hasQuery = Boolean(query);

  if (!hasQuery) {
    return link;
  }

  const hasAlreadyQuery = link.includes(QuerySymbol.START);
  const updatedQuery = hasAlreadyQuery
    ? `${QuerySymbol.AND}${query}`
    : `${QuerySymbol.START}${query}`;

  const fullExternalLink = `${link}${updatedQuery}`;

  return fullExternalLink;
};

export { joinLinkQuery };
