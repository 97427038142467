import * as React from 'react';
import { FooterPartnerItem } from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import { Link } from '~/components/common/common';
import * as styles from './partners-item.module.scss';

type Props = {
  item: FooterPartnerItem;
};

const PartnersItem: React.FC<Props> = ({ item }) => {
  return (
    <li>
      <Link
        className={getValidClasses(styles.link, styles[item.title])}
        externalLink={item.link}
      >
        <span className="visually-hidden">{item.title}</span>
      </Link>
    </li>
  );
};

export { PartnersItem };
