import { JoinPayload } from '~/common/types/types';

const joinPayload: JoinPayload = {
  title: 'join.title',
  description: 'join.description',
  postscript: 'join.postscript',
  registrationBtn: 'registration.button',
};

export { joinPayload };
