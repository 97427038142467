import * as React from 'react';
import { InfoList } from './components/components';
import { InfoPayload, SectionUIProps } from '~/common/types/types';
import {
  Button,
  FormattedMessage,
  SectionLayout,
} from '~/components/common/common';
import * as styles from './info.module.scss';

type Props = SectionUIProps & {
  payload: InfoPayload;
  registrationLink: string | null;
};

const Info: React.FC<Props> = ({ payload, registrationLink }) => {
  const { title, items, registrationBtn } = payload;

  const hasRegistrationLink = Boolean(registrationLink);

  return (
    <SectionLayout headingMessage={title}>
      <InfoList items={items} />
      {hasRegistrationLink && (
        <div className={styles.btnWrapper}>
          <Button
            gtm="sign_up"
            externalLink={registrationLink as string}
            withLanguageQuery
            hasShadow
          >
            <FormattedMessage message={registrationBtn} />
          </Button>
        </div>
      )}
    </SectionLayout>
  );
};

export { Info };
