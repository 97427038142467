import { CarouselRef } from '~/common/types/types';
import { useEffect, useRef, useState } from '~/hooks/hooks';

type UseSliderNavigation = () => {
  ref: React.MutableRefObject<CarouselRef | null>;
  currentIdx: number;
  handleSlideSelect: (idx: number) => void;
};

const useSliderNavigation: UseSliderNavigation = () => {
  const ref = useRef<CarouselRef | null>(null);

  const initialIdx = 0;
  const [currentIdx, setCurrentIdx] = useState<number>(initialIdx);

  const handleSlideSelect = (idx: number): void => {
    const swiperInstance = ref.current?.swiper;

    if (swiperInstance) {
      swiperInstance.slideTo(idx);
      setCurrentIdx(idx);
    }
  };

  useEffect(() => {
    const swiperInstance = ref.current?.swiper;

    if (swiperInstance) {
      const handleIndexChange = (): void =>
        setCurrentIdx(swiperInstance?.activeIndex);

      swiperInstance.on('slideChange', handleIndexChange);

      return (): void => {
        swiperInstance?.off('slideChange', handleIndexChange);
      };
    }
  });

  return {
    currentIdx,
    handleSlideSelect,
    ref,
  };
};

export { useSliderNavigation };
