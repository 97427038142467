import * as React from 'react';
import { MentorsPayload, SectionUIProps } from '~/common/types/types';
import { SectionLayout } from '~/components/common/common';
import { MentorsList } from './components/components';

type Props = SectionUIProps & {
  payload: MentorsPayload;
};

const Mentors: React.FC<Props> = ({ payload }) => {
  const { title, items } = payload;

  return (
    <SectionLayout headingMessage={title} sectionIcon="crown">
      <MentorsList items={items} />
    </SectionLayout>
  );
};

export { Mentors };
