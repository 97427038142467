import * as React from 'react';
import {
  AppLocalizationKey,
  BlogItem as TBlogItem,
} from '~/common/types/types';
import { FormattedMessage, Link } from '~/components/common/common';
import { BlogAuthorImage } from '../components';
import * as styles from './blog-item.module.scss';

type Props = {
  item: TBlogItem;
  readMoreText: AppLocalizationKey;
};

const BlogItem: React.FC<Props> = ({ item, readMoreText }) => {
  const { image, author, title, content, externalLink, decorationKey } = item;

  return (
    <article className={styles.item}>
      <BlogAuthorImage src={image} decorationKey={decorationKey} />
      <span className={styles.author}>
        <FormattedMessage message={author} />
      </span>
      <h3 className={styles.title}>
        <FormattedMessage message={title} />
      </h3>
      <p className={styles.content}>
        <FormattedMessage message={content} />
      </p>
      <Link className={styles.link} externalLink={externalLink}>
        <FormattedMessage message={readMoreText} />
      </Link>
    </article>
  );
};

export { BlogItem };
