import { ENV } from '~/common/enums/enums';

type GetLocalLinkWithLanguage = (localLink: string, language: string) => string;

const getLocalLinkWithLanguage: GetLocalLinkWithLanguage = (
  localLink,
  language,
) => {
  const isDefaultLanguage = language === ENV.APP.DEFAULT_LANGUAGE;

  const languagePrefix = isDefaultLanguage ? '' : `/${language}`;

  return `${languagePrefix}${localLink}`;
};

export { getLocalLinkWithLanguage };
