import * as React from 'react';
import { Image } from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './blog-author-image.module.scss';

type Props = {
  src: string;
  decorationKey: number;
};

const BlogAuthorImage: React.FC<Props> = ({ src, decorationKey }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={getValidClasses(
          styles.container,
          styles[`containerDecoration${decorationKey}`],
        )}
      >
        <div
          className={getValidClasses(
            styles.circle,
            styles[`circleDecoration${decorationKey}`],
          )}
        >
          <Image
            className={getValidClasses(
              styles.img,
              styles[`imgDecoration${decorationKey}`],
            )}
            src={src}
            alt={'author'}
          />
        </div>
      </div>
    </div>
  );
};

export { BlogAuthorImage };
