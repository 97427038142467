import { AppRoute } from '~/common/enums/enums';
import { directionKindToReadable } from '~/common/maps/maps';
import { DirectionsPayload } from '~/common/types/types';
import { DIRECTIONS } from '~/common/constants/constants';

const directionList = DIRECTIONS.map((it) => ({
  title: directionKindToReadable[it],
  link: `${AppRoute.TECHNOLOGY}/${it}`,
}));

const directionsPayload: DirectionsPayload = {
  title: 'directions.title',
  items: directionList,
};

export { directionsPayload };
