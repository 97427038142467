import * as React from 'react';

type Props = {
  src: string;
  className?: string;
  loading?: 'lazy' | 'eager';
  width?: number | string;
  height?: number | string;
  alt: string;
  isDraggable?: boolean;
};

const Image: React.FC<Props> = ({
  loading = 'lazy',
  alt = '',
  src,
  width,
  height,
  className,
  isDraggable,
}) => (
  <img
    src={src}
    className={className}
    loading={loading}
    width={width}
    height={height}
    alt={alt}
    draggable={isDraggable}
  />
);

export { Image };
