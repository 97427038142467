import * as React from 'react';
import { AboutTabItem } from '~/common/enums/enums';
import {
  FooterPayload,
  HeaderPayload,
  NavigationPayload,
  SeoPayload,
} from '~/common/types/types';
import { Navigation } from '~/components/sections/sections';
import { Layout } from '~/components/common/common';
import * as styles from './about-layout.module.scss';

type Props = {
  seoPayload: SeoPayload;
  headerPayload: HeaderPayload;
  footerPayload: FooterPayload;
  navigationPayload: NavigationPayload;
  activeTabItem: AboutTabItem;
  registrationLink: string | null;
};

const AboutLayout: React.FC<Props> = ({
  children,
  seoPayload,
  headerPayload,
  footerPayload,
  navigationPayload,
  registrationLink,
  activeTabItem,
}) => (
  <Layout
    seoPayload={seoPayload}
    headerPayload={headerPayload}
    footerPayload={footerPayload}
    registrationLink={registrationLink}
  >
    <Navigation payload={navigationPayload} activeItem={activeTabItem} />
    <div className={styles.contentWrapper}>{children}</div>
  </Layout>
);

export { AboutLayout };
