import * as React from 'react';
import {
  TimelineItem as TTimelineItem,
  AppLocalizationKey,
} from '~/common/types/types';
import { TimelineItem } from '../components';
import * as styles from './timeline-list.module.scss';
import { getValidClasses } from '~/helpers/helpers';

type Props = {
  items: TTimelineItem[];
  badgeText: AppLocalizationKey;
  hasActiveTimelineItem: boolean;
};

const TimelineList: React.FC<Props> = ({
  items,
  badgeText,
  hasActiveTimelineItem,
}) => {
  return (
    <ul
      className={getValidClasses(
        styles.list,
        hasActiveTimelineItem && styles.spacing,
      )}
    >
      {items.map((it) => {
        return <TimelineItem item={it} badgeText={badgeText} key={it.title} />;
      })}
    </ul>
  );
};

export { TimelineList };
