import * as React from 'react';
import { HeaderPayload, FooterPayload, SeoPayload } from '~/common/types/types';
import { useFacebookChat, useAppTranslation } from '~/hooks/hooks';
import { Footer, Header, Seo } from '~/components/common/common';

type Props = {
  seoPayload: SeoPayload;
  headerPayload: HeaderPayload;
  footerPayload: FooterPayload;
  registrationLink: string | null;
};

const Layout: React.FC<Props> = ({
  children,
  seoPayload,
  footerPayload,
  headerPayload,
  registrationLink,
}) => {
  const { currentLanguage } = useAppTranslation();
  const { handleRender: onFacebookChatRender } = useFacebookChat();

  return (
    <>
      <Seo payload={seoPayload} />
      <Header
        payload={headerPayload}
        currentLanguage={currentLanguage}
        registrationLink={registrationLink}
      />
      <main>{children}</main>
      <Footer payload={footerPayload} />
      <span>{onFacebookChatRender()}</span>
    </>
  );
};

export { Layout };
