import * as React from 'react';
import * as styles from './tag-list.module.scss';

type Props = {
  items: string[];
};

const TagList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <li className={styles.item} key={it}>
        {it}
      </li>
    ))}
  </ul>
);

export { TagList };
