import { AboutPayload } from '~/common/types/types';
import image0 from '~/assets/img/about-slider/0.jpg';
import image1 from '~/assets/img/about-slider/1.jpg';
import image2 from '~/assets/img/about-slider/2.jpg';
import image3 from '~/assets/img/about-slider/3.jpg';
import image4 from '~/assets/img/about-slider/4.jpg';
import image5 from '~/assets/img/about-slider/5.jpg';
import image6 from '~/assets/img/about-slider/6.jpg';

const IMAGES = [image0, image1, image2, image3, image4, image5, image6];

const aboutPayload: AboutPayload = {
  sections: [
    {
      title: 'about.index.group0.title',
      items: ['about.index.group0.paragraph0', 'about.index.group0.paragraph1'],
    },
    {
      title: 'about.index.group1.title',
      items: ['about.index.group1.paragraph0', 'about.index.group1.paragraph1'],
    },
    {
      title: 'about.index.group2.title',
      items: ['about.index.group2.paragraph0'],
    },
    {
      title: 'about.index.group3.title',
      items: [
        'about.index.group3.paragraph0',
        'about.index.group3.paragraph1',
        'about.index.group3.paragraph2',
        'about.index.group3.paragraph3',
        'about.index.group3.paragraph4',
      ],
    },
  ],
  images: IMAGES,
};

export { aboutPayload };
