import * as React from 'react';
import { AboutPayload } from '~/common/types/types';
import { AboutSection, AboutSlider } from './components/components';
import * as styles from './about.module.scss';

type Props = {
  payload: AboutPayload;
};

const About: React.FC<Props> = ({ payload }) => {
  const { sections, images } = payload;

  return (
    <div className={styles.contentWrapper}>
      {sections.map((section) => (
        <AboutSection section={section} key={section.title} />
      ))}
      <AboutSlider images={images} />
    </div>
  );
};

export { About };
