import { AppLocalizationMap } from '~/common/types/types';

const UAMessagesMap: AppLocalizationMap = {
  seo: {
    main: {
      title: 'Безкоштовні онлайн курси з програмування | Binary Studio Academy',
      description:
        'Стань впевненим full-stack розробником за 2,5 місяці та навчися створювати складні вебзастосунки на практиці.',
    },
    about: {
      title: 'Про нас | Binary Studio Academy',
      description:
        'Вчимо створювати складні додатки, трансформуємо початківців у впевнених розробників. Актуальний стек, досвідчені коучі, розробка реального проєкту.',
    },
    faq: {
      title: 'FAQ | Binary Studio Academy',
      description:
        'Шукай відповіді на часті запитання про безкоштовний онлайн-курс із програмування - Binary Studio Academy.',
    },
    bs: {
      title: 'Binary Studio | Binary Studio Academy',
      description:
        'Заснована в 2005 році, компанія Binary Studio проводить щорічний безкоштовний онлайн-інтенсив з JS та QA - Binary Studio Academy.',
    },
    codex: {
      title: 'Умови навчання | Binary Studio Academy',
      description:
        'Умови навчання в Binary Studio Academy - щорічному безкоштовному онлайн-інтенсиві з JS та QA, та яких правил варто дотримуватись протягом навчання.',
    },
    onlineEvent: {
      title: 'Homeference | Binary Studio Academy',
      description:
        'Homeference - це серія онлайн-трансляцій по веб-розробці від Binary Studio Academy, переходь та дивись.',
    },
    js: {
      title: 'Безкоштовний онлайн-курс із JavaScript | Binary Studio Academy',
      description:
        'Навчись створювати сучасні веб-додатки, опануй JS, React, Node.js та стань full-stack розробником за 2,5 місяці.',
    },
    qa: {
      title: 'Безкоштовний онлайн-курс із QA | Binary Studio Academy',
      description:
        'Опануй інструменти мануального та автоматизованого тестування, отримай досвід командної роботи над реальним проєктом.',
    },
  },
  registration: {
    end: 'Відкриття реєстрації скоро!',
    button: 'Зареєструватися',
  },
  header: {
    about: 'Про нас',
    online: 'Онлайн-події',
    blog: 'Блог',
    login: 'Вхід',
  },
  info: {
    title: 'Як працює Академія',
    group0: {
      title: 'Інтенсивний темп – повна підтримка',
      description:
        '2,5 місяці повноцінної роботи над реальним проєктом та зворотний звʼязок від менторів Академії',
    },
    group1: {
      title: 'Практика-теорія-практика',
      description:
        'Навчання на реальних проєктах - дорожні карти, щоденні зустрічі, перегляд коду та методологія SCRUM є невід’ємною частиною',
    },
    group2: {
      title: 'Взаємодія з Binary Studio',
      description: '65% команди Binary Studio залучені в Академію',
    },
  },
  statistics: {
    item0: 'років',
    item1: 'заявок',
    item2: 'студентів',
    item3: 'людей доєдналися до Binary Studio',
    item4: 'країн',
    item5: 'знаходять роботу протягом перших 2 місяців',
  },
  benefits: {
    title: 'Що на тебе чекає?',
    group0: {
      title: 'Практичний досвід',
      description:
        '2.5 місяці інтенсивного навчання та практичного досвіду на реальному проєкті. Кращі випускники отримають пропозицію роботи у Binary Studio, адже вже будуть готові працювати на комерційному проєкті.',
    },
    group1: {
      title: 'Портфоліо',
      description:
        'Отримай перший реальний проєкт для свого портфоліо. Студенти працюють з актуальними технологіями комерційної розробки та які оцінять потенційні роботодавці.',
    },
    group2: {
      title: 'Спільнота',
      description:
        'Ти в оточенні найкращих – командна робота та обмін досвідом зі студентами та коучами. Випускники Академії – це понад 600 спеціалістів з усього світу, які є частиною великого комʼюніті.',
    },
  },
  timeline: {
    badge: 'Триває',
    section0: {
      title: 'Як потрапити в Академію',
      subtitle: '',
      descriptor: 'Відбіркові етапи',
      group0: {
        title: 'Реєстрація',
        description: 'Зареєструйся на Binary Studio Academy 2024.',
      },
      group1: {
        title: '1-й відбірковий етап',
        description: 'Склади онлайн тест з основ програмування та Англійської.',
        dateSeparator: 'і',
      },
      group2: {
        title: '2-й відбірковий етап',
        description: 'Виконай домашні завдання до 3-х відеолекцій.',
      },
    },
    section1: {
      title: 'Таймлайн Binary Studio Academy',
      subtitle: 'Вітаємо, на цьому етапі ти станеш студентом BSA!',
      descriptor: 'Етапи навчання',
      group0: {
        title: 'Лекції',
        description:
          'Вивчи нові технології за допомогою 10+ онлайн-лекцій та домашніх завдань.',
        dateSeparator: 'і',
      },
      group1: {
        title: 'Інтерв’ю',
        description:
          'Познайомся з командою Binary Studio Academy на 15-хвилинному дзвінку.',
      },
      group2: {
        title: 'Проєкт',
        description:
          'Разом з командою та коучами побудуй веб додаток рівня MVP з нуля.',
      },
      group3: {
        title: 'Демо',
        description:
          'Презентуй проєкт на онлайн-демо, отримай сертифікат і шанс приєднатися до Binary Studio.',
      },
    },
  },
  review: {
    title: 'Що цінують випускники',
    group0: {
      commentary:
        'Binary Studio Academy – чудове місце для розвитку та вдосконалення навичок. За допомогою потужної лекційної бази та практичних завдань, які майже ідентичні реальним викликам у сфері роботи, я отримала необхідні знання для успішного початку кар’єри. Проєктний етап дав можливість виявити мої як слабкі, так і сильні сторони у співпраці з командою, а також ознайомитися з повним циклом розробки повноцінного проєкту.</br></br> Озираючись назад, сміливо можна сказати, що навчання в Академії Binary Studio було не просто складним і захоплюючим періодом, але й відіграло вирішальну роль у формуванні мого професійного шляху.',
      authorName: 'Чічік Анастасія',
      authorStatus: 'випускник 2023',
      authorExJob: 'колишній студент',
    },
    group1: {
      commentary:
        'Binary Studio Academy змінила мій шлях становлення як кваліфікованого розробника. На відміну від інших курсів, де надаються лише технічні знання, Binary Studio Academy також надає практичний досвід роботи з реальним проєктом і командою. Проєктна робота зі щоденними командними дзвінками та обговореннями дала нам відчуття, що ми перебуваємо у справжньому робочому середовищі.</br></br>Робота над різними складними завданнями, такими як інтеграція з третіми сторонами та розробка MVP всього за 6 тижнів, була одним з ключових моментів, що навчила нас труднощам реальних проєктів. Незважаючи на відсутність попереднього досвіду роботи, спільні огляди коду та сприятливе середовище значно прискорили мій технічний розвиток.',
      authorName: 'Фарід Шабанов',
      authorStatus: 'випускник 2022',
      authorExJob: 'колишній студент',
    },
    group2: {
      commentary:
        'Академія - це магічне поєднання розвитку, людей, стосунків, знань та досягнень.</br>Кожен новий виклик дає можливість навчитися чомусь новому, поспілкуватися з однодумцями.</br>Для мене Академія відкрила двері до тих аспектів життєвого циклу розвитку, які я раніше не розумів і навіть не здогадувався про їхнє існування. Цей досвід дуже важливий. Він корисний, надихає, дає сили рухатися вперед і підкорювати нові цілі.</br></br>Хочу поділитися кількома рекомендаціями, які зроблять подорож більш захоплюючою для наступних студентів: будьте допитливими, жадайте знань, робіть все по максимуму для себе, допомагайте іншим та спілкуйтеся.',
      authorName: 'Володимир Пальчин',
      authorStatus: 'випускник 2023',
      authorExJob: 'колишній категорійний менеджер',
    },
    group3: {
      commentary:
        'Проходження навчання в Академії Binary Studio було дивовижним досвідом, який дійсно ставив перед мною виклики та мотивував мене. Лекції дали мені як теоретичні, так і практичні навички, завдяки чому я змогла дізнатися про різні аспекти розробки програмного забезпечення. Унікальність Академії полягала в тому, що ми працювали над реальними проєктами в команді. Це допомогло мені не лише покращити свої технічні навички, але й зрозуміти важливість спільної роботи, відповідальності та прагнення до однієї мети. Озираючись назад, я можу сказати, що Академія відіграла значну роль у початку моєї карʼєри в IT. Вона дала мені навички та впевненість, необхідні для досягнення успіху в цій швидкозмінній сфері.',
      authorName: 'Вероніка Сірак',
      authorStatus: 'випускниця 2023',
      authorExJob: 'колишній викладач статистики',
    },
    group4: {
      commentary:
        'Академія стала особливим досвідом.</br>Всі були дуже відкриті і готові допомогти подолати будь-яку перешкоду. Наявність людей різних рівнів в одній команді також дозволила мені подивитися на когось більш досвідченого, що сприяло моєму зростанню.</br>Академія також дала цінне розуміння того, як працює процес розробки у великих компаніях і як виглядає комунікація в команді розробників.',
      authorName: 'Владислав Добичин',
      authorStatus: 'випускник 2023',
      authorExJob: 'колишній викладач англійської мови',
    },
  },
  history: {
    title: 'Історія Академії',
    group0: {
      year: 2011,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Випускників',
      title: 'Перша Академія',
      historyText:
        '2011 - рік заснування Binary Studio Academy. Компанія, знаходячись у Донецьку, проводить першу в історії міста академію для студентів за напрямками .NET тa PHP. Заняття проходять в офісі компанії та включають теоретичні лекції з домашніми завданнями.',
    },
    group1: {
      year: 2012,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Випускників',
      title: 'Нова програма',
      historyText:
        'Академія набирає обертів, програма відточується і починає включати розробку повноцінного додатку з нуля в командах.',
    },
    group2: {
      year: 2013,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Випускників',
      title: 'Нові напрямки',
      historyText:
        'Академія здобуває популярності у Донецьку. Ми розширюємо напрямки С++ групою та випускаємо майже 30 IT спеціалістів.',
    },
    group3: {
      year: 2014,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Випускників',
      title: 'Переходимо до онлайн формату',
      historyText:
        '2014 стає поворотним моментом в історії Академії та компанії в цілому, бо у розпалі відбіркового етапу Академії компанія змушена раптово переїхати із Донецька до Західної України. Маючи вже набрані групи, ми вперше проводимо Академію онлайн.',
    },
    group4: {
      year: 2015,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Випускників',
      title: 'Визнання та ріст',
      historyText:
        'Академія здобуває нагороду на IT Education Awards. Ми включаємо новий напрямок QA, збільшуємо конкурс на вступ та проводимо ще якіснішу онлайн Академію. DEMO та вечірку випускників проводимо в офісі у Львові, куди з`їжджаються студенти з усієї України.',
    },
    group5: {
      year: 2016,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Випускників',
      title: 'Набираємо обертів',
      historyText:
        'Binary Studio Academy вже третій рік поспіль є безкоштовним освітнім онлайн проєктом в Україні. Ми постійно підвищуємо фахові стандарти навчання. За шість років частка випускників Академіі серед співробітників компаніі Binary Studio сягає 40%.',
    },
    group6: {
      year: 2017,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Випускників',
      title: 'Співпрацюємо з найкращими університетами',
      historyText:
        'Академія у партнерстві з 5 університетами країни залучає рекордну кількість аплікантів, оновлює програму курсів з JS, JAVA та .NET і проводиться у складі шістьох груп. Зі студентів, що випустилися у вересні, 8 вже працює у компанії над внутрішніми та комерційними проєктами.',
    },
    group7: {
      year: 2018,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Студентів',
      title: 'Покращуємо досвід навчання',
      historyText:
        'Щоб студенти отримали максимум від Академії, ми вдосконалюємо систему управління навчанням (LMS) та оновлюємо мобільний додаток.',
    },
    group8: {
      year: 2019,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Студентів',
      title: 'Вводимо новий етап підготовки',
      historyText:
        'Аби студенти отримали максимальну користь вже на етапі відбору, ми запроваджуємо міні-проєкт. Він знайомить з архітектурою складних додатків та показує як все працює з середини.',
    },
    group9: {
      year: 2020,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Студентів',
      title: 'Вводимо новий навчальний трек',
      historyText:
        'Ми додаємо Java трек, який одразу став другим за популярністю серед аплікантів. Цього року ми доводимо, що для навчання в Академії немає вікових обмежень: на рівні з усіма навчається найстарший (50 років) студент, а наймолодший (11 років!) презентує проєкт своєї команди на open-air Demo day. Частка випускників Академії в команді Binary Studio сягає 82%.',
    },
    group10: {
      year: 2021,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Студентів',
      title: 'Запуск першої Winter Academy',
      historyText:
        'Цього року проводимо першу закриту Winter Academy у форматі JS інтенсиву. Понад 600 студентів попередніх літніх Академій вирішили спробувати свої сили вдруге! Вводимо уніфікований підхід щодо оцінки коду всіх студентів. Святкуємо 10 років Академії новим рекордом за кількістю аплікантів!',
    },
    group11: {
      year: 2022,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Студентів',
      title: '14 нових країн і рекордна кількість іноземних студентів!',
      historyText:
        'У 2022 Binary Studio Academy сміливо можна назвати міжнародною Академією. Цього літа ми залучили студентів із 16 країн світу. Відтепер Академія пропонує 3 треки англійською мовою для того, щоб кожен студент міг скористатися найкращими навчальними можливостями в BSA. Лекції, тести, домашні завдання, продуктова документація, щотижневі демо тепер також англійською мовою, щоб студенти адаптувались до реальних умов комерційної розробки вже під час навчання. Також ми почали надавати ще більше матеріалів для самостійної підготовки на етапі реєстрації, щоб кожен охочий міг підготуватися до вступних іспитів.',
    },
    group12: {
      year: 2023,
      applicantsTitle: 'Заявок',
      studentsTitle: 'Студентів',
      title: 'Розширили нашу діяльність на Латинську Америку та Азію',
      historyText:
        '2023 рік - був роком зростання та масштабування! Ми були раді отримати понад 15 000 заявок від ентузіастів-початківців в ІТ з понад 30 країн світу. Було дуже приємно бачити, як розширюється наша міжнародна команда, і ми пишаємося тим, що до нас приєдналися найкращі таланти з Латинської Америки та Азії, які успішно закінчили',
    },
  },
  past: {
    title: 'Дивись як проходить демо презентація студентських проєктів',
  },
  questions: {
    title: 'Запитання і відповіді',
    group0: {
      question: 'Як потрапити до Академії?',
      answer:
        'Академія проходить 1 раз на рік. Щоб потрапити до Академії, спочатку зареєструйся. Після реєстрації потрібно пройти відбір: успішно скласти онлайн-тест, виконати домашні завдання і поспілкуватися з представником команди Академії.',
    },
    group1: {
      question: 'Чи отримаю я працевлаштування після закінчення Академії?',
      answer:
        'Кращим студентам ми пропонуємо приєднатися до команди Binary Studio ремоут. Крім того, усі випускники Академії мають достатньо знань і вмінь, щоб успішно пройти співбесіду в будь-яку ІТ компанію.',
    },
    group2: {
      question: 'Скільки коштує навчання в Академії?',
      answer:
        'Навчання у Binary Studio Academy абсолютно безкоштовне. Ти нічого не платиш ні під час навчання, ні після працевлаштування.',
    },
    question: 'Залишились питання?',
  },
  landing: {
    title: 'Binary Studio Academy',
    subtitleBottom: 'Онлайн-інтенсив з розробки веб-додатків',
    description:
      'За 2,5 місяці курсу ми трансформуємо програмістів-початківців у інженерів, здатних створювати додатки світового рівня.',
    item0: 'Безкоштовно',
    item1: 'Практично',
    item2: 'Онлайн',
  },
  directions: {
    title: 'Обери свій напрямок',
  },
  join: {
    title: 'Готовий приєднатися?',
    description: 'Чекаємо на тебе в Binary Studio Academy 2024!',
    postscript:
      'А поки чекаєш на старт відбору, радимо підписатися на наш <a href="https://t.me/bsacademy">Телеграм-канал</a>, щоб не пропускати анонси наших івентів, корисні матеріали та цікаве чтиво.',
  },
  facebook: {
    chat: {
      login: 'Привіт! Маєш питання? Пиши їх команді Академії тут!',
      logout: 'Привіт! Маєш питання? Пиши їх команді Академії тут!',
    },
  },
  events: {
    past: 'Минулі події',
    watch: 'Дивитись запис',
  },
  bsaDescription: {
    title: 'Binary Studio Academy',
    description: 'Безкоштовний онлайн-інтенсив з програмування на JS та QA.',
    timelineTitle: 'Як виглядає курс',
    timelineItem0: {
      period: 'Липень – Серпень',
      title: 'Лекції, напрацювання теоретичної бази',
      description:
        '~ 15 відеолекцій від досвідчених розробників Binary Studio з домашніми завданнями.',
    },
    timelineItem1: {
      period: 'Серпень – Вересень',
      title: 'Розробка проєкту, робота в команді',
      description:
        'Академісти працюють в командах над великими state-of-the-art веб рішеннями.',
    },
    benefitTitle: 'Що дає Академія',
    benefitItem0: 'Впевнене володіння сучасними фреймворками та інструментами',
    benefitItem1:
      'Вміння писати чистий код, використовуючи industry’s best practices',
    benefitItem2:
      'Досвід розробки на фронтеді та бекенді (для усіх напрямків програмування)',
    benefitItem3: 'Навички роботи в команді над реальним проєктом',
    benefitItem4: 'Фул-стек проєкт у портфоліо',
    benefitItem5:
      'Можливість отримати запрошення приєднатись до команди Binary Studio',
  },
  technology: {
    js: {
      landing: {
        title:
          'Поглиблений онлайн курс з програмування додатків на <strong>JavaScript</strong>',
        subtitleTop: 'Binary Studio Academy',
        description:
          'Команда топових програмістів Binary Studio познайомить із сучасними JavaScript технологіями та навчить створювати state-of-art додатки',
        item0: '2.5 місяці',
        item1: 'Full-stack досвід',
        item2: 'Безкоштовно',
        item3: 'Онлайн',
      },
      info: {
        title: 'Що ти отримаєш',
        group0: {
          title: 'Прискорений курс навчання:',
          description:
            '2,5-місячна інтенсивна програма під керівництвом досвідчених менторів та провідних джуніор-розробників.',
        },
        group1: {
          title: 'Full-stack розробка:',
          description:
            'Практичний досвід full-stack розробки з використанням новітніх технологій JS, React та Node.js.',
        },
        group2: {
          title: 'Наставництво ментора:',
          description:
            'Регулярні перевірки коду та відгуки від досвідчених JS розробників.',
        },
        group3: {
          title: 'Різноманітні технічні завдання:',
          description:
            'Працюємо над завданнями від Docker та AWS до React Native для розробки мобільних додатків.',
        },
        group4: {
          title: 'Середовище для співпраці:',
          description:
            'Командні проєкти з найкращими студентами, з використанням методологій SCRUM та Agile.',
        },
        group5: {
          title: 'Можливість працевлаштування:',
          description:
            'Найкращі випускники можуть отримати пропозицію про віддалену роботу в Binary Studio.',
        },
        group6: {
          title: 'Сертифікат:',
          description:
            'Отримай сертифікат про закінчення Binary Studio Academy.',
        },
      },
      techtime: {
        title: 'З чого складається JavaScript курс',
        group0: {
          title: 'Лекції',
          period: 'Червень – Серпень',
          description:
            'Заповнюємо прогалини і надаємо необхідну базу для подальшої роботи над проєктом.',
          activity:
            'В онлайн-кабінеті тобі будуть доступні  ~10-15 відеолекцій з домашніми завданнями і дедлайнами. Досвідчені розробники, перевіряючи завдання, даватимуть фідбеки та рекомендації.',
        },
        group1: {
          title: 'Проєкт',
          period: 'Серпень – Вересень',
          description: 'Навчаємо створювати додатки світового рівня.',
          activity:
            'Студенти у командах під менторством коучів створюють проєкт від ідеї до функціональної демо-версії. Коучі допомагають на всіх етапах розробки, проводять щоденні зідзвони з командою, ретроспективи і рев’ю.',
        },
      },
      examples: {
        title: 'Приклади минулих проєктів',
        group0: {
          title: 'НАВЧАЛЬНА ПЛАТФОРМА ДЛЯ ЛЮБИТЕЛІВ ПРОГРАМУВАННЯ',
          description:
            'Онлайн-освітня платформа для розробників програмного забезпечення, де користувачі можуть ділитись своїми знаннями, вирішуючи різні завдання. Сервіс містить базу завдань різного рівня складності, типу, тематики, за допомогою яких люди підвищують свої навички. Користувачі самі можуть підготувати завдання для інших, створювати клани, боротись за почесні місця в спільноті тощо.',
        },
        group1: {
          title: 'СУЧАСНА ПЛАТФОРМА ДЛЯ ПОДКАСТІВ',
          description:
            'Платформа для запису, завантаження та зберігання подкастів. Сервіс дозволяє записувати подкаст в режимі реального часу, а люди можуть заходити і коментувати під час ефіру. Користувачі можуть створювати списки улюблених подкастів, залишати коментарі та лайки. Також є можливість обмежити доступ до подкастів. ',
        },
      },
      mentors: {
        title: 'Ментори',
        mentor0: 'Владислав Зубко',
        mentor1: 'Микита Ремеслов',
        mentor2: 'Антон Комісаров',
        mentor3: 'Віталій Каленіченко',
        mentor4: 'Фарід Шабанов',
        mentor5: 'Дмитро Попович',
      },
      join: {
        title: 'Хочеш прокачатися у JavaScript?',
      },
    },
    qa: {
      landing: {
        title: 'Поглиблений онлайн курс з <strong>QA</strong>',
        subtitleTop: 'Binary Studio Academy',
        description:
          'Досвідчені QA-інженери Binary Studio допоможуть опанувати ключові техніки тестування, зрозуміти роль тестування у процесі розробки, познайомлять з актуальними трендами',
        item0: '2.5 місяці',
        item1: 'Безкоштовно',
        item2: 'Онлайн',
      },
      info: {
        title: 'Що ти отримаєш',
        group0: {
          title: 'Практичні челенджі:',
          description:
            'Прокачаєш навички тестування за допомогою ефективних QA-методологій на реальному проєкті.',
        },
        group1: {
          title: 'Автоматизація тестування:',
          description:
            'Опануєш Postman для тестування API, практикуючи створення автоматизованих тестів.',
        },
        group2: {
          title: 'Професійна підтримка:',
          description:
            'Поради від досвідчених QA-інженерів поглиблять твої знання у ролі QA.',
        },
        group3: {
          title: 'Реальні умови:',
          description:
            'Роботу в SCRUM-команді, щоб отримати практичний досвід розробки складних додатків.',
        },
        group4: {
          title: 'Сертифікат про проходження:',
          description:
            'Отримаєш сертифікат від Binary Studio Academy - твій знак успіху в QA тестуванні.',
        },
        group5: {
          title: 'Оффер від Binary Studio:',
          description:
            'Найкращі випускники зможуть отримати віддалену роботу в Binary Studio.',
        },
      },
      techtime: {
        title: 'З чого складається QA курс',
        group0: {
          title: 'Лекції',
          period: 'Червень – Серпень',
          description:
            'Заповнюємо прогалини і надаємо необхідну базу для подальшої роботи над проєктом',
          activity:
            'В онлайн-кабінеті тобі будуть доступні  ~10-15 відеолекцій з домашніми завданнями і дедлайнами. Досвідчені QA, перевіряючи завдання, даватимуть фідбеки та рекомендації.',
        },
        group1: {
          title: 'Проєкт',
          period: 'Серпень – Вересень',
          description: 'Навчаємо створювати додатки світового рівня',
          activity:
            'Студенти у командах під менторством коучів створюють проєкт від ідеї до функціональної демо-версії. Коучі допомагають на всіх етапах розробки, проводять щоденні зідзвони з командою, ретроспективи і рев’ю.',
        },
      },
      examples: {
        title: 'Приклади минулих проєктів',
        group0: {
          title: 'СЕРВІС ДЛЯ АНАЛІЗУ ТРЕНДІВ ТА ІДЕЙ СТАРТАПІВ',
          description:
            'Платформа дозволяє людям публікувати власні проєкти для збору коштів та залучення спонсорів. Є кілька функцій, таких як рекомендації проєктів для спонсорів, статистика по найбільш популярних темах, аналітика трендів, інформація про схожі проєкти та їх поточний стан. Платформа групує ідеї за подібністю та відображає, які категорії проєктів є успішними впродовж останніх трьох місяців.',
        },
        group1: {
          title: 'ІНТЕРАКТИВНИЙ СЕРВІС ДЛЯ ПРЕЗЕНТАЦІЙ',
          description:
            'Рішення дозволяє доповідачам спілкуватися з його аудиторією в реальному часі: слухачі можуть додавати коментарі або картинки, брати участь у голосуваннях. Єдине що для цього потрібно, це встановити додаток Google App Script і розширення Google Chrome, щоб отримати доступ до повного функціоналу.',
        },
      },
      mentors: {
        title: 'Ментори',
        mentor0: 'Маргарита Ровна',
        mentor1: 'Володимир Ярош',
        mentor2: 'Станіслав Горкуша',
      },
      join: {
        title: 'Хочеш прокачатися у QA?',
      },
    },
  },
  about: {
    title: 'Познайомимось ближче?',
    description:
      'Тут ми розказуємо хто ми і з якою метою це робимо. Якщо залишились питання і ти хочеш дізнатись більше, то цей блок точно для тебе.',
    index: {
      title: 'Що ми робимо',
      group0: {
        title: 'Хто ми',
        paragraph0:
          'Binary Studio Academy - це щорічний літній безкоштовний онлайн інтенсив з JS та QA.',
        paragraph1:
          'Щовесни ми відкриваємо реєстрацію на новий навчальний сезон, відбираємо найсильніших аплікантів і за три насичені місяці випускаємо впевнених Junior девелоперів і QA.',
      },
      group1: {
        title: 'Що ми робимо',
        paragraph0:
          'Ми допомагаємо початківцям впорядкувати і поглибити вже набуті знання з програмування, заповнити прогалини в теорії, оволодіти сучасними фреймворками та інструментами. Студенти Академії отримують досвід командної роботи в умовах, максимально наближених до справжньої комерційної розробки, і фул-стек проєкт для свого портфоліо.',
        paragraph1:
          'Кращим з кращих ми пропонуємо приєднатися до Binary Studio.',
      },
      group2: {
        title: 'Наша місія',
        paragraph0:
          'Місія Binary Studio Academy - трансформувати талановитих і мотивованих студентів у здібних інженерів, здатних створювати софт світового рівня.',
      },
      group3: {
        title: 'Натхнення',
        paragraph0:
          'Академія виникла з ентузіазму розробників Binary Studio. Тому, незважаючи на постійну еволюцію, фундаментальні засади лишаються непохитними.',
        paragraph1:
          'Ми прагнемо формувати сильне і свідоме ком’юніті. Binary Studio Academy існує, щоб талановиті й мотивовані розробники-початківці могли здобути прикладні знання й досвід.',
        paragraph2:
          'Binary Studio Academy була, є і завжди буде безкоштовною. Це не комерційний продукт, а внутрішня ініціатива.',
        paragraph3:
          'Ментори і коучі Академії - це члени команди Binary Studio. З весни по осінь кожного року на волонтерських засадах ми присвячуємо Академії майже весь вільний час та вкладаємо у неї дуже багато енергії та ентузіазму. Нас драйвить те, що ми ділимося своїми знаннями, даємо можливість студентам здобути ґрунтовну практичну освіту і в результаті - класну роботу. Нас мотивує бути причетними до величезної спільної справи. Нас надихає прогрес кожного студента. Binary Studio Academy - це серце нашого ком’юніті. Приєднуйся - і переконайся сам.',
        paragraph4:
          'Цікавий факт: 82% команди Binary Studio - випускники Академії за попередні 10 років. Тож твої лектори, ментори і коучі знають напевне, як зробити твоє навчання в Академії дійсно ефективним.',
      },
    },
    faq: {
      title: 'Часті запитання',
      group0: {
        question: 'Як потрапити до Академії?',
        answer:
          'Зареєструватися на сайті Академії\nПідготуватися до вступного тестування. Посилання на корисні ресурси та іншу інформацію, що стане в нагоді під час підготовки, ти знайдеш у своєму особистому кабінеті на сайті Академії і <a href="https://t.me/bsacademy">Telegram-каналі</a>\nПройти відбір: скласти онлайн тест з основ програмування, виконати домашні завдання та поспілкуватися із представником команди Академії.',
      },
      group1: {
        question: 'Які вимоги для вступу до Академії?',
        answer:
          'Мати базові знання з JS\nВміти працювати з даними (наприклад, через SQL), розуміти принципи ООП та / або ФП\nЗнати англійську на рівні розуміння документації\nПриділяти навчанню від 8 до 12 годин на день з 6 липня по 12 вересня\nНе хвилюйся: після реєстрації ти отримаєш доступ до матеріалів для підготовки в особистому кабінеті.',
      },
      group2: {
        question:
          'Якщо я реєструвався минулого року, чи потрібно мені реєструватися знову?',
        answer:
          'Якщо ти вже створював профіль на сайті Binary Studio Academy, твої дані збережені в нашій системі. Ще раз реєструватися не потрібно. Просто зазнач в особистому кабінеті напрям навчання, який цікавить тебе цього року.',
      },
      group3: {
        question: 'Скільки коштує навчання в Академії?',
        answer:
          'Навчання у Binary Studio Academy абсолютно безкоштовне. Ти нічого не платиш ні під час навчання, ні після працевлаштування..',
      },
      group4: {
        question: 'Якою мовою відбувається навчання?',
        answer:
          'При налаштуванні профіля можна обрати мову: українську чи російську. Умови завдань, описи етапів, уся інформація в особистому кабінеті, а також вступне тестування будуть відображені зазначеною мовою. Проте лекції подаються без перекладу: частина з них записані українською, частина - російською.',
      },
      group5: {
        question: 'Чи є вікові обмеження для кандидатів?',
        answer:
          'Для навчання в Академії немає жодних обмежень за віком, місцем проживання чи здобутою освітою. Єдиний критерій - технічний рівень кандидата.',
      },
      group6: {
        question: 'Чи важлива англійська для вступу?',

        answer:
          '2019 року ми спростили умови вступу, вилучивши із тестування секцію з англійської мови. Для навчання в Академії достатньо розуміти англомовну документацію. Проте для подальшої роботи в ІТ англійська - це маст-хев.',
      },
      group7: {
        question: 'Яка структура вступного тесту за напрямками програмування?',
        answer:
          'Тест складається з 30 питань закритого типу. Тематичні блоки: ООП, бази даних, верстка, JavaScript.',
      },
      group8: {
        question: 'Яка структура вступного тесту за напрямком QA?',
        answer:
          'Тест складається з 30 питань закритого типу. Тематичні блоки: розуміння SDLC, знання верстки, базові знання JavaScript.',
      },
      group9: {
        question: 'Чому для тестування виділено два дні?',
        answer:
          'Ми проводимо дві сесії тестування, щоб кожен кандидат міг обрати зручний час. Тест можна скласти лише один раз, 25 або 29 квітня, з 10.00 до 00.00.',
      },
      group10: {
        question: 'Чи можу я скласти тест двічі?',
        answer:
          'Протягом навчального сезону вступне тестування можна пройти лише раз і тільки за одним напрямком. Дубльовані профілі буде видалено з системи. Якщо для успішного складання тесту не вистачило балів, ти можеш підготуватися протягом року і спробувати свої сили в наступному наборі.',
      },
      group11: {
        question: 'Чи можливо навчатися за кількома напрямами?',
        answer:
          'Для проходження тестування і подальшого навчання необхідно обрати один напрям і вказати його при реєстрації. Навчання в Академії надзвичайно інтенсивне, тож поєднувати кілька курсів фізично неможливо.',
      },
      group12: {
        question:
          'Чи можна змінити напрям навчання після реєстрації і як це зробити?',
        answer:
          'Так, після реєстрації і до складання вступного тесту можна змінити напрям навчання. Для цього достатньо написати на academy@binary-studio.com, і організатори допоможуть.',
      },
      group13: {
        question:
          'Чи можу я поєднувати навчання в Академії і full-time роботу?',
        answer:
          'Успішне навчання передбачає велике навантаження і займатиме багато часу. Поєднувати його із роботою буде складно, але можливо. За умови, що робота не перешкоджатиме своєчасному виконанню завдань і залученості до командної розробки проєкту.',
      },
      group14: {
        question: 'Скільки студентів буде зараховано?',
        answer:
          'На навчання ми зараховуємо топ 5% аплікантів за результатами відбору: вступний тест, три домашні завдання на засвоєння лекцій та Zoom-інтерв’ю з представником команди Академії.',
      },
      group15: {
        question: 'Якого розміру будуть групи?',
        answer:
          'Студентів, які успішно пройшли відбір, ми розподіляємо по групах відповідно до обраної технології. В одній групі не більше 10 студентів.',
      },
      group16: {
        question:
          'Який вигляд має командний проєкт, над якими працюють студенти?',
        answer:
          'Студенти розробляють без перебільшення state-of-the-art веб-додатки зі складним фронтендом, гарно структурованим бекендом і використанням декількох клауд сервісів. Хоча проєкти є некомерційними, умови роботи максимально наближені до реальних. проєктна команда складається з девелоперів і QA, які щодня проводять daily meeting в онлайні. Крім того, на кожному проєкті є Product Owner, який час від часу бере участь у мітингах команди і допомагає формулювати вимоги до продукту.',
      },
      group17: {
        question: 'Скільки часу мені потрібно буде приділяти навчанню?',
        answer:
          'Упродовж липня триватиме теоретична частина. Ти прослухаєш 15 лекцій і до кожної отримаєш домашнє завдання. Протягом 6-тижневого курсу в серпні-вересні ти працюватимеш у команді над створенням веб-додатку. Команда та коучі визначають зручний час для проведення щоденних онлайн-мітингів. Над своїми тасками ти працюватимеш за власним розкладом, але для ефективної роботи над проєктом потрібно не менше 8 годин на день.',
      },
      group18: {
        question: 'Чи отримаю я працевлаштування після закінчення Академії?',
        answer:
          'Кращим студентам ми пропонуємо приєднатися до команди Binary Studio ремоут. Крім того, усі випускники Академії мають достатньо знань і вмінь, щоб успішно пройти співбесіду в будь-яку ІТ компанію. Ми не займаємося працевлаштуванням, але охоче надамо рекомендації від наших технічних фахівців, що засвідчать твій рівень. А командний проєкт додасть очок. ;)',
      },
    },
    cob: {
      title: 'Кодекс поведінки',
      group0: {
        title: 'Кодекс поведінки',
        item0:
          'Я використовуватиму лише один профіль для реєстрації на тест і участі в Академії.',
        item1:
          'Тестові та домашні завдання - моя особиста та самостійна праця (за винятком командних завдань).',
        item2:
          'Я не поширюватиму готові рішення та відповіді в Академії та за її межами.',
        item3:
          'Я не буду використовувати штучні засоби покращення моїх результатів, або погіршення результатів інших студентів.',
      },
      group1: {
        title: 'Умови навчання',
        item0: 'Пройти навчання в Академії можна тільки один раз.',
        item1:
          'Зарахування відбувається за умови складання вступного тесту не нижче від прохідного рівня, вчасної здачі трьох тестових домашніх завдань та проходження співбесіди. Після кожного відбіркового етапу студент отримує листа з повідомленням про свій результат.',
        item2:
          'Всі лекційні матеріали, тестові та практичні завдання становлять інтелектуальну власність Binary Studio Academy, їх розповсюдження заборонено.',
        item3:
          'Навчання проводиться онлайн, тому час перегляду лекцій студент вибирає на свій розсуд, але ми об’єктивно оцінюємо практичні завдання за параметрами вчасності, якості та самостійного рішення. Також в Академії приділяється додаткова увага організації командної роботи, тому її навмисне порушення чи ігнорування Кодексу поведінки призводить до виключення з Академії.',
        item4:
          'За рішенням керівництва Академії студента може бути виключено з процесу навчання в будь-який момент без пояснення причини.',
        item5:
          'Студент, який виконає вимоги проєкту, отримає сертифікат про набуту кваліфікацію.',
      },
    },
    bs: {
      title: 'Binary Studio',
      group0: {
        title: 'Хто ми?',
        paragraph0:
          'Binary Studio ― українська ІТ компанія, заснована 2005 року з офісом у Львові. Ми створюємо world-class software для малого і середнього бізнесу. Наші продукти слугують мільйонам юзерів, а нашими рішеннями користуються гіганти з Fortune 500.',
      },
      group1: {
        title: 'Чим ми відрізняємось',
        paragraph0:
          'Ми будуємо ком’юніті однодумців усередині компанії та навколо неї. Маємо розвинену технічну культуру та залучаємо до неї інших, охоче ділимося знаннями. Проводимо закриті й відкриті лекції і мітапи, як для розробників рівня Senior та Architect, так і для новачків. Створюємо безліч можливостей і сприятливу атмосферу для розвитку. Ми цінуємо ініціативність, бажання навчатися і прагнення досконалості. Крім того, у компанії діє no-asshole правило.',
      },
    },
  },
  teamComposition: {
    title: 'Реальне робоче середовище',
    group0: {
      title: 'Cуперкоуч',
      description: 'Забезпечує реалізацію проєкту, працює над архітектурою',
    },
    group1: {
      title: 'Коуч',
      description:
        'Організовує команду, розподіляє завдання та проводить щоденні мітинги',
    },
    group2: {
      title: 'Розробник',
      description:
        'Приймає/отримує завдання, пише код, співпрацює з колегами по команді, отримує крутий досвід',
    },
    group3: {
      title: '',
      description: '',
    },
    group4: {
      title: 'Тестувальник',
      description:
        'Аналізує вимоги, тестує, співпрацює з розробниками, отримує чудовий досвід',
    },
    group5: {
      title: 'Власник продукту',
      description:
        'Переглядає продукт під час щотижневих демо, пропонує додаткові функції, над якими варто попрацювати',
    },
    group6: {
      title: 'Дизайнер',
      description: 'Надає проєкту професійного та довершеного вигляду',
    },
  },
  blogs: {
    titlePart1: 'Binary Studio',
    titlePart2: 'Блог Академії',
    readMoreText: 'Детальніше',
    blog0: {
      title: 'Від BSA до Binary Studio: шлях випускників',
      author: 'Аліна Купчик',
      content:
        'Привіт усім майбутнім студентам BSA, мене звати Аліна, я випускниця Літньої Академії 2022 року на треку JS. Поділюся з вами своєю історією як я потрапила в Binary',
    },
    blog1: {
      title: 'Як опанувати чистий код: принципи програмування',
      author: 'Фарід Шабанов',
      content:
        'Мій досвід та практичні поради та підказки, як писати масштабований, читабельний і організований',
    },
    blog2: {
      title: 'Від цирку до IT',
      author: 'Дмитро Кулаков',
      content:
        'Привіт! Мене звуть Дмитро, я працюю full-stack розробником у Binary Studio і хочу поділитися історією про те, як у 31 рік я змінив карʼєру акробата на програміста.',
    },
  },
  foundingTeam: {
    ceoName: 'Artem Goncharov',
    ceoPosition: 'Founder of Binary Studio Academy',
    paragraph0:
      'Artem Goncharov, the visionary founder of Binary Studio Academy, is transforming the tech education landscape in Eastern Europe. With a strong belief in practical learning and a commitment to fostering a new generation of engineering talent, Artem has created an intensive program that bridges the gap between theoretical knowledge and real-world application.',
    paragraph1:
      'Artem’s journey in the tech industry began with a Master’s degree in Computer Science from Donetsk National University. He later expanded his expertise with an MBA from the prestigious Haas School of Business at the University of California, Berkeley, where he honed his entrepreneurial skills and strategic thinking.',
    paragraph2:
      'Recognizing the gaps in traditional tech hiring practices, Artem founded Binary Studio Academy to cultivate talented students into skilled software engineers and tech leaders. The Academy’s intensive 3-month program transforms top-tier Computer Science and Engineering university students into job-ready software developers by simulating a real-world development environment and focusing on hands-on experience. It takes place twice a year and trains engineers and QA specialists.',
    paragraph3:
      'The Academy’s curriculum emphasizes both hard skills and soft skills. Students are expected to write high-quality code, demonstrate a solid understanding of their chosen technology stack, and showcase their ability to support and extend existing applications. The program also places a strong emphasis on communication skills, proactivity, ownership, and an entrepreneurial spirit, encouraging students to actively participate in stand-ups, present their work results logically and concisely, and take full responsibility for their tasks from start to finish.',
    paragraph4:
      'Under Artem’s leadership, Binary Studio Academy has become increasingly popular, attracting thousands of applicants each year. In 2023, the Academy reached an impressive milestone of 15,000 applications, with only the most talented and dedicated students making it through the rigorous selection process.',
    paragraph5:
      'Artem Goncharov’s entrepreneurial spirit, technical expertise, and commitment to empowering the next generation of tech leaders have positioned him at the forefront of transforming tech education. Through Binary Studio Academy, he continues to inspire, nurture, and develop talented individuals, equipping them with the skills and mindset needed to thrive in the ever-evolving world of technology.',
  },
};

export { UAMessagesMap };
