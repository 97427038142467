import * as React from 'react';
import { TimelineSectionItem, AppLocalizationKey } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import { TimelineList } from '../components';
import {
  checkIsDateToday,
  checkIsDatesInRangeByNow,
  getValidClasses,
} from '~/helpers/helpers';
import { useAppTranslation } from '~/hooks/hooks';
import * as styles from './sections-item.module.scss';

type Props = {
  section: TimelineSectionItem;
  badgeText: AppLocalizationKey;
};

const SectionsItem: React.FC<Props> = ({ section, badgeText }) => {
  const { title, subtitle, items, sectionHighlight, descriptor } = section;
  const { handleTranslate } = useAppTranslation();

  const hasSubtitle = Boolean(handleTranslate(subtitle));

  const hasActiveTimelineItem = items.some(({ dateStart, dateEnd }) => {
    return dateEnd
      ? checkIsDatesInRangeByNow(dateStart, dateEnd)
      : checkIsDateToday(dateStart);
  });

  return (
    <li
      className={getValidClasses(
        styles.item,
        sectionHighlight && styles[sectionHighlight],
      )}
    >
      <h3
        className={getValidClasses(
          styles.title,
          hasSubtitle && styles.hasSubtitle,
        )}
      >
        <FormattedMessage message={title} />
      </h3>
      {hasSubtitle && (
        <p className={styles.subtitle}>
          <FormattedMessage message={subtitle} />
        </p>
      )}

      <div
        className={getValidClasses(
          styles.listWrapper,
          hasActiveTimelineItem && styles.spacing,
        )}
      >
        <FormattedMessage
          className={getValidClasses(
            styles.descriptor,
            styles.descriptorDecoration,
            styles[`${descriptor.position}Decoration`],
          )}
          message={descriptor.text}
        />
        <TimelineList
          items={items}
          badgeText={badgeText}
          hasActiveTimelineItem={hasActiveTimelineItem}
        />
      </div>
    </li>
  );
};

export { SectionsItem };
