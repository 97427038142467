import * as React from 'react';
import { IconName } from '~/common/enums/enums';
import { ReviewItem as TReviewItem } from '~/common/types/types';
import { FormattedMessage, Icon, Link } from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import { ReviewItemImage } from '../components';
import { useSlideData } from '~/hooks/hooks';
import { getSliderSide } from '../../helpers/helpers';
import * as styles from './review-item.module.scss';

type Props = {
  item: TReviewItem;
};

const ReviewItem: React.FC<Props> = ({ item }) => {
  const { isActive, isNext, isPrev } = useSlideData();

  const side = getSliderSide(isNext, isPrev);

  return (
    <div className={getValidClasses(styles.wrapper, side && styles[side])}>
      <blockquote
        className={getValidClasses(styles.item, isActive && styles.itemActive)}
      >
        <div className={getValidClasses(styles.quoteWrapper)}>
          <Icon
            className={getValidClasses(
              styles.icon,
              isActive && styles.iconActive,
            )}
            iconName={IconName.QUOTE}
          />
          <q
            className={getValidClasses(
              styles.quote,
              !isActive && styles.hidden,
            )}
          >
            <FormattedMessage message={item.commentary} />
          </q>
        </div>
        <cite
          className={getValidClasses(
            styles.authorText,
            !isActive && styles.hidden,
          )}
        >
          <FormattedMessage
            className={styles.authorName}
            message={item.authorName}
          />
          <FormattedMessage
            className={styles.authorStatus}
            message={item.authorStatus}
          />
          <FormattedMessage
            className={styles.authorExJob}
            message={item.authorExJob}
          />
          <Link className={styles.authorLink} externalLink={item.authorLink}>
            <span>{item.authorLinkTitle}</span>
          </Link>
        </cite>
        <ReviewItemImage
          src={item.authorImage}
          decorationKey={item.decorationKey}
          isActive={isActive}
        />
      </blockquote>
    </div>
  );
};
export { ReviewItem };
