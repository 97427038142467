import { COBPayload } from '~/common/types/types';

const cobPayload: COBPayload = {
  sections: [
    {
      title: 'about.cob.group0.title',
      items: [
        'about.cob.group0.item0',
        'about.cob.group0.item1',
        'about.cob.group0.item2',
        'about.cob.group0.item3',
      ],
    },
    {
      title: 'about.cob.group1.title',
      items: [
        'about.cob.group1.item0',
        'about.cob.group1.item1',
        'about.cob.group1.item2',
        'about.cob.group1.item3',
        'about.cob.group1.item4',
        'about.cob.group1.item5',
      ],
    },
  ],
};

export { cobPayload };
