import { useEffect, useState } from '~/hooks/hooks';

type UseActiveBreakpoint = (breakpoints: number[]) => number;

const useActiveBreakpoint: UseActiveBreakpoint = (breakpoints) => {
  const [activeBreakpoint, setActiveBreakpoint] = useState<number>(0);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleWindowResize = (): void => {
      let match = 0;

      breakpoints.forEach((it) => {
        const matchingBreakpoint = window.matchMedia(
          `(min-width: ${it}px)`,
        ).matches;

        if (matchingBreakpoint) {
          match = it;
        }
      });

      setActiveBreakpoint(match);
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return (): void => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return activeBreakpoint;
};

export { useActiveBreakpoint };
