import * as React from 'react';
import { BSADescriptionTimelineItem } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './timeline-item.module.scss';

type Props = {
  item: BSADescriptionTimelineItem;
};

const TimelineItem: React.FC<Props> = ({ item }) => (
  <li>
    <h4 className={styles.period}>
      <FormattedMessage className={styles.periodText} message={item.period} />
    </h4>
    <b className={styles.title}>
      <FormattedMessage message={item.title} />
    </b>
    <p className={styles.description}>
      <FormattedMessage message={item.description} />
    </p>
  </li>
);

export { TimelineItem };
