// extracted by mini-css-extract-plugin
export var item = "timeline-item-module--item--eaxnG";
export var itemInRange = "timeline-item-module--item-in-range--Tf6At";
export var timelineBar = "timeline-item-module--timeline-bar--t-GXj";
export var itemExpired = "timeline-item-module--item-expired--RNaja";
export var icon = "timeline-item-module--icon--wvXfE";
export var details = "timeline-item-module--details--M9bKj";
export var badge = "timeline-item-module--badge--f19Qq";
export var datesWrapper = "timeline-item-module--dates-wrapper--Cknny";
export var title = "timeline-item-module--title---7H34";
export var description = "timeline-item-module--description--KcSDS";