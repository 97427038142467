import * as React from 'react';
import { TimelinePayload, SectionUIProps } from '~/common/types/types';
import { SectionLayout } from '~/components/common/common';
import { SectionsList } from './components/components';
import * as styles from './timeline.module.scss';

type Props = SectionUIProps & {
  payload: TimelinePayload;
};

const TIMELINE_CLASSES = {
  wrapper: styles.wrapper,
};

const Timeline: React.FC<Props> = ({ payload, sectionHighlight }) => {
  const { badgeText, sections } = payload;

  return (
    <SectionLayout
      classes={TIMELINE_CLASSES}
      sectionHighlight={sectionHighlight}
    >
      <h2 className="visually-hidden">Academy Timeline</h2>
      <SectionsList badgeText={badgeText} sections={sections} />
    </SectionLayout>
  );
};

export { Timeline };
