import * as React from 'react';
import { FoundingTeamPayload } from '~/common/types/types';
import * as styles from './founding-team.module.scss';
import { FormattedMessage, Image } from '~/components/common/common';

type Props = {
  payload: FoundingTeamPayload;
};

const FoundingTeam: React.FC<Props> = ({ payload }) => {
  const { imageSrc, ceoName, paragraphs, ceoPosition } = payload;

  return (
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        <Image
          className={styles.image}
          src={imageSrc}
          width="250"
          height="250"
          alt="Founder of Binary Studio Academy."
        />
        <figcaption className={styles.caption}>
          <FormattedMessage message={ceoName} />,
          <br />
          <FormattedMessage message={ceoPosition} />
        </figcaption>
      </figure>
      <div className={styles.paragraphs}>
        {paragraphs.map((paragraph) => (
          <p className={styles.paragraph} key={paragraph}>
            <FormattedMessage message={paragraph} />
          </p>
        ))}
      </div>
    </div>
  );
};

export { FoundingTeam };
