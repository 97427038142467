import { TRACK_OWNER_IDX } from '~/common/constants/constants';
import { AppLocalizationKey, MentorsPayload } from '~/common/types/types';
import margarytaRovna from '~/assets/img/mentors/margaryta-rovna.png';
import volodymyrYarosh from '~/assets/img/mentors/volodymyr-yarosh.png';
import stanislavHorkusha from '~/assets/img/mentors/stanislav-horkusha.png';

const MENTORS = [margarytaRovna, volodymyrYarosh, stanislavHorkusha];

const mentorsPayload: MentorsPayload = {
  title: 'technology.qa.mentors.title',
  items: MENTORS.map((it, idx) => ({
    name: `technology.qa.mentors.mentor${idx}` as AppLocalizationKey,
    imgPath: it,
    isTrackOwner: idx === TRACK_OWNER_IDX,
  })),
};

export { mentorsPayload };
