import * as React from 'react';
import { navigate } from 'gatsby';
import { useEffect } from '~/hooks/hooks';
import { AppRoute } from '~/common/enums/enums';
import { Seo } from '~/components/common/common';
import { seoPayload } from '~/components/main-page/data/data';

const ENRedirect: React.FC = () => {
  useEffect(() => {
    navigate(AppRoute.ROOT);
  }, []);

  return <Seo payload={seoPayload} />;
};

export default ENRedirect;
