import React from 'react';
import { TeamCompositionItem as TTeamCompositionItem } from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import { FormattedMessage, Image } from '~/components/common/common';
import * as styles from './team-composition-item.module.scss';

type Props = {
  item: TTeamCompositionItem;
};

const TeamCompositionItem: React.FC<Props> = ({ item }) => {
  return (
    <li className={getValidClasses(styles.item, styles[item.role])}>
      <Image
        src={item.image}
        alt={item.title}
        width={75}
        height={75}
        className={getValidClasses(styles.image, styles[`${item.role}Image`])}
      />
      <div>
        <h3 className={styles.title}>
          <FormattedMessage message={item.title} />
        </h3>
        <p className={styles.description}>
          <FormattedMessage message={item.description} />
        </p>
      </div>
    </li>
  );
};

export { TeamCompositionItem };
