import * as React from 'react';
import { TimelineSectionItem, AppLocalizationKey } from '~/common/types/types';
import { SectionsItem } from '../components';
import * as styles from './sections-list.module.scss';

type Props = {
  sections: TimelineSectionItem[];
  badgeText: AppLocalizationKey;
};

const SectionsList: React.FC<Props> = ({ sections, badgeText }) => (
  <ul className={styles.list}>
    {sections.map((it) => (
      <SectionsItem section={it} badgeText={badgeText} key={it.title} />
    ))}
  </ul>
);

export { SectionsList };
