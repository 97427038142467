import * as React from 'react';
import { ENV } from '~/common/enums/enums';
import { footerPayload, headerPayload } from '~/common/payload/payload';
import { Layout } from '~/components/common/common';
import {
  LandingImage,
  Techtime,
  Examples,
  Mentors,
  Info,
  Join,
} from '~/components/sections/sections';
import {
  landingPayload,
  techtimePayload,
  examplesPayload,
  mentorsPayload,
  infoPayload,
  joinPayload,
  seoPayload,
} from './data/data';

const Page: React.FC = () => (
  <Layout
    seoPayload={seoPayload}
    footerPayload={footerPayload}
    headerPayload={headerPayload}
    registrationLink={ENV.APP.REGISTRATION_LINK}
  >
    <h1 className="visually-hidden">JavaScript | Binary Studio Academy</h1>
    <LandingImage
      payload={landingPayload}
      registrationLink={ENV.APP.REGISTRATION_LINK}
      sectionHighlight="secondary"
    />
    <Info payload={infoPayload} registrationLink={ENV.APP.REGISTRATION_LINK} />
    <Techtime payload={techtimePayload} />
    <Examples payload={examplesPayload} />
    <Mentors payload={mentorsPayload} />
    <Join payload={joinPayload} registrationLink={ENV.APP.REGISTRATION_LINK} />
  </Layout>
);

export { Page };
