import * as React from 'react';
import { AppLocalizationKey } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './benefit-list.module.scss';

type Props = {
  items: AppLocalizationKey[];
};

const BenefitList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <li className={styles.item} key={it}>
        <FormattedMessage message={it} />
      </li>
    ))}
  </ul>
);

export { BenefitList };
