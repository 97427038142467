import * as React from 'react';
import { AppLanguage, AppRoute } from '~/common/enums/enums';
import { useRouter } from '~/hooks/hooks';
import { LanguageItem } from '../components';
import { getChangeLanguageUrl } from './helpers/helpers';
import * as styles from './language-list.module.scss';

type Props = {
  languages: AppLanguage[];
  currentLanguage: AppLanguage;
};

const LanguageList: React.FC<Props> = ({ languages, currentLanguage }) => {
  const { pathname } = useRouter();

  return (
    <div className={styles.navbarLang}>
      <div className={styles.navbarLangCurrent}>{currentLanguage}</div>
      <ul className={styles.navbarDropdown}>
        {languages.map((language) => {
          const url = getChangeLanguageUrl({
            language,
            pathname,
          });

          return (
            <LanguageItem
              isActive={language === currentLanguage}
              url={url as AppRoute}
              language={language}
              key={language}
            />
          );
        })}
      </ul>
    </div>
  );
};

export { LanguageList };
