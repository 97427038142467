import * as React from 'react';
import { BenefitsPayload, SectionUIProps } from '~/common/types/types';
import {
  FormattedMessage,
  Button,
  SectionLayout,
} from '~/components/common/common';
import { BenefitsList } from './components/components';
import * as styles from './benefits.module.scss';

const BENEFITS_CLASSES = {
  wrapper: styles.wrapper,
  title: styles.title,
};

type Props = SectionUIProps & {
  payload: BenefitsPayload;
  registrationLink: string | null;
};

const Benefits: React.FC<Props> = ({
  payload,
  registrationLink,
  sectionHighlight,
}) => {
  const { title, items, registrationBtn } = payload;
  const hasRegistrationLink = Boolean(registrationLink);

  return (
    <SectionLayout
      classes={BENEFITS_CLASSES}
      sectionHighlight={sectionHighlight}
      headingMessage={title}
    >
      <BenefitsList items={items} />
      {hasRegistrationLink && (
        <div className={styles.btnWrapper}>
          <Button
            gtm="sign_up"
            externalLink={registrationLink as string}
            withLanguageQuery
            hasShadow
          >
            <FormattedMessage message={registrationBtn} />
          </Button>
        </div>
      )}
    </SectionLayout>
  );
};

export { Benefits };
