import * as React from 'react';
import { LandingItem as TLandingItem } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './landing-item.module.scss';

type Props = {
  item: TLandingItem;
};

const LandingItem: React.FC<Props> = ({ item }) => {
  const hasIcon = Boolean(item.icon);

  return (
    <li
      className={getValidClasses(
        styles.item,
        hasIcon && styles.itemIcon,
        styles[item.icon],
      )}
    >
      <h3 className={styles.title}>
        <FormattedMessage message={item.title} />
      </h3>
    </li>
  );
};

export { LandingItem };
