import * as React from 'react';
import { EventsPayload, SectionUIProps } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import { EventsList } from './components/components';
import * as styles from './events.module.scss';

type Props = SectionUIProps & {
  payload: EventsPayload;
};

const Events: React.FC<Props> = ({ payload }) => {
  const { items, past, watch } = payload;

  return (
    <section className={styles.section}>
      <h2 className="visually-hidden">Events</h2>
      <p className={styles.subtitle}>
        <FormattedMessage message={past} />
      </p>
      <EventsList items={items} moreLinkText={watch} />
    </section>
  );
};

export { Events };
