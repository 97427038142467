import * as React from 'react';
import { FAQPayload } from '~/common/types/types';
import { FAQList } from './components/components';

type Props = {
  payload: FAQPayload;
};

const FAQ: React.FC<Props> = ({ payload }) => {
  const { items } = payload;

  return <FAQList items={items} />;
};

export { FAQ };
