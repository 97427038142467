import * as React from 'react';
import { AppLocalizationKey, BSAEvent } from '~/common/types/types';
import { EventsItem } from '../components';
import * as styles from './events-list.module.scss';

type Props = {
  items: BSAEvent[];
  moreLinkText: AppLocalizationKey;
};

const EventsList: React.FC<Props> = ({ items, moreLinkText }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <EventsItem item={it} moreLinkText={moreLinkText} key={it.title} />
    ))}
  </ul>
);

export { EventsList };
