import { LandingVideoPayload } from '~/common/types/types';

const landingPayload: LandingVideoPayload = {
  title: 'landing.title',
  subtitleTop: null,
  subtitleBottom: 'landing.subtitleBottom',
  description: 'landing.description',
  items: [
    {
      icon: 'coin',
      title: 'landing.item0',
    },
    {
      icon: 'code',
      title: 'landing.item1',
    },
    {
      icon: 'web',
      title: 'landing.item2',
    },
  ],
  registrationBtn: 'registration.button',
  registrationEnd: 'registration.end',
  generalVideoFramelink:
    'https://player.vimeo.com/video/327036457?autoplay=1&loop=1&muted=1&background=1',
  fullSizeVideoFrameLink:
    'https://onelineplayer.com/player.html?autoplay=true&loop=true&autopause=false&muted=false&url=https%3A%2F%2Fvimeo.com%2F331987189&poster=&time=true&progressBar=true&playButton=false&overlay=true&muteButton=true&fullscreenButton=true&style=light&logo=false&quality=720p',
};

export { landingPayload };
