import { useCallback, useState } from '~/hooks/hooks';
import { INITIAL_CORDS } from './common/constants';
import { Cords } from './common/types';

type UseCursorParallax = (initialCords?: Cords) => {
  coords: Cords;
  handleMove: (evt: React.MouseEvent) => void;
  handleScroll: (evt: React.UIEvent) => void;
};

const useCursorParallax: UseCursorParallax = (initialCords = INITIAL_CORDS) => {
  const [coords, setCords] = useState<Cords>(initialCords);

  const handleMove = useCallback((evt: React.MouseEvent) => {
    const { clientX: x, clientY: y } = evt;

    setCords({
      ...coords,
      x: x - window.innerWidth / 2,
      y: y - window.innerHeight / 2,
    });
  }, []);

  const handleScroll = useCallback((evt: React.UIEvent) => {
    setCords({
      ...coords,
      st: (evt.target as HTMLDivElement).scrollTop,
    });
  }, []);

  return {
    coords,
    handleMove,
    handleScroll,
  };
};

export { useCursorParallax };
