import * as React from 'react';
import { FooterSocialItem } from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import { Link } from '~/components/common/common';
import * as styles from './social-item.module.scss';

type Props = {
  item: FooterSocialItem;
};

const SocialItem: React.FC<Props> = ({ item }) => (
  <li>
    <Link
      className={getValidClasses(styles.link, styles[item.title])}
      externalLink={item.link}
    >
      <span className="visually-hidden">{item.title}</span>
    </Link>
  </li>
);

export { SocialItem };
