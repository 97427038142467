import * as React from 'react';
import { NavigationPayload } from '~/common/types/types';
import { FormattedMessage, Tabs } from '~/components/common/common';
import * as styles from './navigation.module.scss';

type Props = {
  payload: NavigationPayload;
  activeItem: string;
};

const Navigation: React.FC<Props> = ({ payload, activeItem }) => {
  const { title, description, tabLinks } = payload;

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContent}>
          <b className={styles.headerTitle}>
            <FormattedMessage message={title} />
          </b>
          <p className={styles.headerDescription}>
            <FormattedMessage message={description} />
          </p>
        </div>
        <Tabs tabLinks={tabLinks} activeItem={activeItem} />
      </div>
    </div>
  );
};

export { Navigation };
