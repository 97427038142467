import { useSwiperSlide } from 'swiper/react';

type UseSlideData = () => {
  isActive: boolean;
  isNext: boolean;
  isPrev: boolean;
};

const useSlideData: UseSlideData = () => {
  const { isActive, isNext, isPrev } = useSwiperSlide();

  return {
    isActive,
    isNext,
    isPrev,
  };
};

export { useSlideData };
