import * as React from 'react';
import { TechtimePayload, SectionUIProps } from '~/common/types/types';
import { TagList, SectionLayout } from '~/components/common/common';
import { TechtimeList } from './components/components';

type Props = SectionUIProps & {
  payload: TechtimePayload;
};

const Techtime: React.FC<Props> = ({ payload }) => {
  const { title, items, technologies } = payload;

  return (
    <SectionLayout headingMessage={title} sectionIcon="glasses">
      <TechtimeList items={items} />
      <TagList items={technologies} />
    </SectionLayout>
  );
};

export { Techtime };
