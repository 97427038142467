import * as React from 'react';
import { StatisticsItem as TStatisticsItem } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './statistics-item.module.scss';

type Props = {
  item: TStatisticsItem;
};

const StatisticsItem: React.FC<Props> = ({ item }) => (
  <li className={styles.item}>
    <span className={styles.amount}>{item.amount}</span>
    <FormattedMessage
      className={styles.description}
      message={item.description}
    />
  </li>
);

export { StatisticsItem };
