enum AppRoute {
  ROOT = '/',
  TECHNOLOGY = '/technology',
  ABOUT = '/about',
  ABOUT_FAQ = '/about/faq',
  ABOUT_COB = '/about/code-of-behavior',
  ABOUT_BS = '/about/binary-studio',
  ONLINE_EVENTS = '/online-events',
  FOUNDING_TEAM = '/founding-team',
}

export { AppRoute };
