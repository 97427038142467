import { AppLocalizationKey, BenefitsPayload } from '~/common/types/types';
import practicalImg from '~/assets/img/benefits/practical.jpg';
import portfolioImg from '~/assets/img/benefits/portfolio.jpg';
import communityImg from '~/assets/img/benefits/community.jpg';

const BENEFITS = [practicalImg, portfolioImg, communityImg];

const benefitsPayload: BenefitsPayload = {
  title: 'benefits.title',
  items: BENEFITS.map((it, idx) => ({
    imgPath: it,
    title: `benefits.group${idx}.title` as AppLocalizationKey,
    description: `benefits.group${idx}.description` as AppLocalizationKey,
  })),
  registrationBtn: 'registration.button',
};

export { benefitsPayload };
