import * as React from 'react';
import { LandingImagePayload, SectionUIProps } from '~/common/types/types';
import { Image } from '~/components/common/common';
import { Landing } from '~/components/sections/sections';
import * as styles from './landing-image.module.scss';

const LANDING_CLASSES = {
  title: styles.title,
  subtitleTop: styles.subtitleTop,
};

type Props = SectionUIProps & {
  payload: LandingImagePayload;
  registrationLink: string | null;
};

const LandingImage: React.FC<Props> = ({
  payload,
  registrationLink,
  sectionHighlight,
}) => {
  const { imgSrc, ...sectionPayload } = payload;

  return (
    <Landing
      classes={LANDING_CLASSES}
      payload={sectionPayload}
      registrationLink={registrationLink}
      sectionHighlight={sectionHighlight}
    >
      <Image className={styles.img} src={imgSrc} alt="landing" />
    </Landing>
  );
};

export { LandingImage };
