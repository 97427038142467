import * as React from 'react';
import {
  LandingPayload,
  SectionUIProps,
  AppLocalizationKey,
} from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import {
  Button,
  FormattedMessage,
  SectionLayout,
} from '~/components/common/common';
import { LandingList } from './components/components';
import * as styles from './landing.module.scss';

type Props = SectionUIProps & {
  payload: LandingPayload;
  classes?: {
    title?: string;
    subtitleTop?: string;
  };
  onMove?: (evt: React.MouseEvent) => void;
  onScroll?: (evt: React.UIEvent) => void;
  registrationLink: string | null;
};

const Landing: React.FC<Props> = ({
  classes,
  payload,
  onMove,
  onScroll,
  registrationLink,
  sectionHighlight,
  children,
}) => {
  const {
    title,
    subtitleTop,
    subtitleBottom,
    description,
    items,
    registrationBtn,
    registrationEnd,
  } = payload;

  const hasRegistrationLink = Boolean(registrationLink);
  const hasSubtitleTop = Boolean(subtitleTop);
  const hasSubtitleBottom = Boolean(subtitleBottom);

  return (
    <SectionLayout sectionHighlight={sectionHighlight}>
      <div
        className={styles.container}
        onMouseMove={onMove}
        onScrollCapture={onScroll}
      >
        <div className={styles.contentWrapper}>
          {hasSubtitleTop && (
            <p
              className={getValidClasses(styles.subtitle, classes?.subtitleTop)}
            >
              <FormattedMessage message={subtitleTop as AppLocalizationKey} />
            </p>
          )}
          <b className={getValidClasses(styles.title, classes?.title)}>
            <FormattedMessage message={title} />
          </b>
          {hasSubtitleBottom && (
            <p className={styles.subtitle}>
              <FormattedMessage
                message={subtitleBottom as AppLocalizationKey}
              />
            </p>
          )}
          <div className={styles.listWrapper}>
            {hasRegistrationLink ? (
              <Button
                gtm="sign_up"
                className={styles.btn}
                externalLink={registrationLink as string}
                withLanguageQuery
                hasShadow
              >
                <FormattedMessage message={registrationBtn} />
              </Button>
            ) : (
              <p className={styles.registration}>
                <FormattedMessage message={registrationEnd} />
              </p>
            )}
            <LandingList items={items} description={description} />
          </div>
        </div>
        <div className={styles.mediaWrapper}>{children}</div>
      </div>
    </SectionLayout>
  );
};

export { Landing };
