import * as React from 'react';
import MaskOverlayIcon from '~/assets/img/mask-overlay.inline.svg';
import { LandingVideoPayload, SectionUIProps } from '~/common/types/types';
import { useCursorParallax, useState } from '~/hooks/hooks';
import { Landing } from '~/components/sections/sections';
import * as styles from './landing-video.module.scss';

const InterRadius = {
  BG: 120,
  SCROLL: 12,
} as const;

type Props = SectionUIProps & {
  payload: LandingVideoPayload;
  registrationLink: string | null;
};

const LandingVideo: React.FC<Props> = ({
  payload,
  registrationLink,
  sectionHighlight,
}) => {
  const { generalVideoFramelink, fullSizeVideoFrameLink, ...sectionPayload } =
    payload;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { coords, handleMove, handleScroll } = useCursorParallax();

  const { st, x, y } = coords;

  const handleModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const interBg = `translate3d(${x / InterRadius.BG}%, ${
    y / InterRadius.BG
  }%, 0)`;
  const interScroll = `translate(0, ${st / InterRadius.SCROLL} )`;

  return (
    <Landing
      payload={sectionPayload}
      sectionHighlight={sectionHighlight}
      registrationLink={registrationLink}
      onMove={handleMove}
      onScroll={handleScroll}
    >
      <>
        <div className={styles.videoWrapper}>
          <div className={styles.video}>
            <div className={styles.videoInnerWrapper}>
              <iframe
                title="video"
                className={styles.videoFrame}
                src={generalVideoFramelink}
                width="640"
                height="640"
                allow="autoplay; fullscreen"
              />
              <div
                className={styles.maskOverlay}
                style={{ transform: interBg }}
              >
                <div style={{ transform: interScroll }}>
                  <MaskOverlayIcon />
                </div>
              </div>
            </div>
          </div>
          <button className={styles.playButton} onClick={handleModal}>
            <span className="visually-hidden">Check full video</span>
          </button>
        </div>
        {isModalOpen && (
          <div className={styles.modalWrapper}>
            <iframe
              title="full-video"
              allow="autoplay; fullscreen"
              scrolling="no"
              src={fullSizeVideoFrameLink}
              className={styles.modalVideoFrame}
            />

            <button className={styles.closeIcon} onClick={handleModal}>
              <span className="visually-hidden">Close video</span>
            </button>
          </div>
        )}
      </>
    </Landing>
  );
};

export { LandingVideo };
