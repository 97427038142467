import { TRACK_OWNER_IDX } from '~/common/constants/constants';
import { MentorsPayload, AppLocalizationKey } from '~/common/types/types';
import vladyslavZubko from '~/assets/img/mentors/vladyslav-zubko.png';
import mykytaRemeslov from '~/assets/img/mentors/mykyta-remeslov.png';
import vitaliyKalenichenko from '~/assets/img/mentors/vitaliy-kalenichenko.png';
import antonKomisarov from '~/assets/img/mentors/anton-komisarov.png';
import faridShabanov from '~/assets/img/mentors/farid-shabanov.png';
import dmytroPopovych from '~/assets/img/mentors/dmytro-popovych.png';

const MENTORS = [
  vladyslavZubko,
  mykytaRemeslov,
  antonKomisarov,
  vitaliyKalenichenko,
  faridShabanov,
  dmytroPopovych,
];

const mentorsPayload: MentorsPayload = {
  title: 'technology.js.mentors.title',
  items: MENTORS.map((it, idx) => ({
    name: `technology.js.mentors.mentor${idx}` as AppLocalizationKey,
    imgPath: it,
    isTrackOwner: idx === TRACK_OWNER_IDX,
  })),
};

export { mentorsPayload };
