import * as React from 'react';
import { HistoryItem } from '~/common/types/types';
import { HistorySelectItem } from '../components';
import * as styles from './history-select-list.module.scss';

type Props = {
  items: HistoryItem[];
  onGoToSlide: (idx: number) => void;
  activeIndex: number;
};

const HistorySelectList: React.FC<Props> = ({
  items,
  onGoToSlide,
  activeIndex,
}) => (
  <ul className={styles.list}>
    {items.map((item, idx) => {
      const isActive = idx === activeIndex;

      return (
        <HistorySelectItem
          item={item}
          itemIdx={idx}
          isActive={isActive}
          onYearSelect={onGoToSlide}
          key={item.year}
        />
      );
    })}
  </ul>
);

export { HistorySelectList };
