import * as React from 'react';
import { QuestionsPayload, SectionUIProps } from '~/common/types/types';
import {
  FormattedMessage,
  SectionLayout,
  Link,
} from '~/components/common/common';
import * as styles from './questions.module.scss';
import { QuestionList } from './components/components';

type Props = SectionUIProps & {
  payload: QuestionsPayload;
};

const QUESTIONS_CLASSES = {
  wrapper: styles.wrapper,
  title: styles.title,
  section: styles.section,
};

const Questions: React.FC<Props> = ({ payload, sectionHighlight }) => {
  const { title, items, question, link } = payload;

  return (
    <SectionLayout
      classes={QUESTIONS_CLASSES}
      headingMessage={title}
      sectionHighlight={sectionHighlight}
    >
      <div className={styles.listWrapper}>
        <QuestionList questions={items} />
      </div>
      <Link localLink={link} className={styles.moreQuestionLink}>
        <FormattedMessage message={question} />
      </Link>
    </SectionLayout>
  );
};

export { Questions };
