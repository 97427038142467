const ExternalLink = {
  BS: {
    ROOT: 'https://binary-studio.com/',
    POLICY: 'https://binary-studio.com/privacy-policy/',
  },
  BSA: {
    ROOT: 'https://academy.binary-studio.com',
    BLOG: 'https://academy.binary-studio.com/blog/',
  },
  LMS: {
    LOGIN: 'https://study.binary-studio.com/auth/login/',
  },
  SOCIALS: {
    INSTAGRAM: 'https://www.instagram.com/binary_studio_academy/',
    TELEGRAM: 'https://t.me/binarystudioacademy',
    FACEBOOK: 'https://www.facebook.com/BinaryStudioAcademy',
  },
} as const;

export { ExternalLink };
