import * as React from 'react';
import { COBPayload } from '~/common/types/types';
import { COBSection } from './components/components';
import * as styles from './cob.module.scss';

type Props = {
  payload: COBPayload;
};

const COB: React.FC<Props> = ({ payload }) => {
  const { sections } = payload;

  return (
    <div className={styles.contentWrapper}>
      {sections.map((it) => (
        <COBSection section={it} key={it.title} />
      ))}
    </div>
  );
};

export { COB };
