"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeStringCase = void 0;
const change_case_1 = require("change-case");
const caseTypeToFn = {
    'kebab': change_case_1.paramCase,
    'header': change_case_1.headerCase,
};
const changeStringCase = (string, caseType, options) => {
    const getChangedStringCase = caseTypeToFn[caseType];
    return getChangedStringCase(string, options);
};
exports.changeStringCase = changeStringCase;
