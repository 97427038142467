import React from 'react';
import { TeamCompositionItem } from '../components';
import { TeamCompositionItem as TTeamCompositionItem } from '~/common/types/types';
import * as styles from './team-compisition-list.module.scss';

type Props = {
  items: TTeamCompositionItem[];
};

const TeamCompositionList: React.FC<Props> = ({ items }) => {
  return (
    <ul className={styles.wrapper}>
      {items.map((it) => (
        <TeamCompositionItem key={it.title} item={it} />
      ))}
    </ul>
  );
};

export { TeamCompositionList };
