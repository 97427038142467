import * as React from 'react';
import { SectionUIProps, AppLocalizationKey } from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './section-layout.module.scss';

type Props = SectionUIProps & {
  classes?: {
    section?: string;
    wrapper?: string;
    title?: string;
  };
  headingLevel?: 'h2' | 'h3';
  headingMessage?: AppLocalizationKey;
};

const SectionLayout: React.FC<Props> = ({
  classes,
  headingLevel = 'h2',
  sectionHighlight,
  sectionIcon,
  headingMessage,
  children,
}) => {
  const Heading = headingLevel;
  const hasHeading = Boolean(headingMessage);
  const hasIcon = Boolean(sectionIcon);

  return (
    <section
      className={getValidClasses(
        styles[sectionHighlight as string],
        classes?.section,
      )}
    >
      <div className={getValidClasses(styles.wrapper, classes?.wrapper)}>
        {hasHeading && (
          <Heading
            className={getValidClasses(
              styles.title,
              hasIcon && styles.titleIcon,
              styles[sectionIcon as string],
              classes?.title,
            )}
          >
            <FormattedMessage message={headingMessage as AppLocalizationKey} />
          </Heading>
        )}
        {children}
      </div>
    </section>
  );
};

export { SectionLayout };
