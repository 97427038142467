import { ENV } from '~/common/enums/enums';
import { APP_LANGUAGES } from '~/common/constants/constants';

const excludeLanguageFromPathname = (
  str: string,
  languages: string[],
): string => {
  const excludedLanguageFromUrl = str.split('/').filter((symbol) => {
    const isValidPath = Boolean(symbol);
    const isLanguagePart = languages.some((language) => symbol === language);

    return isValidPath && !isLanguagePart;
  });

  return `/${excludedLanguageFromUrl.join('/')}`;
};

const getChangeLanguageUrl = ({
  language,
  pathname,
}: {
  language: string;
  pathname: string;
}): string => {
  const isDefaultLanguage = language === ENV.APP.DEFAULT_LANGUAGE;

  const languagePrefix = isDefaultLanguage ? '' : `/${language}`;

  const normalizedUrl = excludeLanguageFromPathname(
    pathname,
    Array.from(APP_LANGUAGES),
  );

  return `${languagePrefix}${normalizedUrl}`;
};

export { getChangeLanguageUrl };
