import * as React from 'react';
import { FooterSocialItem } from '~/common/types/types';
import { SocialItem } from '../components';
import * as styles from './social-list.module.scss';

type Props = {
  items: FooterSocialItem[];
};

const SocialList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <SocialItem item={it} key={it.title} />
    ))}
  </ul>
);

export { SocialList };
