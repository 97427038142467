import * as React from 'react';
import { Image } from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './review-item-image.module.scss';

type Props = {
  src: string;
  decorationKey: number;
  isActive: boolean;
};

const ReviewItemImage: React.FC<Props> = ({ src, decorationKey, isActive }) => (
  <div
    className={getValidClasses(
      styles.wrapper,
      isActive && styles.wrapperActive,
    )}
  >
    <div
      className={getValidClasses(
        styles.imgWrapper,
        isActive && styles.decoration,
        isActive && styles[`decoration${decorationKey}`],
      )}
    >
      <Image
        src={src}
        className={getValidClasses(
          styles.img,
          isActive && styles.imgActive,
          isActive && styles.imgDecoration,
          isActive && styles[`imgDecoration${decorationKey}`],
        )}
        alt="reviewer"
      />
    </div>
  </div>
);

export { ReviewItemImage };
