// extracted by mini-css-extract-plugin
export var item = "team-composition-item-module--item--sB+nE";
export var image = "team-composition-item-module--image--SzS9Y";
export var title = "team-composition-item-module--title--1JZ-u";
export var description = "team-composition-item-module--description--adtMt";
export var surrounded = "team-composition-item-module--surrounded--yDDGL";
export var productOwnerImage = "team-composition-item-module--product-owner-image--1tybg";
export var qaImage = "team-composition-item-module--qa-image--Z4CJW";
export var surroundedImage = "team-composition-item-module--surrounded-image--yAdL9";
export var supercoach = "team-composition-item-module--supercoach--nlm4L";
export var coach = "team-composition-item-module--coach--xaf6g";
export var developer = "team-composition-item-module--developer--nOCOz";
export var qa = "team-composition-item-module--qa--lnPjT";
export var productOwner = "team-composition-item-module--product-owner--jXfgz";
export var designer = "team-composition-item-module--designer--GJkxs";
export var supercoachImage = "team-composition-item-module--supercoach-image--rYJH5";
export var coachImage = "team-composition-item-module--coach-image--I0NyO";
export var developerImage = "team-composition-item-module--developer-image--OlrRA";