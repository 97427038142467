import { TimelinePayload } from '~/common/types/types';

const timelinePayload: TimelinePayload = {
  badgeText: 'timeline.badge',
  sections: [
    {
      title: 'timeline.section0.title',
      subtitle: 'timeline.section0.subtitle',
      descriptor: {
        text: 'timeline.section0.descriptor',
        position: 'right',
      },
      items: [
        {
          title: 'timeline.section0.group0.title',
          description: 'timeline.section0.group0.description',
          dateStart: '2024-04-02',
          dateEnd: '2024-05-24',
          dateSeparator: null,
        },
        {
          title: 'timeline.section0.group1.title',
          description: 'timeline.section0.group1.description',
          dateStart: '2024-05-25',
          dateEnd: '2024-05-28',
          dateSeparator: 'timeline.section0.group1.dateSeparator',
        },
        {
          title: 'timeline.section0.group2.title',
          description: 'timeline.section0.group2.description',
          dateStart: '2024-05-31',
          dateEnd: '2024-06-11',
          dateSeparator: null,
        },
      ],
    },
    {
      title: 'timeline.section1.title',
      subtitle: 'timeline.section1.subtitle',
      descriptor: {
        text: 'timeline.section1.descriptor',
        position: 'left',
      },
      sectionHighlight: 'secondary',
      items: [
        {
          title: 'timeline.section1.group0.title',
          description: 'timeline.section1.group0.description',
          dateStart: '2024-06-26',
          dateEnd: '2024-08-08',
          dateSeparator: null,
        },
        {
          title: 'timeline.section1.group1.title',
          description: 'timeline.section1.group1.description',
          dateStart: '2024-06-26',
          dateEnd: '2024-08-08',
          dateSeparator: null,
        },
        {
          title: 'timeline.section1.group2.title',
          description: 'timeline.section1.group2.description',
          dateStart: '2024-08-16',
          dateEnd: '2024-09-27',
          dateSeparator: null,
        },
        {
          title: 'timeline.section1.group3.title',
          description: 'timeline.section1.group3.description',
          dateStart: '2024-09-28',
          dateEnd: null,
          dateSeparator: null,
        },
      ],
    },
  ],
};

export { timelinePayload };
