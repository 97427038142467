import { FAQPayload, AppLocalizationKey } from '~/common/types/types';
import { generateItems } from '~/helpers/helpers';

const ITEMS_COUNT = 19;

const faqPayload: FAQPayload = {
  items: generateItems(ITEMS_COUNT, (idx) => ({
    question: `about.faq.group${idx}.question` as AppLocalizationKey,
    answer: `about.faq.group${idx}.answer` as AppLocalizationKey,
  })),
};

export { faqPayload };
