import * as React from 'react';
import { default as Helmet } from 'react-helmet';
import { SeoPayload } from '~/common/types/types';
import { useAppTranslation } from '~/hooks/hooks';
import ogImage from '~/assets/img/social.png';
import { getScripts } from './helpers/helpers';
import { languageToISO } from '~/common/maps/maps';

type Props = {
  payload: SeoPayload;
};

const Seo: React.FC<Props> = ({ payload }) => {
  const { title, description, siteUrl } = payload;
  const { currentLanguage, handleTranslate } = useAppTranslation();

  const scripts = getScripts();

  return (
    <Helmet
      htmlAttributes={{
        lang: languageToISO[currentLanguage],
      }}
      title={handleTranslate(title)}
      meta={[
        {
          name: 'description',
          content: handleTranslate(description),
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: handleTranslate(title),
        },
        {
          property: 'og:description',
          content: handleTranslate(description),
        },
        {
          property: 'og:site_name',
          content: handleTranslate(description),
        },
        {
          property: 'og:url',
          content: siteUrl,
        },
        {
          property: 'og:image',
          content: ogImage,
        },
      ]}
      script={scripts}
    />
  );
};

export { Seo };
