import * as React from 'react';
import * as styles from './details.module.scss';

type Props = {
  children: [React.ReactNode, React.ReactNode];
};

const Details: React.FC<Props> = ({ children }) => {
  const [heading, content] = children;

  return (
    <details className={styles.details}>
      <summary className={styles.summary}>{heading}</summary>
      <div className={styles.content}>{content}</div>
    </details>
  );
};

export { Details };
