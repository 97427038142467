import * as React from 'react';
import { default as i18n } from 'i18next';
import {
  initReactI18next,
  I18nextProvider,
  I18nextProviderProps,
} from 'react-i18next';
import { UAMessagesMap, ENMessagesMap } from '~/locales/locales';
import { AppEnvironment, AppLanguage, ENV } from '~/common/enums/enums';

const isDebug = ENV.APP.ENVIRONMENT === AppEnvironment.DEVELOPMENT;

class Internationalization {
  #instance: typeof i18n;

  constructor() {
    this.#instance = i18n;

    i18n.use(initReactI18next).init({
      debug: isDebug,
      fallbackLng: ENV.APP.DEFAULT_LANGUAGE,
      initImmediate: false,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        [AppLanguage.UA]: {
          translation: UAMessagesMap,
        },
        [AppLanguage.EN]: {
          translation: ENMessagesMap,
        },
      },
    });
  }

  get instance(): typeof i18n {
    return this.#instance;
  }

  get provider(): React.FC<I18nextProviderProps> {
    return I18nextProvider;
  }
}

export { Internationalization };
