import * as React from 'react';
import { AppRoute } from '~/common/enums/enums';
import { NavigationLink } from '~/common/types/types';
import { Link } from '~/components/common/common';
import * as styles from './navigation-item.module.scss';

type Props = {
  item: NavigationLink;
};

const NavigationItem: React.FC<Props> = ({ item }) => (
  <li className={styles.item}>
    <Link
      className={styles.link}
      externalLink={item.externalLink}
      localLink={item.localLink as AppRoute}
    >
      {item.title}
    </Link>
  </li>
);

export { NavigationItem };
