import * as React from 'react';
import {
  CarouselBreakpoints,
  MentorsItem as TMentorsItem,
} from '~/common/types/types';
import { Carousel } from '~/components/common/common';
import { MentorsItem } from '../components';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './mentors-list.module.scss';

const BREAKPOINTS: CarouselBreakpoints = {
  0: {
    slidesPerView: 1,
  },
  576: {
    slidesPerView: 2,
  },
  1024: {
    slidesPerView: 3,
  },
};

type Props = {
  items: TMentorsItem[];
};

const MentorsList: React.FC<Props> = ({ items }) => (
  <Carousel
    breakpoints={BREAKPOINTS}
    hasNavigation
    className={getValidClasses(styles.mentors, styles.wrapper)}
  >
    {items.map((it) => (
      <MentorsItem item={it} key={it.name} />
    ))}
  </Carousel>
);

export { MentorsList };
