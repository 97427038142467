import * as React from 'react';
import { WrapPageElementBrowserArgs } from 'gatsby';
import { InternationalizationWrapper } from '~/components/common/common';
import { DefaultPageContext } from '~/common/types/types';
import '~/assets/css/styles.scss';

const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<null, DefaultPageContext>): React.ReactNode => (
  <InternationalizationWrapper
    language={props.pageContext.language}
    isSSR={false}
  >
    {element}
  </InternationalizationWrapper>
);

export { wrapPageElement };
