import * as React from 'react';
import { IconName } from '~/common/enums/enums';
import { getValidClasses } from '~/helpers/helpers';
import arrowRight from '~/assets/img/icons/arrow-right.inline.svg';
import check from '~/assets/img/icons/check.inline.svg';
import quote from '~/assets/img/icons/quote.inline.svg';
import signIn from '~/assets/img/icons/sign-in.inline.svg';
import calendar from '~/assets/img/icons/calendar.inline.svg';
import * as styles from './icon.module.scss';

const iconNameToIcon = {
  [IconName.ARROW_RIGHT]: arrowRight,
  [IconName.CHECK]: check,
  [IconName.SIGN_IN_ALT]: signIn,
  [IconName.QUOTE]: quote,
  [IconName.CALENDAR]: calendar,
};

type Props = {
  iconName: IconName;
  className?: string;
};

const Icon: React.FC<Props> = ({ iconName, className }) => {
  const SvgIcon = iconNameToIcon[iconName];

  return (
    <SvgIcon
      className={getValidClasses(styles.icon, className)}
      aria-hidden="true"
    />
  );
};

export { Icon };
