import * as React from 'react';
import { COBSection as TCOBSection } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './cob-section.module.scss';

type Props = {
  section: TCOBSection;
};

const COBSection: React.FC<Props> = ({ section }) => {
  const { title, items } = section;

  return (
    <section>
      <h2 className={styles.sectionTitle}>
        <FormattedMessage message={title} />
      </h2>
      <ul className={styles.sectionList}>
        {items.map((it) => (
          <li key={it}>
            <FormattedMessage message={it} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export { COBSection };
