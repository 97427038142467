import * as React from 'react';
import { EmbedVideoPayload, SectionUIProps } from '~/common/types/types';
import { SectionLayout } from '~/components/common/common';
import * as styles from './embed-video.module.scss';

type Props = SectionUIProps & {
  payload: EmbedVideoPayload;
};

const EmbedVideo: React.FC<Props> = ({ payload, sectionHighlight }) => {
  const { title, videoSrc } = payload;

  return (
    <SectionLayout headingMessage={title} sectionHighlight={sectionHighlight}>
      <div className={styles.wrapper}>
        <iframe
          className={styles.iframe}
          src={videoSrc}
          title="video"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        />
      </div>
    </SectionLayout>
  );
};

export { EmbedVideo };
