import { AppLocalizationKey, TechtimePayload } from '~/common/types/types';
import { generateItems } from '~/helpers/helpers';

const ITEMS_COUNT = 2;

const techtimePayload: TechtimePayload = {
  title: 'technology.js.techtime.title',
  items: generateItems(ITEMS_COUNT, (idx) => ({
    title: `technology.js.techtime.group${idx}.title` as AppLocalizationKey,
    period: `technology.js.techtime.group${idx}.period` as AppLocalizationKey,
    description:
      `technology.js.techtime.group${idx}.description` as AppLocalizationKey,
    activity:
      `technology.js.techtime.group${idx}.activity` as AppLocalizationKey,
  })),
  technologies: [
    'JavaScript',
    'Node.js',
    'React',
    'Express.js',
    'MongoDB',
    'PostgreSQL',
  ],
};

export { techtimePayload };
