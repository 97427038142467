import * as React from 'react';
import { getValidClasses } from '~/helpers/helpers';
import { NavigationLink } from '~/common/types/types';
import { NavigationItem } from '../components';
import * as styles from './navigation-list.module.scss';

type Props = {
  items: NavigationLink[];
  hasRawText?: boolean;
  hasItemBorder?: boolean;
};

const NavigationList: React.FC<Props> = ({
  items,
  hasRawText = false,
  hasItemBorder = false,
}) => (
  <ul
    className={getValidClasses(
      styles.list,
      hasItemBorder ? styles.borderList : styles.navigationList,
    )}
  >
    {items.map((it) => (
      <NavigationItem
        item={it}
        key={it.title}
        hasRawText={hasRawText}
        hasBorder={hasItemBorder}
      />
    ))}
  </ul>
);

export { NavigationList };
