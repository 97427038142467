// extracted by mini-css-extract-plugin
export var wrapper = "blog-author-image-module--wrapper--sefKU";
export var container = "blog-author-image-module--container--vc3LW";
export var containerDecoration1 = "blog-author-image-module--container-decoration1--7SU5n";
export var containerDecoration2 = "blog-author-image-module--container-decoration2--JfSWQ";
export var containerDecoration3 = "blog-author-image-module--container-decoration3--qmbDS";
export var circle = "blog-author-image-module--circle--C7K+R";
export var circleDecoration1 = "blog-author-image-module--circle-decoration1--ZbSr1";
export var circleDecoration2 = "blog-author-image-module--circle-decoration2--B6IY7";
export var circleDecoration3 = "blog-author-image-module--circle-decoration3--cZ6nC";
export var img = "blog-author-image-module--img--3FEhj";
export var imgDecoration1 = "blog-author-image-module--img-decoration1--yewBX";
export var imgDecoration2 = "blog-author-image-module--img-decoration2--ni+el";
export var imgDecoration3 = "blog-author-image-module--img-decoration3--7zFPi";