import { AppRoute, ExternalLink, IconName } from '~/common/enums/enums';
import { HeaderPayload, NavigationLink } from '~/common/types/types';
import { DIRECTIONS, APP_LANGUAGES } from '~/common/constants/constants';
import { directionKindToReadable } from '~/common/maps/maps';

const directionLinks: NavigationLink[] = DIRECTIONS.map((it) => ({
  title: directionKindToReadable[it],
  localLink: `${AppRoute.TECHNOLOGY}/${it}`,
}));

const headerPayload: HeaderPayload = {
  navigationItems: directionLinks,
  userNavigationItems: [
    {
      title: 'header.about',
      localLink: AppRoute.ABOUT,
    },
    {
      title: 'header.online',
      localLink: AppRoute.ONLINE_EVENTS,
    },
    {
      title: 'header.blog',
      externalLink: ExternalLink.BSA.BLOG,
      target: 'self',
    },
    {
      title: 'header.login',
      externalLink: ExternalLink.LMS.LOGIN,
      icon: IconName.SIGN_IN_ALT,
    },
  ],
  languages: Array.from(APP_LANGUAGES),
  registrationBtn: 'registration.button',
};

export { headerPayload };
