import * as React from 'react';
import { AppLanguage, AppRoute } from '~/common/enums/enums';
import { getValidClasses } from '~/helpers/helpers';
import { Link } from '~/components/common/common';
import * as styles from './language-item.module.scss';

type Props = {
  language: AppLanguage;
  url: AppRoute;
  isActive: boolean;
};

const LanguageItem: React.FC<Props> = ({ language, url, isActive }) => (
  <li>
    <Link
      localLink={url}
      className={getValidClasses(styles.item, isActive && styles.itemActive)}
      hasLanguagePrefix={false}
    >
      {language}
    </Link>
  </li>
);

export { LanguageItem };
