import * as React from 'react';
import { FooterPartnerItem } from '~/common/types/types';
import { PartnersItem } from '../components';
import * as styles from './partners-list.module.scss';

type Props = {
  items: FooterPartnerItem[];
};

const PartnersList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <PartnersItem item={it} key={it.title} />
    ))}
  </ul>
);

export { PartnersList };
