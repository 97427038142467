import * as React from 'react';
import { ENV, AboutTabItem } from '~/common/enums/enums';
import {
  footerPayload,
  headerPayload,
  navigationPayload,
} from '~/common/payload/payload';
import { AboutLayout } from '~/components/common/common';
import { FAQ } from '~/components/sections/sections';
import { faqPayload, seoPayload } from './data/data';

const Page: React.FC = () => (
  <AboutLayout
    seoPayload={seoPayload}
    headerPayload={headerPayload}
    footerPayload={footerPayload}
    navigationPayload={navigationPayload}
    activeTabItem={AboutTabItem.FAQ}
    registrationLink={ENV.APP.REGISTRATION_LINK}
  >
    <h1 className="visually-hidden">FAQ</h1>
    <FAQ payload={faqPayload} />
  </AboutLayout>
);

export { Page };
