"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIsDatesInRange = void 0;
const date_fns_1 = require("date-fns");
const checkIsDatesInRange = (date, startDate, endDate) => {
    return (0, date_fns_1.isWithinInterval)(new Date(date), {
        start: new Date(startDate),
        end: new Date(endDate),
    });
};
exports.checkIsDatesInRange = checkIsDatesInRange;
