import * as React from 'react';
import { AppLanguage, AppRoute } from '~/common/enums/enums';
import { HeaderPayload } from '~/common/types/types';
import logo from '~/assets/img/bsa-logo.svg';
import {
  Image,
  Link,
  Button,
  FormattedMessage,
} from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import { useState } from '~/hooks/hooks';
import { LanguageList, NavigationList } from './components/components';
import * as styles from './header.module.scss';

type Props = {
  payload: HeaderPayload;
  currentLanguage: AppLanguage;
  registrationLink: string | null;
};

const Header: React.FC<Props> = ({
  payload,
  currentLanguage,
  registrationLink,
}) => {
  const { registrationBtn, navigationItems, userNavigationItems, languages } =
    payload;
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const hasRegistrationLink = Boolean(registrationLink);
  const hasLanguages = Boolean(languages.length);

  const handleMenuToggle = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.logoWrapper}>
          <Link localLink={AppRoute.ROOT}>
            <Image src={logo} alt="logo" />
            <span className="visually-hidden">Go home</span>
          </Link>
          <nav className={styles.directions}>
            <NavigationList items={navigationItems} hasRawText hasItemBorder />
          </nav>
          <button
            className={styles.navbarBurgerButton}
            onClick={handleMenuToggle}
          >
            <div
              className={getValidClasses(
                isMenuOpen && styles.navbarBurgerActive,
              )}
            >
              <div className={styles.navbarBurgerLine}></div>
            </div>
          </button>
        </div>
        <div
          className={getValidClasses(
            styles.headerMenuWrapper,
            isMenuOpen ? styles.navbarMenuOpen : styles.navbarMenuClose,
          )}
        >
          <NavigationList items={userNavigationItems} />
          {hasRegistrationLink && (
            <Button
              gtm="sign_up"
              className={styles.registerBtn}
              externalLink={registrationLink as string}
              withLanguageQuery
              hasShadow
            >
              <FormattedMessage message={registrationBtn} />
            </Button>
          )}
          {hasLanguages && (
            <LanguageList
              languages={languages}
              currentLanguage={currentLanguage}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export { Header };
