import * as React from 'react';
import { FAQItem } from '~/common/types/types';
import { Details, FormattedMessage } from '~/components/common/common';
import * as styles from './faq-list.module.scss';

type Props = {
  items: FAQItem[];
};

const FAQList: React.FC<Props> = ({ items }) => (
  <ul className={styles.faqList}>
    {items.map((it) => (
      <Details key={it.question}>
        <FormattedMessage message={it.question} />
        <FormattedMessage message={it.answer} />
      </Details>
    ))}
  </ul>
);

export { FAQList };
