import * as React from 'react';
import { AboutSection as TAboutSection } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './about-section.module.scss';

type Props = {
  section: TAboutSection;
};

const AboutSection: React.FC<Props> = ({ section }) => {
  const { title, items } = section;

  return (
    <section>
      <h2 className={styles.sectionTitle}>
        <FormattedMessage message={title} />
      </h2>
      {items.map((it) => (
        <p key={it} className={styles.sectionParagraph}>
          <FormattedMessage message={it} />
        </p>
      ))}
    </section>
  );
};

export { AboutSection };
