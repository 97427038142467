import { AppLanguage } from '~/common/enums/enums';

type GetFullLinkQuery = (args: {
  initialQueries: Record<string, string>;
  language: AppLanguage;
  withLanguageQuery: boolean;
}) => string;

const getFullLinkQuery: GetFullLinkQuery = ({
  initialQueries,
  language,
  withLanguageQuery,
}) => {
  const searchParams = new URLSearchParams(initialQueries);

  if (withLanguageQuery) {
    searchParams.append('locale', language);
  }

  return searchParams.toString();
};

export { getFullLinkQuery };
