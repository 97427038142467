import { InfoPayload } from '~/common/types/types';

const infoPayload: InfoPayload = {
  title: 'technology.js.info.title',
  items: [
    {
      icon: 'magister',
      title: 'technology.js.info.group0.title',
      description: 'technology.js.info.group0.description',
    },
    {
      icon: 'gear',
      title: 'technology.js.info.group1.title',
      description: 'technology.js.info.group1.description',
    },
    {
      icon: 'star',
      title: 'technology.js.info.group2.title',
      description: 'technology.js.info.group2.description',
    },
    {
      icon: 'laptop',
      title: 'technology.js.info.group3.title',
      description: 'technology.js.info.group3.description',
    },
    {
      icon: 'cluster',
      title: 'technology.js.info.group4.title',
      description: 'technology.js.info.group4.description',
    },
    {
      icon: 'diplomatic',
      title: 'technology.js.info.group5.title',
      description: 'technology.js.info.group5.description',
    },
    {
      icon: 'award',
      title: 'technology.js.info.group6.title',
      description: 'technology.js.info.group6.description',
    },
  ],
  registrationBtn: 'registration.button',
};

export { infoPayload };
