import { InfoPayload } from '~/common/types/types';

const infoPayload: InfoPayload = {
  title: 'info.title',
  items: [
    {
      icon: 'hands',
      title: 'info.group0.title',
      description: 'info.group0.description',
    },
    {
      icon: 'head',
      title: 'info.group1.title',
      description: 'info.group1.description',
    },
    {
      icon: 'people',
      title: 'info.group2.title',
      description: 'info.group2.description',
    },
  ],
  registrationBtn: 'registration.button',
};

export { infoPayload };
