// extracted by mini-css-extract-plugin
export var item = "sections-item-module--item--dBcFz";
export var title = "sections-item-module--title--8dCyp";
export var subtitle = "sections-item-module--subtitle--WC8rN";
export var descriptor = "sections-item-module--descriptor--8v2lx";
export var hasSubtitle = "sections-item-module--has-subtitle--pYLzI";
export var listWrapper = "sections-item-module--list-wrapper--HeRYr";
export var descriptorDecoration = "sections-item-module--descriptor-decoration--eiT1m";
export var leftDecoration = "sections-item-module--left-decoration--g8RLo";
export var rightDecoration = "sections-item-module--right-decoration--u3KR9";
export var spacing = "sections-item-module--spacing--4NWvh";