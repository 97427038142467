import * as React from 'react';
import { HistoryItem as THistoryItem } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import { useHistoryCountAnimation } from './hooks/hooks';
import * as styles from './history-item.module.scss';

type Props = {
  item: THistoryItem;
  isActive: boolean;
};

const HistoryItem: React.FC<Props> = ({ item, isActive }) => {
  const { currentApplicants, currentStudents } = useHistoryCountAnimation({
    item,
    isActive,
  });

  return (
    <article className={styles.item}>
      <div className={styles.wrapper}>
        <dl className={styles.outputs}>
          <div className={styles.outputsItem}>
            <dt className={styles.outputsItemTitle}>{currentApplicants}</dt>
            <dd className={styles.outputsItemDescription}>
              <FormattedMessage message={item.applicantsTitle} />
            </dd>
          </div>
          <div className={styles.outputsItem}>
            <dt className={styles.outputsItemTitle}> {currentStudents}</dt>
            <dd className={styles.outputsItemDescription}>
              <FormattedMessage message={item.studentsTitle} />
            </dd>
          </div>
        </dl>
        <div>
          <h3 className={styles.title}>
            <FormattedMessage message={item.title} />
          </h3>
          <p className={styles.description}>
            <FormattedMessage message={item.historyText} />
          </p>
        </div>
      </div>
    </article>
  );
};

export { HistoryItem };
