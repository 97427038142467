import seoSrc from '~/assets/img/artem-goncharov.png';
import { generateItems } from '~/helpers/helpers';
import { AppLocalizationKey, FoundingTeamPayload } from '~/common/types/types';

const PARAGRAPHS_COUNT = 5;

const foundingTeamPayload: FoundingTeamPayload = {
  imageSrc: seoSrc,
  ceoName: 'foundingTeam.ceoName',
  ceoPosition: 'foundingTeam.ceoPosition',
  paragraphs: generateItems(
    PARAGRAPHS_COUNT,
    (idx) => `foundingTeam.paragraph${idx}` as AppLocalizationKey,
  ),
};

export { foundingTeamPayload };
