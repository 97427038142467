import * as React from 'react';
import { AboutTabItem, ENV } from '~/common/enums/enums';
import {
  footerPayload,
  headerPayload,
  navigationPayload,
} from '~/common/payload/payload';
import { AboutLayout } from '~/components/common/common';
import { COB } from '~/components/sections/sections';
import { cobPayload, seoPayload } from './data/data';

const Page: React.FC = () => (
  <AboutLayout
    seoPayload={seoPayload}
    headerPayload={headerPayload}
    footerPayload={footerPayload}
    navigationPayload={navigationPayload}
    activeTabItem={AboutTabItem.COB}
    registrationLink={ENV.APP.REGISTRATION_LINK}
  >
    <h1 className="visually-hidden">Code of behavior</h1>
    <COB payload={cobPayload} />
  </AboutLayout>
);

export { Page };
