import { HistoryItem } from '~/common/types/types';
import { useState, useEffect } from '~/hooks/hooks';

const APPLICANTS_ANIMATION_SPEED_COUNT = 20;
const ANIMATION_TIMEOUT = 20;

type UseHistoryCountAnimation = (params: {
  item: HistoryItem;
  isActive: boolean;
}) => {
  currentApplicants: number;
  currentStudents: number;
};

const useHistoryCountAnimation: UseHistoryCountAnimation = ({
  isActive,
  item,
}) => {
  const [currentApplicants, setCurrentApplicants] = useState<number>(0);
  const [currentStudents, setCurrentStudents] = useState<number>(0);

  useEffect(() => {
    if (isActive) {
      setCurrentApplicants(0);
      setCurrentStudents(0);
    }
  }, [isActive]);

  useEffect(() => {
    const hasAnimation = currentApplicants < item.applicantsNum;

    if (!hasAnimation) {
      return;
    }

    const timeout = setTimeout(() => {
      setCurrentApplicants(
        currentApplicants +
          Number(currentApplicants < item.applicantsNum) *
            APPLICANTS_ANIMATION_SPEED_COUNT,
      );
    }, ANIMATION_TIMEOUT);

    return (): void => clearTimeout(timeout);
  }, [currentApplicants]);

  useEffect(() => {
    const hasAnimation = currentStudents < item.studentsNum;

    if (!hasAnimation) {
      return;
    }

    const timeout = setTimeout(() => {
      setCurrentStudents(
        currentStudents + Number(currentStudents < item.studentsNum),
      );
    }, ANIMATION_TIMEOUT);

    return (): void => clearTimeout(timeout);
  }, [currentStudents]);

  return {
    currentApplicants,
    currentStudents,
  };
};

export { useHistoryCountAnimation };
