import * as React from 'react';
import { StatisticsItem as TStatisticsItem } from '~/common/types/types';
import { StatisticsItem } from '../components';
import * as styles from './statistics-list.module.scss';

type Props = {
  items: TStatisticsItem[];
};

const StatisticsList: React.FC<Props> = ({ items }) => (
  <ul className={styles.statistics}>
    {items.map((item) => (
      <StatisticsItem key={item.amount} item={item} />
    ))}
  </ul>
);

export { StatisticsList };
