import * as React from 'react';
import { BSADescriptionPayload, SectionUIProps } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import { TimelineList, BenefitList } from './components/components';
import * as styles from './bsa-description.module.scss';

type Props = SectionUIProps & {
  payload: BSADescriptionPayload;
};

const BSADescription: React.FC<Props> = ({ payload }) => {
  const {
    title,
    description,
    timelineItems,
    benefitItems,
    timelineTitle,
    benefitTitle,
  } = payload;

  return (
    <section className={styles.section}>
      <h2 className={styles.title}>
        <FormattedMessage message={title} />
      </h2>
      <p className={styles.description}>
        <FormattedMessage message={description} />
      </p>
      <section className={styles.subsection}>
        <h3 className={styles.subtitle}>
          <FormattedMessage message={timelineTitle} />
        </h3>
        <TimelineList items={timelineItems} />
      </section>
      <section className={styles.subsection}>
        <h3 className={styles.subtitle}>
          <FormattedMessage message={benefitTitle} />
        </h3>
        <BenefitList items={benefitItems} />
      </section>
    </section>
  );
};

export { BSADescription };
