import * as React from 'react';
import { SectionLayout } from '~/components/common/common';
import { HistoryPayload, SectionUIProps } from '~/common/types/types';
import { HistoryList } from './components/components';

type Props = SectionUIProps & {
  payload: HistoryPayload;
};

const History: React.FC<Props> = ({ payload, sectionHighlight }) => {
  const { title, items } = payload;

  return (
    <SectionLayout headingMessage={title} sectionHighlight={sectionHighlight}>
      <HistoryList items={items} />
    </SectionLayout>
  );
};

export { History };
