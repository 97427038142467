import { AppEnvironment, AppLanguage } from './app';
import { ExternalLink } from './external-link.enum';

const ENV = {
  APP: {
    URL: ExternalLink.BSA.ROOT,
    REGISTRATION_LINK:
      'https://study.binary-studio.com/auth/register?academy=summer-2024',
    DEFAULT_LANGUAGE: AppLanguage.EN,
    ENVIRONMENT: (process.env.GATSBY_ENVIRONMENT ??
      AppEnvironment.DEVELOPMENT) as AppEnvironment,
  },
  FACEBOOK: {
    APP_ID: process.env.GATSBY_FB_APP_ID ?? '',
    PAGE_ID: process.env.GATSBY_FB_PAGE_ID ?? '',
  },
  GOOGLE: {
    TAG_MANAGER_ID: process.env.GATSBY_GOOGLE_TAG_MANAGER_ID ?? '',
  },
} as const;

export { ENV };
