// extracted by mini-css-extract-plugin
export var primary = "section-layout-module--primary--Wa3yN";
export var secondary = "section-layout-module--secondary--pr3c9";
export var wrapper = "section-layout-module--wrapper--2xLkx";
export var title = "section-layout-module--title--7IvAr";
export var titleIcon = "section-layout-module--title-icon--JG5c1";
export var scales = "section-layout-module--scales--DPP-c";
export var colosseum = "section-layout-module--colosseum--Y6GQi";
export var lion = "section-layout-module--lion--8TtXa";
export var headphones = "section-layout-module--headphones--RrzkB";
export var crown = "section-layout-module--crown--EPH2n";
export var phone = "section-layout-module--phone--OU5wd";
export var gates = "section-layout-module--gates--yVCB3";
export var glasses = "section-layout-module--glasses---S-+S";
export var mobile = "section-layout-module--mobile--BQnjd";
export var chariot = "section-layout-module--chariot--twHx1";
export var wreath = "section-layout-module--wreath--sPxQy";
export var harp = "section-layout-module--harp--KNE8t";
export var titleText = "section-layout-module--title-text--gXf1l";