import { EventsPayload } from '~/common/types/types';
import anna from '~/assets/img/speakers/anna.png';
import academy from '~/assets/img/speakers/academy.png';
import vitaliy from '~/assets/img/speakers/vitaliy.png';
import natalia from '~/assets/img/speakers/natalia.png';
import taras from '~/assets/img/speakers/taras.png';
import kyrylo1 from '~/assets/img/speakers/kyrylo-1.png';
import denis from '~/assets/img/speakers/denis.png';
import max1 from '~/assets/img/speakers/max-1.png';
import vlad from '~/assets/img/speakers/vlad.png';
import dima from '~/assets/img/speakers/dima.png';
import max from '~/assets/img/speakers/max.png';
import nikita from '~/assets/img/speakers/nikita.png';
import bohdan from '~/assets/img/speakers/bohdan.png';
import door from '~/assets/img/speakers/door.png';
import oleg from '~/assets/img/speakers/oleg.png';
import kyrylo from '~/assets/img/speakers/kyrylo.png';
import andriiBoiko from '~/assets/img/speakers/andrii-boiko.png';
import tetianaHutii from '~/assets/img/speakers/tetiana-hutii.png';

const eventsPayload: EventsPayload = {
  past: 'events.past',
  watch: 'events.watch',
  items: [
    {
      title: 'Фінальний проєкт: як ми писали навчальну платформу',
      speaker: 'Tetiana Hutii',
      imgPath: tetianaHutii,
      link: 'https://www.youtube.com/watch?v=Fn6mm7wQib4',
    },
    {
      title: 'REST vs GraphQL',
      speaker: 'Andrii Boiko',
      imgPath: andriiBoiko,
      link: 'https://www.youtube.com/watch?v=dwCFCA7OeEk',
    },
    {
      title: 'Solving QA challenges on a real project',
      speaker: 'Anna Kovalenko',
      imgPath: anna,
      link: 'https://youtu.be/dxYw7tn9kTo',
    },
    {
      title: 'Онлайн презентація Binary Studio Academy та Q&A',
      speaker: '',
      imgPath: academy,
      link: 'https://youtu.be/4F7yWqGap5g',
    },
    {
      title: 'Чому Java все ще в топі?',
      speaker: 'Vitalii Bedletskyi',
      imgPath: vitaliy,
      link: 'https://youtu.be/0a_WZ_2iw0Y',
    },
    {
      title: 'What you don`t know about SQL?',
      speaker: 'Nataliia Proskurnycha',
      imgPath: natalia,
      link: 'https://youtu.be/JQQ8np0Yswg',
    },
    {
      title: ' Electron: можеш у веб - зможеш і десктоп',
      speaker: 'Taras Dubyk',
      imgPath: taras,
      link: 'https://youtu.be/jcHD1DSmxqA',
    },
    {
      title: 'Hitchhiker`s guide to ruining the project',
      speaker: 'Kyrylo Lesohorskyi',
      imgPath: kyrylo1,
      link: 'https://www.youtube.com/watch?v=MLoO90ilTSM&feature=youtu.be',
    },
    {
      title:
        'React Native vs Flutter. Сучасна розробка кросс-платформових додатків',
      speaker: 'Denis Beketsky',
      imgPath: denis,
      link: 'https://www.youtube.com/watch?v=0N8GvQhajx8&feature=youtu.be',
    },
    {
      title: 'Trending Programming Languages',
      speaker: 'Maxim Goncharuk & Kyrylo Lesohorskyi',
      imgPath: max1,
      link: 'https://youtu.be/VFhpUBOKC-w',
    },
    {
      title: 'Modern Render',
      speaker: 'Vladyslav Zubko',
      imgPath: vlad,
      link: 'https://youtu.be/8qIXtCVckHY',
    },
    {
      title: 'Hello world vs production',
      speaker: 'Dmitriy Beseda',
      imgPath: dima,
      link: 'https://www.youtube.com/watch?v=XRT0GxZgzT4&feature=youtu.be',
    },
    {
      title:
        'Як приборкати звіра: ізольована розробка функціоналу у розподіленій системі',
      speaker: 'Maxim Goncharuk',
      imgPath: max,
      link: 'https://www.youtube.com/watch?v=F8AjHbVhUOw&feature=youtu.be',
    },
    {
      title: 'Чи реально джуніору “затащити” проєкт рівня Google?',
      speaker: 'Nikita Krasnov',
      imgPath: nikita,
      link: 'https://youtu.be/ZN7QpCr0H1o',
    },
    {
      title: 'Machine Learning на Node.js проєкті: як джуніору не розгубитися',
      speaker: 'Bohdan Rusinka',
      imgPath: bohdan,
      link: 'https://youtu.be/zYjW3QF-P28',
    },
    {
      title: 'День закритих дверей',
      speaker: '',
      imgPath: door,
      link: 'https://youtu.be/SOlxyzXsxwo',
    },
    {
      title: 'Робота програміста очима самого програміста',
      speaker: 'Oleh Kopot',
      link: 'https://youtu.be/sizqWSX_F7A',
      imgPath: oleg,
    },
    {
      title: 'Мовні війни: чи встигає JS за Rust, Kotlin та Go у 2020 році',
      speaker: 'Kyrylo Lesohorskyi',
      link: 'https://youtu.be/zCHHEpvVflI',
      imgPath: kyrylo,
    },
  ],
};

export { eventsPayload };
