import * as React from 'react';
import { Swiper, SwiperRef } from 'swiper/react';
import { carouselEffectToModule } from '~/common/maps/maps';
import { CarouselProps } from '~/common/types/types';
import { useActiveBreakpoint } from './hooks/hooks';
import { getBreakpoints } from './helpers/helpers';
import { Pagination, Navigation } from 'swiper/modules';
import { getValidClasses } from '~/helpers/helpers';
import { CarouselSlide } from './components/components';
import 'swiper/css';
import 'swiper/css/pagination';
import * as styles from './carousel.module.scss';

const Carousel: React.ForwardRefExoticComponent<
  CarouselProps & React.RefAttributes<SwiperRef>
> = React.forwardRef(
  ({ breakpoints, children, className, hasPagination, hasNavigation }, ref) => {
    const allBreakpoints = getBreakpoints(breakpoints);
    const activeBreakpoint = useActiveBreakpoint(allBreakpoints);

    const {
      isLooped,
      effect,
      slidesPerView,
      centeredSlides,
      coverflowEffect,
      spaceBetween,
      wrapperClassName,
    } = breakpoints[activeBreakpoint];

    const modules = [];

    if (effect) {
      modules.push(carouselEffectToModule[effect]);
    }

    if (hasPagination) {
      modules.push(Pagination);
    }

    if (hasNavigation) {
      modules.push(Navigation);
    }

    return (
      <Swiper
        ref={ref}
        key={`swiper-${activeBreakpoint}`}
        wrapperTag="ul"
        loop={isLooped}
        modules={modules}
        slidesPerView={slidesPerView}
        centeredSlides={centeredSlides}
        effect={effect}
        spaceBetween={spaceBetween}
        coverflowEffect={coverflowEffect}
        className={className}
        wrapperClass={getValidClasses(styles.wrapper, wrapperClassName)}
        observer
        pagination={hasPagination}
        navigation={{
          enabled: hasNavigation,
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
      >
        {React.Children.map(children, (child) => {
          return <CarouselSlide>{child}</CarouselSlide>;
        })}
        {hasNavigation && (
          <>
            <button
              className={getValidClasses(
                'swiper-button-prev',
                styles.swiperButton,
                styles.swiperButtonPrev,
              )}
            />
            <button
              className={getValidClasses(
                'swiper-button-next',
                styles.swiperButton,
                styles.swiperButtonNext,
              )}
            />
          </>
        )}
      </Swiper>
    );
  },
);

export { Carousel };
