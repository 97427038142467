import * as React from 'react';
import { NavigationLink } from '~/common/types/types';
import { NavigationItem } from '../components';
import * as styles from './navigation-list.module.scss';

type Props = {
  items: NavigationLink[];
};

const NavigationList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((it) => (
      <NavigationItem item={it} key={it.title} />
    ))}
  </ul>
);

export { NavigationList };
