import * as React from 'react';
import { Carousel } from '~/components/common/common';
import {
  CarouselBreakpoints,
  ReviewItem as TReviewItem,
} from '~/common/types/types';
import { CarouselEffect } from '~/common/enums/enums';
import { ReviewItem } from '../components';
import * as styles from './review-list.module.scss';

const BREAKPOINTS: CarouselBreakpoints = {
  0: {
    slidesPerView: 1,
    spaceBetween: '30',
  },
  1080: {
    isLooped: true,
    slidesPerView: 2,
    centeredSlides: true,
    effect: CarouselEffect.COVERFLOW,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 400,
      modifier: 1,
      slideShadows: false,
    },
  },
};

type Props = {
  items: TReviewItem[];
};

const ReviewList: React.FC<Props> = ({ items }) => (
  <Carousel breakpoints={BREAKPOINTS} hasNavigation className={styles.wrapper}>
    {items.map((it) => (
      <ReviewItem item={it} key={it.authorName} />
    ))}
  </Carousel>
);

export { ReviewList };
