import * as React from 'react';
import { AppRoute, IconName } from '~/common/enums/enums';
import { NavigationLink, AppLocalizationKey } from '~/common/types/types';
import { Link, FormattedMessage, Icon } from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './navigation-item.module.scss';

type Props = {
  hasRawText: boolean;
  item: NavigationLink;
  hasBorder?: boolean;
};

const NavigationItem: React.FC<Props> = ({ item, hasRawText, hasBorder }) => {
  const hasIcon = Boolean(item.icon);

  return (
    <li className={styles.item}>
      <Link
        className={getValidClasses(
          styles.link,
          hasBorder ? styles.border : styles.userLink,
        )}
        localLink={item.localLink as AppRoute}
        externalLink={item.externalLink}
        target={item.target}
      >
        {hasIcon && (
          <Icon className={styles.icon} iconName={item.icon as IconName} />
        )}{' '}
        {hasRawText ? (
          <span>{item.title}</span>
        ) : (
          <FormattedMessage message={item.title as AppLocalizationKey} />
        )}
      </Link>
    </li>
  );
};

export { NavigationItem };
