import * as React from 'react';
import { CarouselBreakpoints } from '~/common/types/types';
import { Carousel, Image } from '~/components/common/common';
import * as styles from './about-slider.module.scss';

type Props = {
  images: string[];
};

const BREAKPOINTS: CarouselBreakpoints = {
  0: {
    slidesPerView: 1,
  },
};

const AboutSlider: React.FC<Props> = ({ images }) => (
  <Carousel breakpoints={BREAKPOINTS} className={styles.wrapper} hasPagination>
    {images.map((imgPath) => (
      <Image
        alt="Binary studio workers"
        className={styles.sliderImage}
        height="500"
        src={imgPath}
        key={imgPath}
      />
    ))}
  </Carousel>
);

export { AboutSlider };
