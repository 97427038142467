import * as React from 'react';
import { FooterPayload } from '~/common/types/types';
import {
  PartnersList,
  NavigationList,
  SocialList,
} from './components/components';
import * as styles from './footer.module.scss';

type Props = {
  payload: FooterPayload;
};

const Footer: React.FC<Props> = ({ payload }) => {
  const { partnersItems, navigationItems, socialItems } = payload;

  return (
    <footer className={styles.footer}>
      <section>
        <h2 className="visually-hidden">Partners</h2>
        <PartnersList items={partnersItems} />
      </section>
      <section className={styles.navigationList}>
        <h2 className="visually-hidden">Footer Navigation</h2>
        <NavigationList items={navigationItems} />
      </section>
      <section>
        <h2 className="visually-hidden">Socials</h2>
        <SocialList items={socialItems} />
      </section>
    </footer>
  );
};

export { Footer };
