import { ENV } from '~/common/enums/enums';
import { SeoPayload } from '~/common/types/types';

const seoPayload: SeoPayload = {
  title: 'seo.foundingTeam.title',
  description: 'seo.foundingTeam.description',
  siteUrl: ENV.APP.URL,
};

export { seoPayload };
