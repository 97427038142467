import * as React from 'react';
import { InfoItem as TInfoItem } from '~/common/types/types';
import { InfoItem } from '../components';
import * as styles from './info-list.module.scss';

type Props = {
  items: TInfoItem[];
};

const InfoList: React.FC<Props> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((item) => (
      <InfoItem item={item} key={item.title} />
    ))}
  </ul>
);

export { InfoList };
