import { HistoryPayload } from '~/common/types/types';

const historyPayload: HistoryPayload = {
  title: 'history.title',
  items: [
    {
      year: 'history.group12.year',
      applicantsNum: 15000,
      applicantsTitle: 'history.group12.applicantsTitle',
      studentsNum: 410,
      studentsTitle: 'history.group12.studentsTitle',
      title: 'history.group12.title',
      historyText: 'history.group12.historyText',
    },
    {
      year: 'history.group11.year',
      applicantsNum: 7800,
      applicantsTitle: 'history.group11.applicantsTitle',
      studentsNum: 250,
      studentsTitle: 'history.group11.studentsTitle',
      title: 'history.group11.title',
      historyText: 'history.group11.historyText',
    },
    {
      year: 'history.group10.year',
      applicantsNum: 6200,
      applicantsTitle: 'history.group10.applicantsTitle',
      studentsNum: 130,
      studentsTitle: 'history.group10.studentsTitle',
      title: 'history.group10.title',
      historyText: 'history.group10.historyText',
    },
    {
      year: 'history.group9.year',
      applicantsNum: 5300,
      applicantsTitle: 'history.group9.applicantsTitle',
      studentsNum: 170,
      studentsTitle: 'history.group9.studentsTitle',
      title: 'history.group9.title',
      historyText: 'history.group9.historyText',
    },
    {
      year: 'history.group8.year',
      applicantsNum: 4000,
      applicantsTitle: 'history.group8.applicantsTitle',
      studentsNum: 120,
      studentsTitle: 'history.group8.studentsTitle',
      title: 'history.group8.title',
      historyText: 'history.group8.historyText',
    },
    {
      year: 'history.group7.year',
      applicantsNum: 3500,
      applicantsTitle: 'history.group7.applicantsTitle',
      studentsNum: 114,
      studentsTitle: 'history.group7.studentsTitle',
      title: 'history.group7.title',
      historyText: 'history.group7.historyText',
    },
    {
      year: 'history.group6.year',
      applicantsNum: 3100,
      applicantsTitle: 'history.group6.applicantsTitle',
      studentsNum: 35,
      studentsTitle: 'history.group6.studentsTitle',
      title: 'history.group6.title',
      historyText: 'history.group6.historyText',
    },
    {
      year: 'history.group5.year',
      applicantsNum: 2500,
      applicantsTitle: 'history.group5.applicantsTitle',
      studentsNum: 59,
      studentsTitle: 'history.group5.studentsTitle',
      title: 'history.group5.title',
      historyText: 'history.group5.historyText',
    },
    {
      year: 'history.group4.year',
      applicantsNum: 1500,
      applicantsTitle: 'history.group4.applicantsTitle',
      studentsNum: 37,
      studentsTitle: 'history.group4.studentsTitle',
      title: 'history.group4.title',
      historyText: 'history.group4.historyText',
    },
    {
      year: 'history.group3.year',
      applicantsNum: 360,
      applicantsTitle: 'history.group3.applicantsTitle',
      studentsNum: 15,
      studentsTitle: 'history.group3.studentsTitle',
      title: 'history.group3.title',
      historyText: 'history.group3.historyText',
    },
    {
      year: 'history.group2.year',
      applicantsNum: 300,
      applicantsTitle: 'history.group2.applicantsTitle',
      studentsNum: 28,
      studentsTitle: 'history.group2.studentsTitle',
      title: 'history.group3.title',
      historyText: 'history.group2.historyText',
    },
    {
      year: 'history.group1.year',
      applicantsNum: 200,
      applicantsTitle: 'history.group1.applicantsTitle',
      studentsNum: 18,
      studentsTitle: 'history.group1.studentsTitle',
      title: 'history.group1.title',
      historyText: 'history.group1.historyText',
    },
    {
      year: 'history.group0.year',
      applicantsNum: 60,
      applicantsTitle: 'history.group0.applicantsTitle',
      studentsNum: 13,
      studentsTitle: 'history.group0.studentsTitle',
      title: 'history.group0.title',
      historyText: 'history.group0.historyText',
    },
  ],
};

export { historyPayload };
