import {
  AppLocalizationKey,
  TeamCompositionPayload,
} from '~/common/types/types';
import supercoach from '~/assets/img/team-composition/supercoach.svg';
import coach from '~/assets/img/team-composition/coach.svg';
import developer from '~/assets/img/team-composition/developer.svg';
import qa from '~/assets/img/team-composition/qa.svg';
import productOwner from '~/assets/img/team-composition/product-owner.svg';
import designer from '~/assets/img/team-composition/designer.svg';
import surrounded from '~/assets/img/team-composition/surrounded.svg';

const teamRoles = [
  {
    image: supercoach,
    role: 'supercoach',
  },
  {
    image: coach,
    role: 'coach',
  },
  {
    image: developer,
    role: 'developer',
  },
  {
    image: surrounded,
    role: 'surrounded',
  },
  {
    image: qa,
    role: 'qa',
  },
  {
    image: productOwner,
    role: 'productOwner',
  },
  {
    image: designer,
    role: 'designer',
  },
];

const teamCompositionPayload: TeamCompositionPayload = {
  title: 'teamComposition.title',
  items: teamRoles.map((it, idx) => {
    return {
      image: it.image,
      role: it.role,
      title: `teamComposition.group${idx}.title` as AppLocalizationKey,
      description:
        `teamComposition.group${idx}.description` as AppLocalizationKey,
    };
  }),
};

export { teamCompositionPayload };
