import { ExamplesPayload } from '~/common/types/types';
import hypeCrafter from '~/assets/img/project-examples/hype-crafter.gif';
import slidez from '~/assets/img/project-examples/slidez.gif';

const examplesPayload: ExamplesPayload = {
  title: 'technology.qa.examples.title',
  items: [
    {
      title: 'technology.qa.examples.group0.title',
      description: 'technology.qa.examples.group0.description',
      imgPath: hypeCrafter,
      technologies: [
        'TypeScript',
        'React',
        'Redux/Saga',
        'Node.js',
        'PostgreSQL',
        'TypeORM',
      ],
    },
    {
      title: 'technology.qa.examples.group1.title',
      description: 'technology.qa.examples.group1.description',
      imgPath: slidez,
      technologies: [
        'Java',
        'Spring',
        'Hibernate',
        'WebSockets',
        'PostgreSQL',
        'React',
      ],
    },
  ],
};

export { examplesPayload };
