import * as React from 'react';
import { ENV } from '~/common/enums/enums';
import { footerPayload, headerPayload } from '~/common/payload/payload';
import {
  Info,
  Timeline,
  Reviews,
  History,
  Questions,
  LandingVideo,
  Directions,
  Benefits,
  EmbedVideo,
  Statistics,
  TeamComposition,
  Blogs,
} from '~/components/sections/sections';
import { Layout } from '~/components/common/common';
import {
  infoPayload,
  timelinePayload,
  reviewPayload,
  historyPayload,
  questionsPayload,
  directionsPayload,
  landingPayload,
  benefitsPayload,
  seoPayload,
  embedVideoPayload,
  statisticsPayload,
  teamCompositionPayload,
  blogsPayload,
} from './data/data';

const Page: React.FC = () => (
  <Layout
    seoPayload={seoPayload}
    headerPayload={headerPayload}
    footerPayload={footerPayload}
    registrationLink={ENV.APP.REGISTRATION_LINK}
  >
    <h1 className="visually-hidden">Binary Studio Academy</h1>
    <LandingVideo
      payload={landingPayload}
      registrationLink={ENV.APP.REGISTRATION_LINK}
      sectionHighlight="secondary"
    />
    <Statistics payload={statisticsPayload} />
    <Info payload={infoPayload} registrationLink={ENV.APP.REGISTRATION_LINK} />
    <Directions payload={directionsPayload} style="primary" />
    <Benefits
      payload={benefitsPayload}
      registrationLink={ENV.APP.REGISTRATION_LINK}
      sectionHighlight="secondary"
    />
    <TeamComposition payload={teamCompositionPayload} />
    <Timeline payload={timelinePayload} sectionHighlight="secondary" />
    <Reviews payload={reviewPayload} />
    <History payload={historyPayload} sectionHighlight="secondary" />
    <EmbedVideo payload={embedVideoPayload} />
    <Blogs payload={blogsPayload} sectionHighlight="secondary" />
    <Questions payload={questionsPayload} />
    <Directions payload={directionsPayload} style="secondary" />
  </Layout>
);

export { Page };
