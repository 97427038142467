import * as React from 'react';
import { InfoItem as TInfoItem } from '~/common/types/types';
import { FormattedMessage } from '~/components/common/common';
import { getValidClasses } from '~/helpers/helpers';
import * as styles from './info-item.module.scss';

type Props = {
  item: TInfoItem;
};

const InfoItem: React.FC<Props> = ({ item }) => (
  <li className={getValidClasses(styles.item, styles[item.icon])}>
    <h3 className={styles.title}>
      <FormattedMessage message={item.title} />
    </h3>
    <p className={styles.description}>
      <FormattedMessage message={item.description} />
    </p>
  </li>
);

export { InfoItem };
