import { AppRoute, ExternalLink } from '~/common/enums/enums';
import { directionKindToReadable } from '~/common/maps/maps';
import { FooterPayload, NavigationLink } from '~/common/types/types';
import { DIRECTIONS } from '~/common/constants/constants';

const directionLinks: NavigationLink[] = DIRECTIONS.map((it) => ({
  title: directionKindToReadable[it],
  localLink: `${AppRoute.TECHNOLOGY}/${it}`,
}));

const footerPayload: FooterPayload = {
  partnersItems: [
    {
      title: 'binaryStudio',
      link: ExternalLink.BS.ROOT,
    },
  ],
  navigationItems: [
    ...directionLinks,
    {
      title: 'FAQ',
      externalLink: AppRoute.ABOUT_FAQ,
    },
    {
      title: 'Founding Team',
      externalLink: AppRoute.FOUNDING_TEAM,
    },
    {
      title: 'Privacy Policy',
      externalLink: ExternalLink.BS.POLICY,
    },
  ],
  socialItems: [
    {
      title: 'instagram',
      link: ExternalLink.SOCIALS.INSTAGRAM,
    },
    {
      title: 'telegram',
      link: ExternalLink.SOCIALS.TELEGRAM,
    },
    {
      title: 'facebook',
      link: ExternalLink.SOCIALS.FACEBOOK,
    },
  ],
};

export { footerPayload };
