import * as React from 'react';
import { HistoryItem } from '~/common/types/types';
import { getValidClasses } from '~/helpers/helpers';
import { FormattedMessage } from '~/components/common/common';
import * as styles from './history-select-item.module.scss';

type Props = {
  item: HistoryItem;
  itemIdx: number;
  isActive: boolean;
  onYearSelect: (idx: number) => void;
};

const HistorySelectItem: React.FC<Props> = ({
  item,
  itemIdx,
  isActive,
  onYearSelect,
}) => {
  const handleYearSelect = (): void => {
    onYearSelect(itemIdx);
  };

  return (
    <li>
      <button
        className={getValidClasses(styles.btn, isActive && styles.btnActive)}
        onClick={handleYearSelect}
      >
        <FormattedMessage message={item.year} />
      </button>
    </li>
  );
};

export { HistorySelectItem };
